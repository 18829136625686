import { Avatar, Button, IconButton, ListItem, ListItemAvatar, ListItemText, styled, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { FC } from "react";
import { OrderDetail } from '../../domain/entities/OrderDetail';
import PlusIcon from '@mui/icons-material/AddTwoTone';
import SustractIcon from '@mui/icons-material/RemoveTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { ButtonsWrapper, ListItemWrapper, PriceLabelWrapper } from "./styles";
import useAuth from '../../../../app/hooks/useAuth';

interface CartDetailItemProps {
    readonly detail: OrderDetail;

    readonly changeUnits: (action: 'add' | 'sustract') => void;
    readonly onClickDetail: () => void;
}

const CartDetailItem: FC<CartDetailItemProps> = ({ detail, changeUnits, onClickDetail }) => {
    const { user } = useAuth();

    return (
        <ListItem sx={ListItemWrapper} alignItems="flex-start">
            <Button component="span" sx={ButtonsWrapper}>
                <IconButton size="small" onClick={() => changeUnits('add')}><PlusIcon /></IconButton>
                {detail.quantity}

                <IconButton size="small" onClick={() => changeUnits('sustract')}
                    style={
                        detail.quantity === 1 ? {
                            color: "#f44336"
                        } : {}
                    }
                >{detail.quantity > 1 ? <SustractIcon /> : <DeleteIcon />}
                </IconButton>
            </Button>
            <ListItemAvatar sx={{ padding: "8px", justifyContent: 'center', display: 'flex' }} onClick={onClickDetail}>
                <Avatar alt={detail.price.product.name} src={detail.price.product.getFirstPhoto()} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    detail.price.product.name
                }
                primaryTypographyProps={
                    {
                        component: "span",
                        variant: "body2",
                        color: "textPrimary"
                    }}
                secondary={
                    <div>
                        <div>
                            {detail.price.getValue(user?.type === 'retail').toFixed(2) + "€/ud"}
                        </div>
                    </div>
                }
                secondaryTypographyProps={
                    {
                        variant: "subtitle1",
                    }
                }
                onClick={onClickDetail}
                sx={{
                    alignItems: "center",
                    pr: "8px",
                    lineHeight: "1"
                }}
            />
            <ListItemText sx={PriceLabelWrapper}
                primary={<React.Fragment>
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="textPrimary"
                    >
                        {detail.getPrice(user?.type !== 'company').toFixed(2)}€
                    </Typography>
                </React.Fragment>}
            />
        </ListItem>
    )
}

export default CartDetailItem;