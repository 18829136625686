import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FC, useContext } from "react";
import { FeaturesSelectedContext } from "src/features/order/ui/detail_form";
import { Feature } from "../../domain/Feature";

interface FeatureItemProps {
  group: string,
  features: Feature[]
}

const FeatureItem: FC<FeatureItemProps> = ({group, features}) => {
  const { featuresSelected, selectFeatures } = useContext(FeaturesSelectedContext);
  const featureSelected: Feature | undefined = featuresSelected.find(feature => feature.group === feature.group);

  const onHandleChange = (featureId: string) => {
    const feature: Feature = features.find(feature => feature.id === featureId);
    selectFeatures(feature);
  }
  
  return (
    <FormControl fullWidth>
      <InputLabel id="select-feature-label">{group}</InputLabel>
      <Select
        labelId="select-feature-label"
        id="select-feature"
        value={featureSelected?.id ?? features[0].id}
        label={group}
        onChange={(event) => onHandleChange(event.target.value as string)}
      >
        {
          features.map(feature => (
            <MenuItem key={feature.id} value={feature.id}>{feature.label}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default FeatureItem;