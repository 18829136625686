import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React from "react";
import { FC } from "react";
import useAuth from "src/app/hooks/useAuth";
import { ClientType } from "src/features/client/domain/entities/ClientType";
import { OrderDetail } from "../../domain/entities/OrderDetail";
import { DetailsInOrderListDetailItem, DetailsInOrderListDetailPrice, DetailsInOrderListDetailProductImage, DetailsInOrderListDetailProductName } from "./styles";

interface DetailInOrderCellProps {
    readonly detail: OrderDetail
}

const DetailInOrderCell: FC<DetailInOrderCellProps> = ({ detail }) => {
    const isCompany = useAuth().user?.type === ClientType.Company.value;

    if (!detail) {
        <></>;
    }

    return (
        <ListItem alignItems="flex-start" key={detail.price.id} sx={DetailsInOrderListDetailItem()}>

            <ListItemAvatar sx={DetailsInOrderListDetailProductImage()} onClick={() => { }}>
                <Avatar alt={detail.price.product.name} src={detail.getMainPhoto()} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    detail.price.product.name
                }
                primaryTypographyProps={
                    {
                        component: "span",
                        variant: "body2",
                        color: "textPrimary"
                    }}
                secondary={<div>
                    <div>
                        {detail.quantity + " x " + detail.price.value + "€/ud"}
                    </div>
                </div>
                }
                secondaryTypographyProps={
                    {
                        variant: "subtitle1",
                    }

                }
                onClick={() => { }}
                sx={DetailsInOrderListDetailProductName()}
            />
            <ListItemText
                primary={<React.Fragment>
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="textPrimary"
                    >
                        {detail.getPrice(isCompany !== true).toFixed(2)}€
                    </Typography>
                </React.Fragment>}
                sx={DetailsInOrderListDetailPrice()}
            />
        </ListItem>
    )
}

export default DetailInOrderCell;