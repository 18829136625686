export class ShippingMethodScope {
    static readonly Retail = new ShippingMethodScope('retail');

    static readonly Company = new ShippingMethodScope('company');

    static readonly Both = new ShippingMethodScope('both');

    static readonly Venue = new ShippingMethodScope('venue');

    constructor(public readonly value: string) { }

    static getValues(): ShippingMethodScope[] {
        return [this.Retail, this.Company, this.Both, this.Venue];
    }

    static fromString(string: string): ShippingMethodScope {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}