import { deleteRequest, getRequest, postRequest, putRequest } from 'src/config/http';
import { ShippingArea } from '../domain/entities/ShippingArea';
import { FindShippingAreaDto } from './ShippingAreaDto';

export abstract class ShippingAreaService {

    public static async findShippingAreaAsync(dto: FindShippingAreaDto): Promise<ShippingArea> {
        return ShippingArea.fromJson(await getRequest(`/shipping_areas/`, dto));
    }
    
}