export const SelectCreditCardStyles = {
    creditCardItem: {
        paddingLeft: "15px",
        paddingRight: "0px",
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"
    },
    listContainer: {
        margin: "0px",
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
    },
    loadingContainer: {
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
}
