import { Box, Drawer, TextField } from "@mui/material";
import DrawerHeader from "src/app/components/DrawerHeader";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import {
  getNewOrder,
  getOpenDeliveryDate,
  modifyEstimatedDeliverDateInNewOrder,
  setOpenDeliveryDate,
} from "../../data/OrderSlice";
import { NewOrder } from "../../domain/entities/NewOrder";
import { DeliveryDateDrawerCalendarWrapper } from "./Style";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import moment from "moment";
import { Day } from "src/features/commons/Day";
import { es } from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect, useState } from "react";

const DeliveryDateDrawer = () => {
  const dispatch = useAppDispatch();
  const newOrder: NewOrder = useAppSelector(getNewOrder);
  const openDeliveryDate: boolean = useAppSelector(getOpenDeliveryDate);

  const [firstDateAvailable, setFirstDateAvailable] = useState(undefined);

  useEffect(() => {
    if (!newOrder.shippingMethod) {
      setFirstDateAvailable(undefined);
    } else {
      let preparationDays = newOrder.shippingMethod.preparationDays;
      if (moment().hour() >= newOrder.shippingMethod.limitHour) {
        preparationDays = preparationDays + 1;
      }
      setFirstDateAvailable(moment().add(preparationDays, "days"));
    }
  }, [newOrder.shippingMethod]);

  if (!newOrder.shippingAddress || !newOrder.shippingMethod) {
    return <></>;
  }

  const handleOnClose = () => {
    dispatch(setOpenDeliveryDate(false));
  };

  const isDateDisabled = (date) => {
    if (!date) {
      return true;
    }

    if (moment(date).date() < firstDateAvailable.date()) return true;

    //comprobamos si la fecha está entre los días de reparto y si no desabilitamos la fecha
    const weekDayName = Day.fromString(
      moment(date.toISOString()).format("dddd").toLowerCase()
    );
    return newOrder.shippingMethod.deliveryDays.includes(weekDayName) === false;
  };

  const onSelectDeliveryDate = (selectedDate) => {
    dispatch(modifyEstimatedDeliverDateInNewOrder(selectedDate));
    handleOnClose();
  };

  return (
    <Drawer anchor={"right"} open={openDeliveryDate} onClose={handleOnClose}>
      <DrawerHeader
        title="Elige fecha de entrega"
        onClickBackButton={handleOnClose}
      />
      <Box
        component="div"
        sx={{}}
        style={{ backgroundColor: "white", minWidth: "375px" }}
      >
        <Box component="div" sx={DeliveryDateDrawerCalendarWrapper()}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <StaticDatePicker
              shouldDisableDate={isDateDisabled}
              disablePast
              disableHighlightToday
              onAccept={(newValue) => {
                onSelectDeliveryDate(newValue);
              }}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={newOrder.estimatedDeliveryDate ?? firstDateAvailable}
              onChange={() => {}}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DeliveryDateDrawer;
