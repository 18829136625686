import { NotArrayError } from "src/app/errors/NotArrayError";
import { PaymentInfo } from "./PaymentInfo";
import { PaymentMethodAttribute } from "./PaymentMethodAttribute";
import { PaymentMethodType } from './PaymentMethodType';

export class PaymentMethod {
    constructor(
        readonly id: string,
        readonly erpId: string,
        readonly name: string,
        readonly type: PaymentMethodType,

        readonly attributes?: PaymentMethodAttribute[],
        readonly creditCardRequired?: boolean,
        readonly description?: string,
        readonly instructions?: string,
        readonly paymentInfo?: PaymentInfo,
    ) { }

    public static fromJson(json: any): PaymentMethod {
        return new PaymentMethod(
            json['id'],
            json['erpId'],
            json['name'],
            PaymentMethodType.fromString(json['type']),
            json['attributes'] ? PaymentMethodAttribute.fromJsonArray(json['attributes']) : undefined,
            json['creditCardRequired'],
            json['description'],
            json['instructions'],
            json['paymentInfo']
        );
    }

    public static fromJsonArray(jsonArray: any): PaymentMethod[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}