import { NotArrayError } from "src/app/errors/NotArrayError";

export class CreditCard {
    constructor(
        readonly stripeId: string,
        readonly lastCardNumbers: string,
        readonly brand: string,
        readonly expMonth: number,
        readonly expYear: number,
        readonly ownerName: string,
    ) { }

    public static fromJson(json: any): CreditCard {
        return new CreditCard(
            json['stripeId'],
            json['lastCardNumbers'],
            json['brand'],
            json['expMonth'],
            json['expYear'],
            json['ownerName']
        );
    }

    public static fromJsonArray(jsonArray: any): CreditCard[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}