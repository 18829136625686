import { Box } from '@mui/material';
import { FC } from 'react';
import CartButton from './Cart/index';
import LoginButton from './Login';

interface HeaderButtonsProps {
  readonly onClickCartButton: () => void;
}

const HeaderButtons: FC<HeaderButtonsProps> = ({ onClickCartButton }) => {
  return (
    <Box>
      <LoginButton />
      <CartButton onClickCartButton={onClickCartButton} />
    </Box>
  );
}

export default HeaderButtons;
