const FinishOrderDrawerContainer = (backgroundColor: string) => {
    return {
        minWidth: "calc(min(100vw, 375px))",
        maxWidth: "calc(min(100vw, 375px))",
        backgroundColor
    };
}

const FinishOrderDrawerFooter = () => {
    return {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    };
}

const FinishOrderDrawerSectionDivider = () => {
    return { height: "10px", paddingBottom: '5px !important' }
}

const FinishOrderDrawerSwalIcon = () => {
    return {
        width: "60px",
        height: "60px"
    }
}

export {
    FinishOrderDrawerContainer,
    FinishOrderDrawerFooter,
    FinishOrderDrawerSectionDivider,
    FinishOrderDrawerSwalIcon
}