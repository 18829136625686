import { Box, TextField } from "@mui/material";
interface DetailNotesProps {
    readonly clientNote: string,
    handleNotesChanged(clientNote: string)
}

export const DetailNotes = ({ clientNote, handleNotesChanged }: DetailNotesProps) => (
    <Box px={3} pt={3} display="flex" justifyContent="center">
        <TextField
            multiline
            fullWidth
            disabled={false}
            name="notes"
            value={clientNote}
            placeholder="Indicaciones que quieras hacer sobre el producto"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => handleNotesChanged(event.target.value)}
            sx={{ maxWidth: "400px" }}
            label={"Notas de la línea"}
            variant="outlined"
        />
    </Box>
)