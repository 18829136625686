import { Box, TextField, Button, InputAdornment } from "@mui/material";
import PlusIcon from '@mui/icons-material/AddRounded';
import MinusIcon from '@mui/icons-material/RemoveRounded';
import { ProductAmountMode } from "src/features/product/domain/entities/ProductAmountMode";
import { t } from "i18next";

interface QuantitySelectorProps {
    readonly quantity: number,
    readonly amountModeSelected: ProductAmountMode,
    handleQuantityChanged(quantity: any)
}

export const QuantitySelector = ({ quantity, amountModeSelected, handleQuantityChanged }: QuantitySelectorProps) => (
    <Box px={3} pt={3} display="flex" gap={1} sx={{ width: "100%" }} justifyContent="center">
        <Box display="flex" gap={1} >
            <Button
                variant="contained"
                onClick={() => {
                    handleQuantityChanged(quantity - 1)
                }
                }
            >
                <MinusIcon />
            </Button>
            <TextField
                type="number"
                onChange={(event) => handleQuantityChanged(event.target.value)}
                disabled={false}
                name="nots"
                value={quantity}
                label={amountModeSelected === ProductAmountMode.Units ? t('Detail.AmountModeUnitsHint') : t('Detail.AmountModeInBulkHint')}
                variant="outlined"
                sx={{ maxWidth: "350px" }}
                InputProps={{
                    sx: {
                        "& input": {
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "600"
                        }
                    },
                    endAdornment: amountModeSelected === ProductAmountMode.InBulk ? <InputAdornment position="start">kg</InputAdornment> : "",
                }}
            />
            <Button
                variant="contained"
                onClick={() => handleQuantityChanged(quantity + 1)}
            >
                <PlusIcon />
            </Button>

        </Box>
    </Box>
)