import {
  CreateAddressDto,
  FindClientAddressesDto,
  UpdateAddressDto,
} from "./AddressDto";
import { Address } from "../domain/entities/Address";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "src/config/http";

export abstract class AddressService {
  public static async createAddressAsync(
    createAddress: CreateAddressDto
  ): Promise<Address> {
    return Address.fromJson(
      await postRequest(
        `/clients/${createAddress.clientId}/addresses`,
        createAddress
      )
    );
  }

  public static async deleteAddressAsync(addressId: string): Promise<Address> {
    return Address.fromJson(await deleteRequest(`/addresses/${addressId}`));
  }

  public static async findAddressByIdAsync(
    addressId: string
  ): Promise<Address> {
    return Address.fromJson(await getRequest(`/addresses/${addressId}`));
  }

  public static async findClientAddressesAsync(
    findClientAddresses: FindClientAddressesDto
  ): Promise<Address[]> {
    return Address.fromJsonArray(
      await getRequest(`/clients/${findClientAddresses.clientId}/addresses`, {
        type: findClientAddresses.type,
      })
    );
  }

  public static async updateAddressAsync(
    updateAddress: UpdateAddressDto
  ): Promise<Address> {
    return Address.fromJson(
      await putRequest(
        `/clients/${updateAddress.clientId}/addresses/${updateAddress.addressId}`,
        updateAddress
      )
    );
  }
}
