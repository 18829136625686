import { NotArrayError } from "src/app/errors/NotArrayError";

export class Location {
    constructor(
        readonly code: string,
        readonly name: string,
    ) { }

    public static fromJson(json: any): Location {
        return new Location(
            json['code'],
            json['name'],
        );
    }

    public static fromJsonArray(jsonArray: any): Location[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}