export class Feature {
  constructor(
    readonly id: string,
    readonly group: string,
    readonly label: string,
    readonly productId: string,
  ) {}

  static fromJson(json: Record<string, any>): Feature {
    return new Feature(json.id, json.group, json.label, json.productId);
  }

  toJson(): Record<string, any> {
    return {
      id: this.id,
      group: this.group,
      label: this.label,
      productId: this.productId,
    };
  }
}