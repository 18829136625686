import { FamilyFilterDto } from './FamilyDto';
import { Family } from '../domain/entities/Family';
import { getRequest } from 'src/config/http';

export abstract class FamilyService {
    public static async findFamilyByIdAsync(familyId: string): Promise<Family> {
        return Family.fromJson(await getRequest(`/families/${familyId}`));
    }

    public static async searchFamilysAsync(filters: FamilyFilterDto): Promise<Family[]> {
        const response: Family[] = await getRequest('/families', JSON.parse(JSON.stringify(filters)));
        return Family.fromJsonArray(response);
    }

    public static async searchFamiliesByClientAsync(clientId: string): Promise<Family[]> {
        const response: Family[] = await getRequest(`/clients/${clientId}/families`);
        return Family.fromJsonArray(response);
    }
}