import { Grid } from "@mui/material";
import { FC } from "react";
import FeatureItem from "./FeatureItem";
import { useFeatures } from "./useFeatures";

interface FeatureListProps {
  productId: string;
}

const FeatureList: FC<FeatureListProps> = ({productId}) => {

  const { groups, getFeaturesByGroup } = useFeatures(productId);

  if (!groups) {
    return <></>;
  }

  return (
    <Grid container padding={2} spacing={1}>
      <Grid item xs={12} md={groups.length === 1 ? 12 : 6}>
      {
        groups.map(group => 
          <FeatureItem 
            key={group} 
            group={group} 
            features={getFeaturesByGroup(group)} 
          />
        )
      }
      </Grid>
    </Grid>
  )
}

export default FeatureList;