import {
  FindPriceForProductAndClientDto,
  FindPriceForProductAndFeeDto,
  FindPricesByFeeId,
  SearchPricesByClientId,
} from "./PriceDto";
import { Price } from "../domain/entities/Price";
import { getRequest } from "src/config/http";
import { SearchResponse } from "../../commons/Types";

export abstract class PriceService {
  public static async findPriceForProductAndClientAsync(
    dto: FindPriceForProductAndClientDto
  ): Promise<Price> {
    return Price.fromJson(
      await getRequest(
        `/clients/${dto.clientId}/products/${dto.productId}/prices`
      )
    );
  }

  public static async findPriceForProductAndFeeAsync(
    dto: FindPriceForProductAndFeeDto
  ): Promise<Price> {
    return Price.fromJson(
      await getRequest(
        `/fees/${dto.feeId}/products/${dto.productId}`
      )
    );
  }

  public static async findFeaturedPricesByClientIdAsync(
    clientId: string
  ): Promise<Price[]> {
    return Price.fromJsonArray(
      await getRequest(`/clients/${clientId}/prices`, { type: "featured" })
    );
  }

  public static async findPriceByIdAsync(price_id): Promise<Price> {
    return Price.fromJson(await getRequest(`/prices/${price_id}`));
  }

  public static async findPricesByFeeIdAsync(
    dto: FindPricesByFeeId
  ): Promise<SearchResponse<Price>> {
    const response: SearchResponse<Price> = await getRequest(
      `/fees/${dto.feeId}/prices`,
      JSON.parse(JSON.stringify(dto)),
      {},
      true
    );
    return {
      data: Price.fromJsonArray(response.data),
      totalCount: response.totalCount,
    };
  }

  public static async searchPricesByClientIdAsync(
    filters: SearchPricesByClientId
  ): Promise<SearchResponse<Price>> {
    const response: SearchResponse<Price> = await getRequest(
      `/clients/${filters.clientId}/prices`,
      { ...JSON.parse(JSON.stringify(filters)), type: "favourites" },
      {},
      true
    );
    return {
      data: Price.fromJsonArray(response.data),
      totalCount: response.totalCount,
    };
  }
}
