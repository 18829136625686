import { createAsyncThunk } from "@reduxjs/toolkit";
import { SearchResponse } from "src/features/commons/Types";
import { Price } from "../domain/entities/Price";
import {
  FindPriceForProductAndClientDto,
  FindPricesByFeeId,
  SearchPricesByClientId,
} from "./PriceDto";
import { PriceService } from "./PriceServices";

export const findPriceForProductAndClientAsyncThunk = createAsyncThunk(
  "price/find_by_client_product",
  async (dto: FindPriceForProductAndClientDto): Promise<Price> =>
    PriceService.findPriceForProductAndClientAsync(dto)
);

export const findFeaturedPricesByClientThunk = createAsyncThunk(
  "price/featured_prices_by_client",
  async (clientId: string): Promise<Price[]> =>
    PriceService.findFeaturedPricesByClientIdAsync(clientId)
);


export const findPriceByIdThunk = createAsyncThunk(
    'price/find_by_id',
    async (price_id: string): Promise<Price> =>
        PriceService.findPriceByIdAsync(price_id)
)

export const findPricesByFeeIdThunk = createAsyncThunk(
  "prices/find_by_fee",
  async (dto: FindPricesByFeeId): Promise<SearchResponse<Price>> =>
    PriceService.findPricesByFeeIdAsync(dto)
);

export const searchPricesByClientIdThunk = createAsyncThunk(
  "prices/search",
  async (dto: SearchPricesByClientId): Promise<SearchResponse<Price>> =>
    PriceService.searchPricesByClientIdAsync(dto)
);
