import { NotArrayError } from "src/app/errors/NotArrayError";

export class Note {
    constructor(
        readonly id: string,
        readonly clientId: string,
        readonly productId: string,

        readonly clientText?: string,
        readonly commercialText?: string,
    ) { }

    public static fromJson(json: any): Note {
        return new Note(
            json['id'],
            json['clientId'],
            json['productId'],
            //el ##HIDDEN## es un valor que se inserta en el texto cuando los comerciales desde su app ocultan el comentario de un cliente. 
            //No hay que mostrarlo
            json['clientText'] ? json['clientText'].replace('##HIDDEN##', '') : undefined,
            json['commercialText'],
        );
    }

    public static fromJsonArray(jsonArray: any): Note[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}