import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import useAuth from "src/app/hooks/useAuth";

import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { initializeFirebase } from "src/config/firebase";
import { AuthProvider } from "src/contexts/FirebaseAuthContext";
import { PartnerContextProvider } from "src/features/partner/ui/context/PartnerContext";
import "src/i18n/i18n";
import AppNavigation from "./app/navigation";
import { useAppDispatch, useAppSelector } from "./config/hooks";
import { getPartnerSelected } from "./features/partner/data/PartnerSlice";
import {
  findPartnerByIdThunk,
  findPartnerBySlugThunk,
} from "./features/partner/data/PartnerThunk";
import ThemeProvider from "./theme/ThemeProvider";

function App() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  localStorage.setItem("userId", auth?.user?.id);
  const partner = useAppSelector(getPartnerSelected);

  useEffect(() => {
    const url: string[] = window.location.host.split(".");
    if (url.length > 1) {
      dispatch(findPartnerBySlugThunk(url[1]));
    } else if (url.find((subdomain) => subdomain.includes("localhost"))) {
      dispatch(findPartnerByIdThunk(process.env.REACT_APP_PARTNER_ID));
    }
  }, []);

  useEffect(() => {
    if (partner) {
      initializeFirebase(partner.slug);
      setFirebaseInitialized(true);
    }
  }, [partner]);

  if (!partner || !firebaseInitialized) {
    return <></>;
  }

  console.log(partner);

  return (
    <AuthProvider>
      <ThemeProvider partnerTheme={partner.customs?.theme || "GreyGooseTheme"}>
        <PartnerContextProvider
          partnerId={partner.id}
          salesBlockedMessage={partner.config?.salesBlockedMessage ?? null}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <CssBaseline />
              <AppNavigation />
            </SnackbarProvider>
          </LocalizationProvider>
        </PartnerContextProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}
export default App;
