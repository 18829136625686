import { IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC } from "react";
import { ShippingMethod } from "../../domain/entities/ShippingMethod";
import { ShippingMethodListItemStyles } from "./styles";
import LocalShippingIcon from '@mui/icons-material/LocalShippingTwoTone';
import SelectedIcon from '@mui/icons-material/CheckTwoTone';
import { modifyShippingMethodInNewOrder } from "src/features/order/data/OrderSlice";
import { useAppDispatch } from "src/config/hooks";

interface SelectShippingMethodItemProps {
    onClose: () => void;
    shippingMethod: ShippingMethod;
    shippingMethodSelected: ShippingMethod
}

const SelectShippingMethodItem: FC<SelectShippingMethodItemProps> = ({ onClose, shippingMethod, shippingMethodSelected }) => {
    const dispatch = useAppDispatch();

    if (!shippingMethod) {
        return <></>;
    }

    const onClickShippingMethod = (sM: ShippingMethod) => {
        dispatch(modifyShippingMethodInNewOrder(sM))
        onClose();
    }




    return (
        <ListItemButton sx={ShippingMethodListItemStyles} alignItems={"flex-start"} onClick={() => onClickShippingMethod(shippingMethod)} >
            <ListItemIcon sx={{ marginTop: "0px" }}>
                <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText
                primary={shippingMethod.name}
                secondary={shippingMethod.getPriceDescription()}
            /*  secondary={
                  //shippingMethodValueDescription(detailsInCart, shippingMethod)
              }*/
            />
            {
                (shippingMethodSelected && shippingMethodSelected.id === shippingMethod.id) ?
                    <ListItemIcon>
                        <IconButton edge="end" aria-label="comments">
                            <SelectedIcon />
                        </IconButton>
                    </ListItemIcon> : ""
            }
        </ListItemButton >
    );
}

export default SelectShippingMethodItem;