import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientService } from 'src/features/client/data/ClientService';
import { CreditCard } from 'src/features/client/domain/entities/CreditCard';
import { PaymentMethod } from '../domain/entities/PaymentMethod';
import { PaymentMethodType } from '../domain/entities/PaymentMethodType';
import { PaymentMethodService } from './PaymentMethodService';

export const searchPaymentMethodsThunk = createAsyncThunk(
    'payment_method/search',
    async (type?: PaymentMethodType): Promise<PaymentMethod[]> => PaymentMethodService.searchPaymentMethodsByTypeAsync(type?.value)
);

export const getCreditCardsByClientThunk = createAsyncThunk(
    "client/creditCards",
    async (clientId: string): Promise<CreditCard[]> =>
        PaymentMethodService.getCreditCardsByClient(clientId)
);