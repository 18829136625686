import { FC } from 'react';
import BackIcon from "@mui/icons-material/ChevronLeftTwoTone";
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DrawerHeaderBackButton, DrawerHeaderContainer, DrawerHeaderTitle } from './styles';

interface DrawerHeaderProps {
    readonly title: string;

    readonly onClickBackButton: () => void;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({ title, onClickBackButton }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box sx={DrawerHeaderContainer(theme.colors.primary.main)}>
            <Tooltip title={t('Commons.Back')}>
                <IconButton sx={DrawerHeaderBackButton} edge="start"
                    onClick={onClickBackButton}>
                    <BackIcon sx={{ color: 'white' }} />
                </IconButton>
            </Tooltip>

            <Typography sx={DrawerHeaderTitle} align="center" variant="h4" color={'white'}>
                {title}
            </Typography>
        </Box>
    );
};

export default DrawerHeader;
