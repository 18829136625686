import { createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../domain/entities/Notification";
import {
  FindNotificationsByClientIdDto,
  NotificationFilter,
} from "./NotificationDto";
import { NotificationService } from "./NotificationService";

export const searchHomeNotificationsThunk = createAsyncThunk(
  "notification/home",
  async (filters: NotificationFilter): Promise<Notification[]> =>
    NotificationService.searchNotificationsAsync(filters)
);

export const searchClientNotificationsThunk = createAsyncThunk(
  "notification/client",
  async (filters: FindNotificationsByClientIdDto): Promise<Notification[]> =>
    NotificationService.findNotificationsByClientIdAsync(filters)
);
