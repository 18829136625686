const AcceptButton = () => {
  return {
    marginBottom: "1rem",
    backgroundColor: "#4fd053",
    color: "white",
    "&:hover": {
      backgroundColor: "#47ba4a",
    },
    width: "100%",
  };
};

const Container = () => {
  return {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
  };
};

export { AcceptButton, Container };
