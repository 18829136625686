import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import addressReducer from "src/features/address/data/AddressSlice";
import clientReducer from "src/features/client/data/ClientSlice";
import familyReducer from "src/features/family/data/FamilySlice";
import homeSectionReducer from "src/features/home_section/data/HomeSlice";
import notificationReducer from "src/features/notification/data/NotificationSlice";
import orderReducer from "src/features/order/data/OrderSlice";
import partnerReducer from "src/features/partner/data/PartnerSlice";
import paymentMethodReducer from "src/features/payment_method/data/PaymentMethodSlice";
import priceReducer from "src/features/price/data/PriceSlice";
import shippingMethodReducer from "src/features/shipping_method/data/ShippingMethodSlice";

export const store = configureStore({
  reducer: {
    address: addressReducer,
    client: clientReducer,
    family: familyReducer,
    homeSection: homeSectionReducer,
    notification: notificationReducer,
    order: orderReducer,
    partner: partnerReducer,
    price: priceReducer,
    paymentMethod: paymentMethodReducer,
    shippingMethod: shippingMethodReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
