import { Drawer } from '@mui/material';
import DrawerHeader from 'src/app/components/DrawerHeader';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../config/hooks';
import { getOpenOrderHistory, setOpenOrderHistory } from '../../data/OrderSlice';
import OrdersList from '../order_list';
import { searchOrdersThunk } from '../../data/OrderThunk';
import { useEffect } from 'react';
import useAuth from 'src/app/hooks/useAuth';

const OrdersHistoryDrawer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const open = useAppSelector(getOpenOrderHistory);
    const { user } = useAuth()

    useEffect(() => {

        dispatch(searchOrdersThunk({ clientId: user.id, filter: {} }));
    }, [open])


    const handleOnClose = (): void => {
        dispatch(setOpenOrderHistory(false));
    }

    return (
        <Drawer anchor={"right"} open={open} onClose={handleOnClose} >
            <DrawerHeader title={t('Order.YourOrders')} onClickBackButton={handleOnClose} />
            {
                <OrdersList />
            }
        </Drawer>
    );
}


export default OrdersHistoryDrawer;
