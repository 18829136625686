export class Day {

    static readonly Monday = new Day('monday');

    static readonly Tuesday = new Day('tuesday');

    static readonly Wednesday = new Day('wednesday');

    static readonly Thursday = new Day('thursday');

    static readonly Friday = new Day('friday');

    static readonly Saturday = new Day('saturday');

    static readonly Sunday = new Day('sunday');

    constructor(public readonly value: string) { }

    static getValues(): Day[] {
        return [this.Monday, this.Tuesday, this.Wednesday, this.Thursday, this.Friday, this.Saturday, this.Sunday];
    }

    static fromString(string: string): Day {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}