import { FC, useRef } from 'react';
import useAuth from 'src/app/hooks/useAuth';

import {
  Avatar,
  Box,
  Button,
  styled
} from '@mui/material';
import { useAppDispatch } from '../../../../../config/hooks';
import { setOpenProfile } from 'src/features/client/data/ClientSlice';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);

interface HeaderUserBoxProps { }

const HeaderUserbox: FC<HeaderUserBoxProps> = ({ }) => {
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const ref = useRef<any>(null);

  const handleOnClickProfile = (): void => {
    dispatch(setOpenProfile(true));
  }

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Box
        component="span"
        sx={{
          display: { sm: 'inline-block' },
        }}
      >
        <UserBoxButton color="secondary" ref={ref} onClick={handleOnClickProfile}>
          <Avatar alt={user.name} src={user.avatar} />
        </UserBoxButton>
      </Box>
    </>
  );
}

export default HeaderUserbox;
