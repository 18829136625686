import { NotArrayError } from "src/app/errors/NotArrayError";

export class ShippingAreaDefaults {
    constructor(
        readonly paymentMethodId: string,
        readonly feeId: string,

        commercialId: string
    ) { }

    public static fromJson(json: any): ShippingAreaDefaults {
        return new ShippingAreaDefaults(
            json['paymentMethodId'],
            json['feeId'],
            json['commercialId'],

        );
    }

    public static fromJsonArray(jsonArray: any): ShippingAreaDefaults[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}