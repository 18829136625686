import { ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { FC } from "react";

interface FinishOrderPriceItemProps {
    readonly title: string;
    readonly subtitle: string;
    readonly value: string;
}

const FinishOrderPriceItem: FC<FinishOrderPriceItemProps> = ({ title, subtitle, value }) => {
    return (
        <ListItem style={{ paddingTop: 0 }}>
            <ListItemText
                primary={title}
                secondary={subtitle}
                sx={{ maxWidth: "95%" }}
            />
            <ListItemSecondaryAction>
                {value}
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default FinishOrderPriceItem;