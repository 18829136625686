export class Allergen {
  static readonly Celery = new Allergen("celery");

  static readonly Crustaceans = new Allergen("crustaceans");

  static readonly Eggs = new Allergen("eggs");

  static readonly Fish = new Allergen("fish");

  static readonly Gluten = new Allergen("gluten");

  static readonly Lupin = new Allergen("lupin");

  static readonly Milk = new Allergen("milk");

  static readonly Mollucs = new Allergen("molluscs");

  static readonly Mustard = new Allergen("mustard");

  static readonly Nuts = new Allergen("nuts");

  static readonly Peanuts = new Allergen("peanuts");

  static readonly Sesame = new Allergen("sesame");

  static readonly Soybeans = new Allergen("soybeans");

  static readonly Sulphites = new Allergen("sulphites");

  constructor(public readonly value: string) {}

  static getValues(): Allergen[] {
    return [
      this.Celery,
      this.Crustaceans,
      this.Eggs,
      this.Fish,
      this.Gluten,
      this.Lupin,
      this.Milk,
      this.Mollucs,
      this.Mustard,
      this.Nuts,
      this.Peanuts,
      this.Sesame,
      this.Soybeans,
      this.Sulphites,
    ];
  }

  static fromString(string: string): Allergen {
    const value = this.getValues().find(
      (enumValue) => enumValue.value === string
    );
    if (value) {
      return value;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
        (this as any).prototype.constructor.name
      }`
    );
  }
}
