import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import useAuth from '../../../../app/hooks/useAuth';
import { getOpenProfile, getStatus } from 'src/features/client/data/ClientSlice';
import DrawerHeader from 'src/app/components/DrawerHeader';
import { CircularProgress, Drawer } from '@mui/material';
import SelectAddressList from '../address_list/index';
import { searchAddresssByClient } from '../../data/AddressThunk';
import { getOpenSelectAddress, setOpenSelectAddress } from '../../data/AddressSlice';
import { Address } from '../../domain/entities/Address';

interface SelectAddressDrawerProps { }

const SelectAddressDrawer: FC<SelectAddressDrawerProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const accountOpen = useAppSelector(getOpenProfile);
    const openSelectAddress = useAppSelector(getOpenSelectAddress);
    const isLoading = useAppSelector(getStatus) === 'loading';

    useEffect(() => {
        if (open) {
            dispatch(searchAddresssByClient({
                clientId: user.id,
                type: 'shipping'
            }));
        }
    }, [open]);

    const handleOnClose = (): void => {
        dispatch(setOpenSelectAddress(false));
    }

    const handleSelectAddress = (address: Address): void => {
        handleOnClose();
    }

    return (
        <>
            <Drawer anchor={"right"} open={openSelectAddress} onClose={handleOnClose}>
                <DrawerHeader
                    title={accountOpen ? "Tus direcciones" : "Elige una dirección"}
                    onClickBackButton={handleOnClose} />
                {
                    isLoading ? <CircularProgress /> :
                        <SelectAddressList editable={accountOpen} onSelectAddress={handleSelectAddress} />
                }
            </Drawer>
        </>
    );
}


export default SelectAddressDrawer;
