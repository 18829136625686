import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { HomeSection } from "../domain/entities/HomeSection";
import { searchHomeSectionsThunk } from "./HomeSectionThunk";

interface HomeSectionState {
  homeSections: HomeSection[];
  status: StateStatus;
}

export const initialState: HomeSectionState = {
  homeSections: [],
  status: "ready",
};

const homeSectionSlice = createSlice({
  name: "homeSections",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<HomeSectionState>) => {
    /*
             Search HomeSections
        */
    builder
      .addCase(searchHomeSectionsThunk.pending, (state: HomeSectionState) => {
        state.status = "loading";
      })
      .addCase(
        searchHomeSectionsThunk.fulfilled,
        (state: HomeSectionState, action: PayloadAction<HomeSection[]>) => {
          state.homeSections = action.payload;
          state.status = "ready";
        }
      )
      .addCase(searchHomeSectionsThunk.rejected, (state: HomeSectionState) => {
        state.status = "error";
      });
  },
});

const getHomeSections = (state: RootState) => state.homeSection.homeSections;
const getStatus = (state: RootState) => state.homeSection.status;

export { getHomeSections, getStatus };

export default homeSectionSlice.reducer;
