export class PaymentMethodType {
    static readonly Cash = new PaymentMethodType('cash');

    static readonly Deferred = new PaymentMethodType('deferred');

    constructor(public readonly value: string) { }

    static getValues(): PaymentMethodType[] {
        return [this.Cash, this.Deferred];
    }
    
    static fromString(string: string): PaymentMethodType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}