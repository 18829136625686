import { IconButton, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Address } from "../../domain/entities/Address";
import { AddressListItem } from "./styles";
import { FC } from 'react';
import LocationIcon from '@mui/icons-material/LocationOnTwoTone';
import EditIcon from '@mui/icons-material/EditTwoTone';
import SelectedIcon from '@mui/icons-material/CheckTwoTone';
import { useAppSelector } from '../../../../config/hooks';
import { getAddressSelected } from "../../data/AddressSlice";

interface SelectAddressProps {
    readonly address: Address;

    readonly onClickAddress: () => void;
    readonly onClickEditAddress?: () => void;
}

const SelectAddressItem: FC<SelectAddressProps> = ({ address, onClickAddress, onClickEditAddress }) => {
    const addressSelected = useAppSelector(getAddressSelected);

    return (
        <ListItem button alignItems="flex-start" sx={AddressListItem} onClick={onClickAddress}>
            <ListItemIcon>
                <LocationIcon />
            </ListItemIcon>
            <ListItemText
                primary={address.name}
                secondary={
                    address.street + " " + address.city + " " + address.postalCode + " " + address.province.name
                }
            />

            {
                onClickEditAddress ?
                    <ListItemIcon>
                        <IconButton edge="end" onClick={onClickEditAddress}>
                            <EditIcon />
                        </IconButton>
                    </ListItemIcon>
                    :
                    ((addressSelected && addressSelected.id === address.id) ?
                        <ListItemIcon>
                            <IconButton edge="end">
                                <SelectedIcon />
                            </IconButton>
                        </ListItemIcon> : "")
            }
        </ListItem>
    )
}

export default SelectAddressItem