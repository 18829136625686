export class PaymentInfoStatus {

    static readonly Pending = new PaymentInfoStatus('pending');

    static readonly Failed = new PaymentInfoStatus('failed');

    static readonly Confirmed = new PaymentInfoStatus('confirmed');

    static readonly Refunded = new PaymentInfoStatus('refunded');



    constructor(public readonly value: string) { }

    static getValues(): PaymentInfoStatus[] {
        return [
            this.Pending,
            this.Failed,
            this.Confirmed,
            this.Refunded
        ];
    }

    static fromString(string: string): PaymentInfoStatus {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}