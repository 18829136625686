import { Button, Grid } from "@mui/material";
import { FC } from "react"
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FooterLink } from "./styles";

interface LegalLinkProps {
    readonly text: string,
    readonly url: string
}

const LegalLink: FC<LegalLinkProps> = ({ text, url }) => {
    const openUrl = (url: string) => {
        window.open(url, '_blank')
    }

    if (!text || !url) {
        return <></>;
    }

    return (
        <Grid
            item
            component={Link}
            to={''}
            onClick={() => openUrl(url)}
            sx={FooterLink()}
        >
            {text}
        </Grid>
    );
}

export default LegalLink;