import { NotArrayError } from "src/app/errors/NotArrayError";
import { BillingInfo } from '../../../client/domain/entities/BillingInfo';
import { PartnerConfig } from './PartnerConfig';
import { PartnerContact } from './PartnerContact';
import { PartnerCustoms } from './PartnerCustoms';
import { PartnerDefaults } from './PartnerDefaults';
import { PartnerInvoiceConfig } from './PartnerInvoiceConfig';
import { PartnerType } from './PartnerType';

export class Partner {
    constructor(
        readonly id: string,
        readonly clientGuilds: string[],
        readonly config: PartnerConfig,
        readonly invoiceConfig: PartnerInvoiceConfig,
        readonly name: string,
        readonly type: PartnerType,

        readonly billingInfo?: BillingInfo,
        readonly contact?: PartnerContact,
        readonly customs?: PartnerCustoms,
        readonly defaults?: PartnerDefaults,
        readonly photoUrl?: string,
        readonly slug?: string,
    ) { }

    public static fromJson(json: any): Partner {
        return new Partner(
            json['id'],
            json['clientGuilds'],
            PartnerConfig.fromJson(json['config']),
            PartnerInvoiceConfig.fromJson(json['invoiceConfig']),
            json['name'],
            PartnerType.fromString(json['type']),
            json['billingInfo'] ? BillingInfo.fromJson(json['billingInfo']) : undefined,
            json['contact'] ? PartnerContact.fromJson(json['contact']) : undefined,
            json['customs'] ? PartnerCustoms.fromJson(json['customs']) : undefined,
            json['defaults'] ? PartnerDefaults.fromJson(json['defaults']) : undefined,
            json['photoUrl'],
            json['slug']
        );
    }

    public acceptBothClients(): boolean {
        return this.config.acceptsCompanyClients && this.config.acceptsRetailClients;
    }

    public acceptsOnlyCompanyClients(): boolean {
        return this.config.acceptsCompanyClients && !this.config.acceptsRetailClients;
    }

    public acceptsOnlyRetailClients(): boolean {
        return !this.config.acceptsCompanyClients && this.config.acceptsRetailClients;
    }

    public static fromJsonArray(jsonArray: any): Partner[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}