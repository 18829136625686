import { Box, Button, DialogContent, Drawer, Slide, TextField } from '@mui/material';
import { Formik } from 'formik';
import { FC } from 'react';
import DrawerHeader from 'src/app/components/DrawerHeader';
import * as Yup from 'yup';
import { Address } from '../../domain/entities/Address';
import { AcceptButton, Container } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { updateAddressThunk, createAddressThunk } from '../../data/AddressThunk';
import { getAddressSelected, getOpenManageAddress, setOpenManageAddress } from '../../data/AddressSlice';
import useAuth from '../../../../app/hooks/useAuth';
import { useSnackbar, VariantType } from 'notistack';

const addressSchema = Yup.object({
    name: Yup.string().min(3, "Mínimo 3 carácteres")
        .required('Debes introducir un nombre'),
    city: Yup.string()
        .required('Debes de introducir el nombre de la ciudad'),
    phone: Yup.string().trim().min(9, "Faltan números").matches(/^[0-9]+$/, "El teléfono solo debe de estar compuesto por números").required('Debes introducir un teléfono'),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto")
        .length(5, "Código postal incorrecto")
        .required("Debes proporcionar un código postal"),
    street: Yup.string()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la calle, número y piso'),

});

interface ManageAddressDrawerProps { }

const ManageAddressDrawer: FC<ManageAddressDrawerProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const openManageAddress: boolean = useAppSelector(getOpenManageAddress);
    const addressSelected: Address = useAppSelector(getAddressSelected);

    const handleOnClose = (): void => {
        dispatch(setOpenManageAddress({ open: false }))
    }

    const launchSnackbar = (success: boolean): void => {
        enqueueSnackbar(success ? t('Address.SaveSuccess') : t('Adress.SaveError'), {
            variant: success ? 'success' : 'error',
            preventDuplicate: false,
            TransitionComponent: Slide
        });
        if (success) {
            handleOnClose();
        }
    }

    return (
        <Drawer anchor={"right"} open={openManageAddress} onClose={handleOnClose}>
            <DrawerHeader title={addressSelected ? t('Address.Update') : t('Address.Create')} onClickBackButton={handleOnClose} />
            <Formik
                initialValues={{
                    name: addressSelected?.name ?? "",
                    city: addressSelected?.city ?? "",
                    clientId: user.id,
                    phone: addressSelected?.phone ?? "",
                    postalCode: addressSelected?.postalCode ?? "",
                    street: addressSelected?.street ?? "",
                }}
                validationSchema={addressSchema}
                onSubmit={async (values) => {
                    let response: string;
                    if (addressSelected) {
                        response = await dispatch(updateAddressThunk({
                            ...values,
                            addressId: addressSelected.id
                        })).then(response => response.type);
                    } else {
                        response = await dispatch(createAddressThunk(values)).then(response => response.type);
                    }
                    launchSnackbar(!response.includes('reject'));
                }}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogContent sx={Container}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Nombre del destinatario"
                                type="text"
                                color="secondary"
                                variant="outlined"
                                fullWidth
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="street"
                                name="street"
                                label="Calle y Número"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('street')}
                                helperText={props.touched.street && props.errors.street}
                                error={props.touched.street && props.errors.street !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="Ciudad"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('city')}
                                helperText={props.touched.city && props.errors.city}
                                error={props.touched.city && props.errors.city !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="postalCode"
                                name="postalCode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="phone"
                                name="phone"
                                label="Teléfono"
                                type="phone"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('phone')}
                                helperText={props.touched.phone && props.errors.phone}
                                error={props.touched.phone && props.errors.phone !== undefined}
                            />
                            <Box component='div' sx={{ marginTop: 20 }}>
                                <Button fullWidth variant="contained" sx={AcceptButton} type="submit">
                                    GUARDAR
                                </Button>
                            </Box>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
}


export default ManageAddressDrawer;
