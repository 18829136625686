export class ShippingMethodType {
    static readonly AgencyDelivery = new ShippingMethodType('agency_delivery');
    
    static readonly OwnDelivery = new ShippingMethodType('own_delivery');
    
    static readonly TakeAway = new ShippingMethodType('take_away');
    
    static readonly Digital = new ShippingMethodType('digital');

    constructor(public readonly value: string) { }

    static getValues(): ShippingMethodType[] {
        return [this.AgencyDelivery, this.OwnDelivery, this.TakeAway, this.Digital];
    }
    
    static fromString(string: string): ShippingMethodType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}