const CartContainer = (backgroundColor: string) => {
  return {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
    backgroundColor,
  };
};

const CartContinueButton = (color: string): any => {
  return {
    backgroundColor: color,
    borderRadius: ".375rem",
    color: "#FFF",
    paddingLeft: "4px",
    paddingRight: "4px",
    webKitBoxFlex: "0",
    webKitFlex: "0 0 2rem",
    fontSize: "1.125rem",
    lineHeight: "1.78",
    flex: 0.1,
  };
};

const CartFooter = () => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "1rem",
    boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)",
  };
};

export { CartContainer, CartContinueButton, CartFooter };
