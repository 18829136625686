import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics } from "firebase/analytics";

interface FirebaseConfig {
  slug: String;
  config: {
    apiKey: String;
    authDomain: String;
    projectId: String;
    storageBucket: String;
    messagingSenderId: String;
    appId: String;
    measurementId: String;
  };
}

/*BÉCARES*/

const firebaseConfigurations = {
  OROME: {
    apiKey: "AIzaSyBci07sGoz-BUuSUO6yUKSXKJoy0Do3eWw",
    authDomain: "distributiotest.firebaseapp.com",
    projectId: "distributiotest",
    storageBucket: "distributiotest.appspot.com",
    messagingSenderId: "455534020972",
    appId: "1:455534020972:web:fe453193b7d9ac28124b49",
    measurementId: "G-NPQVNP2ZZE",
  },

  // "BECARES": {
  REPRESENTACIONESBECARES: {
    apiKey: "AIzaSyC54kv0UwW_EGKOqXiE-FFPmibhfI8z2OM",
    authDomain: "becaresdistributio-afd89.firebaseapp.com",
    projectId: "becaresdistributio-afd89",
    storageBucket: "becaresdistributio-afd89.appspot.com",
    messagingSenderId: "37148930953",
    appId: "1:37148930953:web:1519eb206a3dc804b5a758",
    measurementId: "G-2QBHW37WM8",
  },

  // "LA TIENDA DE LINO"
  LATIENDADELINO: {
    apiKey: "AIzaSyCoU23pMn4UJ2uZc6bi3G_OaTuvXMDeY-c",
    authDomain: "latiendadelinodistributio.firebaseapp.com",
    projectId: "latiendadelinodistributio",
    storageBucket: "latiendadelinodistributio.appspot.com",
    messagingSenderId: "1069780605997",
    appId: "1:1069780605997:web:2107324bd7d4f6294d2ca4",
    measurementId: "G-6BZYEFF85G",
  },
};

export const initializeFirebase = (slug?: String) => {
  firebase.initializeApp(firebaseConfigurations[slug.toUpperCase()]);
  // getAnalytics(firebase.apps[0]);
};

export default firebase;
