import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Partner } from "../domain/entities/Partner";
import { findPartnerByIdThunk, findPartnerBySlugThunk } from './PartnerThunk';

interface PartnerState {
    partnerSelected?: Partner;
    status: StateStatus;
}

export const initialState: PartnerState = {
    partnerSelected: undefined,
    status: 'ready',
}

const partnerSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {

    },
    extraReducers: (builder: ActionReducerMapBuilder<PartnerState>) => {
        /*
            Find Partner By Id
        */
        builder.addCase(findPartnerByIdThunk.pending, (state: PartnerState) => {
            state.status = 'loading';
        }).addCase(findPartnerByIdThunk.fulfilled, (state: PartnerState, action: PayloadAction<Partner>) => {
            try {
                localStorage.setItem('unregistered_fee_id', action.payload['defaults']['unregisteredFeeId']);
            } catch { }
            localStorage.setItem('partner_id', action.payload.id);
            localStorage.setItem('slug', action.payload.slug)
            state.partnerSelected = action.payload;
            state.status = 'ready';
        }).addCase(findPartnerByIdThunk.rejected, (state: PartnerState) => {
            state.status = 'error';
        });

        /*
            Find Partner By Slug
        */
        builder.addCase(findPartnerBySlugThunk.pending, (state: PartnerState) => {
            state.status = 'loading';
        }).addCase(findPartnerBySlugThunk.fulfilled, (state: PartnerState, action: PayloadAction<Partner>) => {
            try {
                localStorage.setItem('unregistered_fee_id', action.payload['defaults']['unregisteredFeeId']);
            } catch { }
            localStorage.setItem('partner_id', action.payload.id);
            localStorage.setItem('slug', action.payload.slug)
            state.partnerSelected = action.payload;
            
            state.status = 'ready';
        }).addCase(findPartnerBySlugThunk.rejected, (state: PartnerState) => {
            state.status = 'error';
        });
    }
});

const getPartnerSelected = (state: RootState) => state.partner.partnerSelected;

export {
    getPartnerSelected
}

export default partnerSlice.reducer;