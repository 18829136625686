import {
  CreateOrderDto,
  UpdateOrderDto,
  FindClientOrdersDto,
} from "./OrderDto";
import { Order } from "../domain/entities/Order";
import { SearchResponse } from "../../commons/Types";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "src/config/http";

export abstract class OrderService {
  public static async createOrderAsync(
    createOrder: CreateOrderDto
  ): Promise<Order> {
    return Order.fromJson(await postRequest("/orders", createOrder));
  }

  public static async deleteOrderAsync(orderId: string): Promise<Order> {
    return Order.fromJson(await deleteRequest(`/orders/${orderId}`));
  }

  public static async findOrderByIdAsync(orderId: string): Promise<Order> {
    return Order.fromJson(await getRequest(`/orders/${orderId}`));
  }

  public static async searchOrdersByClientAsync(
    findClientOrders: FindClientOrdersDto
  ): Promise<SearchResponse<Order>> {
    const response: SearchResponse<Order> = await getRequest(
      `/clients/${findClientOrders.clientId}/orders`,
      JSON.parse(JSON.stringify(findClientOrders.filter)),
      {},
      true
    );

    return {
      data: Order.fromJsonArray(response.data),
      totalCount: response.totalCount,
    };
  }

  public static async updateOrderAsync(
    updateOrder: UpdateOrderDto
  ): Promise<Order> {
    return Order.fromJson(
      await putRequest(`/orders/${updateOrder.orderId}`, updateOrder)
    );
  }
}
