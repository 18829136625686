import { Address } from "src/features/address/domain/entities/Address";
import { CreditCard } from "src/features/client/domain/entities/CreditCard";
import { PaymentMethod } from "src/features/payment_method/domain/entities/PaymentMethod";
import { ShippingMethod } from "src/features/shipping_method/domain/entities/ShippingMethod";
import { PaymentInfoDto } from "../../data/OrderDto";
import { OrderDetail } from "./OrderDetail";
import { OrderDiscount } from "./OrderDiscount";

export class NewOrder {
    constructor(
        public billingAddress?: Address,
        public clientNote?: string,
        public creditCard?: CreditCard,
        public details?: OrderDetail[],
        public discount?: OrderDiscount,
        public estimatedDeliveryDate?: Date,
        public invoiceRequired?: boolean,
        public paymentInfo?: PaymentInfoDto,
        public paymentMethod?: PaymentMethod,
        public shippingAddress?: Address,
        public shippingMethod?: ShippingMethod,
    ) { }

  public getTotalPrice(withTaxes?: boolean): number {
    const totalPrice: number = this.estimatedPrice(withTaxes);
    if (withTaxes) {
      return totalPrice + this.taxes();
    }

    return totalPrice;
  }

  public getTotalPriceWithShipping(withTaxes?: boolean): number {
    return this.getTotalPrice(withTaxes) + this.getShippingValue();
  }

  public getShippingValue(withTaxes?: boolean): number {
    if (!this.shippingMethod) {
      return 0;
    }

    let shippingValue = 0;
    if (
      !this.shippingMethod.freeFrom ||
      this.getTotalPrice(withTaxes) < this.shippingMethod.freeFrom
    ) {
      shippingValue = this.shippingMethod.value;
    }

    return withTaxes ? shippingValue * 1.21 : shippingValue;
  }

  public estimatedPrice(withTaxes?: boolean): number {
    return (
      this.details?.reduce((a, b) => a + (b.getPrice(withTaxes) || 0), 0) ?? 0
    );
  }

  public taxes(): number {
    let totalTaxes = 0;
    totalTaxes =
      this.details?.reduce((a, b) => a + (b.getTaxes() || 0), 0) ?? 0;
    totalTaxes += this.getShippingValue() * 0.21;
    return totalTaxes;
  }
}
