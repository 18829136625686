import { NotArrayError } from "src/app/errors/NotArrayError";

export class PartnerInvoiceConfig {
    constructor(
        readonly nextNumber: number,
        readonly prefix: string,
    ) { }

    public static fromJson(json: any): PartnerInvoiceConfig {
        return new PartnerInvoiceConfig(
            json['nextNumber'],
            json['prefix'],
        );
    }

    public static fromJsonArray(jsonArray: any): PartnerInvoiceConfig[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}