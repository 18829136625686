import { NotArrayError } from "src/app/errors/NotArrayError";
import { HomeSectionAction } from "./HomeSectionAction";

export class HomeSection {
    constructor(
        readonly id: string,
        readonly action: HomeSectionAction,
        readonly createdAt: Date,
        readonly photoUrl: string,
        readonly priority: number,
        readonly title: string,

        readonly actionable?: string,
        readonly callToActionText?: string,
        readonly description?: string,
    ) { }

    public static fromJson(json: any): HomeSection {
        return new HomeSection(
            json['id'],
            HomeSectionAction.fromString(json['action']),
            new Date(json['createdAt']),
            json['photoUrl'],
            json['priority'],
            json['title'],
            json['actionable'],
            json['callToActionText'],
            json['description']
        );
    }

    public static fromJsonArray(jsonArray: any): HomeSection[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}