import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { FooterGritItem, FooterLogoStyle } from "./styles";

interface FooterLogoProps {
    url: string
}

const FooterLogo: FC<FooterLogoProps> = ({ url }) => {
    if (!url) {
        return <></>;
    }

    return (
        <Grid item sx={FooterGritItem()}>
            <a href='/'>
                <Box
                    component='img'
                    src={url}
                    sx={FooterLogoStyle()}
                    alt='Logo'
                >

                </Box>
            </a>
        </Grid>
    );
}

export default FooterLogo;