import { NotArrayError } from "src/app/errors/NotArrayError";
import { Feature } from "src/features/feature/domain/Feature";
import { Price } from "src/features/price/domain/entities/Price";
import { ProductOption } from "src/features/product/domain/entities/ProductOption";
import { ProductAmountMode } from "../../../product/domain/entities/ProductAmountMode";

export class OrderDetail {
  constructor(
    readonly id: string,
    public amountModeSelected: ProductAmountMode,
    public estimatedAmount: number,
    readonly price: Price,
    public quantity: number,

    public clientNote?: string,
    readonly commercialNote?: string,
    public featuresSelected?: Feature[],
    readonly finalAmount?: number,
    readonly optionsSelected?: ProductOption[]
  ) { }

  getPrice(withTaxes: boolean): number {
    let detailPrice: number;
    if (this.finalAmount) {
      detailPrice = this.finalAmount * this.price.getValue(withTaxes);
    } else {
      detailPrice = this.quantity * this.price.getValue(withTaxes);
    }

    if (this.optionsSelected) {
      this.optionsSelected.forEach(option => {
          detailPrice += option.value * option.quantity;
      });
  }    

    return detailPrice;
  }

  getTaxes(): number {
    let detailTaxes: number = (this.finalAmount ?? this.estimatedAmount) * this.price.getTaxValue();
    if (this.optionsSelected) {
      this.optionsSelected?.forEach((option: ProductOption) => {
        detailTaxes += option.value * option.quantity * (this.price.product.tax.value / 100)
      });
    }
    return detailTaxes;
  }

  getClone(): OrderDetail {
    return OrderDetail.fromJson(this.toJson());
  }

  public static fromJson(json: any): OrderDetail {
    return new OrderDetail(
      json["id"],
      ProductAmountMode.fromString(json["amountModeSelected"]),
      json["estimatedAmount"],
      Price.fromJson(json["price"]),
      json["quantity"],
      json["clientNote"],
      json["commercialNote"],
      json["featuresSelected"] instanceof Array ? json["featuresSelected"].map(feature => Feature.fromJson(feature)) : undefined,
      json["finalAmount"],
      json["optionsSelected"] instanceof Array ? json["optionsSelected"].map(option => ProductOption.fromJson(option)) : undefined,
    );
  }

  public toJson(): any {
    return {
      id: this.id,
      amountModeSelected: this.amountModeSelected.value,
      estimatdAmount: this.estimatedAmount,
      price: this.price.toJson(),
      quantity: this.quantity,
      clientNote: this.clientNote,
      commercialNote: this.commercialNote,
      featuresSelected: this.featuresSelected?.map(feature => feature.toJson()),
      finalAmount: this.finalAmount,
      optionsSelected: this.optionsSelected?.map(optionsSelected => optionsSelected.toJson())
    };
  }

  public static fromJsonArray(jsonArray: any): OrderDetail[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }

  public static fromPrice(price: Price): OrderDetail {
    return new OrderDetail(
      "eSte es un id",
      ProductAmountMode.Units,
      1,
      price,
      1,
      "",
      "",
      undefined
    );
  }

  public getMainPhoto(): string {
    if (this.price.product.photoUrls.length > 0) {
      return this.price.product.photoUrls[0];
    }
    return "";
  }

  public toDto() {
    if (this.amountModeSelected === ProductAmountMode.Units) {
      return {
        amountModeSelected: this.amountModeSelected.value,
        productId: this.price.product.id,
        quantity: this.quantity,
        featureIds: this.featuresSelected?.map((feature: Feature) => feature.id)
      }
    } else {
      return {
        amountModeSelected: this.amountModeSelected.value,
        productId: this.price.product.id,
        quantity: 1,
        estimatedAmount: this.quantity
      }
    }
  }

}
