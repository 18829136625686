import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/config/store";
import { Filters, StateStatus } from "src/features/commons/Entities";
import { SearchResponse } from "src/features/commons/Types";
import { Price } from "../domain/entities/Price";
import { findFeaturedPricesByClientThunk } from "./PriceThunk";
import {
  findPriceByIdThunk,
  findPriceForProductAndClientAsyncThunk,
  findPricesByFeeIdThunk,
  searchPricesByClientIdThunk,
} from "./PriceThunk";
import { OpenEditDetailDto } from "./PriceDto";

interface PriceFilter extends Filters {
  readonly clientId?: string;
  readonly feeId?: string;
  readonly familyId?: string;
}

interface PriceState {
  prices: Price[];
  featuredPrices: Price[];
  openEditDetail: OpenEditDetailDto;
  priceSelected?: Price;
  filter: PriceFilter;
  totalCount?: number;
  status: StateStatus;
}

export const initialState: PriceState = {
  prices: [],
  featuredPrices: [],
  openEditDetail: { open: false, price: undefined },
  priceSelected: undefined,
  filter: {
    clientId: undefined,
    search_text: undefined,
    familyId: undefined,
    feeId: undefined,
    limit: 12,
    offset: 0,
  },
  totalCount: 0,
  status: "ready",
};

const priceSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    selectPrice: (state: PriceState, action: PayloadAction<Price>) => {
      state.priceSelected = action.payload;
    },

    setOpenEditDetail: (
      state: PriceState,
      action: PayloadAction<OpenEditDetailDto>
    ) => {
      state.openEditDetail = action.payload
    },

    changeFilter: (state: PriceState, action: PayloadAction<PriceFilter>) => {
      state.filter = action.payload;
    },
    clearFilter: (state: PriceState) => {
      state.filter = {
        clientId: undefined,
        search_text: undefined,
        familyId: undefined,
        feeId: undefined,
        limit: 12,
        offset: 0,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<PriceState>) => {
    /*
                 Search Prices
            */
    builder
      .addCase(searchPricesByClientIdThunk.pending, (state: PriceState) => {
        state.status = "loading";
      })
      .addCase(
        searchPricesByClientIdThunk.fulfilled,
        (state: PriceState, action: PayloadAction<SearchResponse<Price>>) => {
          state.prices = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.status = "ready";
        }
      )
      .addCase(searchPricesByClientIdThunk.rejected, (state: PriceState) => {
        state.status = "error";
      });

    /*
                Find Featured Prices
           */
    builder
      .addCase(findFeaturedPricesByClientThunk.pending, (state: PriceState) => {
        state.status = "loading";
      })
      .addCase(
        findFeaturedPricesByClientThunk.fulfilled,
        (state: PriceState, action: PayloadAction<Price[]>) => {
          state.featuredPrices = action.payload;
          state.status = "ready";
        }
      )
      .addCase(
        findFeaturedPricesByClientThunk.rejected,
        (state: PriceState) => {
          state.status = "error";
        }
      );

    /*
                FindPrices by feeid
           */
    builder
      .addCase(findPricesByFeeIdThunk.pending, (state: PriceState) => {
        state.status = "loading";
      })
      .addCase(
        findPricesByFeeIdThunk.fulfilled,
        (state: PriceState, action: PayloadAction<SearchResponse<Price>>) => {
          state.prices = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.status = "ready";
        }
      )
      .addCase(findPricesByFeeIdThunk.rejected, (state: PriceState) => {
        state.status = "error";
      });

    /*
                Find Price by product & client
           */
    builder
      .addCase(
        findPriceForProductAndClientAsyncThunk.pending,
        (state: PriceState) => {
          state.status = "loading";
        }
      )
      .addCase(
        findPriceForProductAndClientAsyncThunk.fulfilled,
        (state: PriceState, action: PayloadAction<Price>) => {
          state.priceSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(
        findPriceForProductAndClientAsyncThunk.rejected,
        (state: PriceState) => {
          state.status = "error";
        }
      );

    /*
            Find Price by id
       */
    builder
      .addCase(findPriceByIdThunk.pending, (state: PriceState) => {
        state.status = "loading";
      })
      .addCase(
        findPriceByIdThunk.fulfilled,
        (state: PriceState, action: PayloadAction<Price>) => {
          state.priceSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findPriceByIdThunk.rejected, (state: PriceState) => {
        state.status = "error";
      });
  },
});

const { selectPrice, setOpenEditDetail, changeFilter, clearFilter } =
  priceSlice.actions;

const getFeaturedPrices = (state: RootState) => state.price.featuredPrices;
const getOpenEditOrderDetail = (state: RootState) => state.price.openEditDetail;
const getPrices = (state: RootState) => state.price.prices;
const getPriceSelected = (state: RootState) => state.price.priceSelected;
const getFilters = (state: RootState) => state.price.filter;
const getTotalCount = (state: RootState) => state.price.totalCount;
const getStatus = (state: RootState) => state.price.status;

export {
  selectPrice,
  changeFilter,
  clearFilter,
  getFeaturedPrices,
  getOpenEditOrderDetail,
  getPrices,
  getPriceSelected,
  getFilters,
  getTotalCount,
  getStatus,
  setOpenEditDetail,
};

export default priceSlice.reducer;
