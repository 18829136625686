import { NotArrayError } from "src/app/errors/NotArrayError";
import { ClientType } from "src/features/client/domain/entities/ClientType";
import { Address } from "../../../address/domain/entities/Address";
import { BillingInfo } from "../../../client/domain/entities/BillingInfo";
import { OrderDetail } from "./OrderDetail";
import { OrderDiscount } from "./OrderDiscount";
import { OrderFrom } from "./OrderFrom";
import { PaymentMethod } from "../../../payment_method/domain/entities/PaymentMethod";
import { ErpStatus } from "../../../commons/ErpStatus";
import { ShippingMethod } from "../../../shipping_method/domain/entities/ShippingMethod";
import { OrderStatus } from "./OrderStatus";
import { ClientLite } from "../../../client/domain/entities/ClientLite";
import { PaymentInfoStatus } from "src/features/payment_method/domain/entities/PaymentInfoStatus";

export class Order {
  constructor(
    readonly id: string,
    readonly billingAddress: Address,
    readonly billingInfo: BillingInfo,
    readonly client: ClientLite,
    readonly clientType: ClientType,
    readonly createdAt: Date,
    readonly details: OrderDetail[],
    readonly erpId: string,
    readonly estimatedPrice: number,
    readonly from: OrderFrom,
    readonly paymentMethod: PaymentMethod,
    readonly sendToErp: ErpStatus,
    readonly shippingAddress: Address,
    readonly shippingMethod: ShippingMethod,
    readonly status: OrderStatus,
    readonly taxes: number,

    readonly clientNote?: string,
    readonly commercialId?: string,
    readonly commercialNote?: string,
    readonly discount?: OrderDiscount,
    readonly estimatedDeliveryDate?: Date,
    readonly finalPrice?: number,
    readonly trackingUrl?: string
  ) { }

  public static fromJson(json: any): Order {
    return new Order(
      json["id"],
      Address.fromJson(json["billingAddress"]),
      BillingInfo.fromJson(json["billingInfo"]),
      ClientLite.fromJson(json["client"]),
      ClientType.fromString(json["clientType"]),
      json["createdAt"],
      OrderDetail.fromJsonArray(json["details"]),
      json["erpId"],
      parseInt(json["estimatedPrice"]),
      OrderFrom.fromString(json["from"]),
      PaymentMethod.fromJson(json["paymentMethod"]),
      ErpStatus.fromJson(json["sendToErp"]),
      Address.fromJson(json["shippingAddress"]),
      ShippingMethod.fromJson(json["shippingMethod"]),
      OrderStatus.fromString(json["status"]),
      json["taxes"],
      json["clientNote"],
      json["commercialId"],
      json["commercialNote"],
      json["discount"] ? OrderDiscount.fromJson(json["discount"]) : undefined,
      json["estimatedDeliveryDate"],
      json["finalPrice"] ? parseInt(json["finalPrice"]) : undefined,
      json["trackingUrl"]
    );
  }

  public getTotalPrice(withTaxes?: boolean): number {
    const totalPrice: number = this.finalPrice ?? this.estimatedPrice;
    if (withTaxes) {
      return totalPrice + this.taxes;
    }

    return totalPrice;
  }

  public getShippingValue(withTaxes?: boolean): number {
    let shippingValue = 0;
    if (
      !this.shippingMethod.freeFrom ||
      this.getTotalPrice(false) < this.shippingMethod.freeFrom
    ) {
      shippingValue = this.shippingMethod.value;
    }

    return withTaxes ? shippingValue * 1.21 : shippingValue;
  }

  public needsPayment() {
    if (this.paymentMethod.creditCardRequired && this.paymentMethod.paymentInfo?.status !== PaymentInfoStatus.Confirmed) {
      return true;
    } else {
      return false;
    }
  }

  public isEditable(): boolean {
    return this.status === OrderStatus.InReview;
  }

  public static fromJsonArray(jsonArray: any): Order[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
