import { Link, useTheme } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import CookieConsent from "react-cookie-consent";

interface CookiesProps {
    url: string
}

const Cookies: FC<CookiesProps> = ({ url }) => {
    const theme = useTheme()

    if (!url) {
        return <></>;
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="Aceptar"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "white", background: theme.colors.primary.dark, fontSize: "13px" }}
        >
            {t('Commons.Footer.Cookies.Legend')}
            {
                url
                    ?
                    <Link
                        underline='none'
                        style={{ color: "white", cursor: 'pointer' }}
                        onClick={() => window.open(url, '_blank')}
                    >
                        {t('Commons.Footer.Cookies.Link')}
                    </Link>
                    :
                    <></>
            }
        </CookieConsent>
    );
}

export default Cookies;