import { NotArrayError } from "src/app/errors/NotArrayError";

export class ClientConsumes {
    constructor(
        readonly year: number,
        readonly month: number,
        readonly finalPrice: number
    ) { }

    public static fromJson(json: any): ClientConsumes {
        return new ClientConsumes(
            json['year'],
            json['month'],
            json['monthConsume']
        );
    }

    public static fromJsonArray(jsonArray: any): ClientConsumes[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}