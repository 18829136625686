import { NotArrayError } from "src/app/errors/NotArrayError";

export class PartnerShippingMethod {
    constructor(
        readonly id: string,
        readonly minTotalPrice: number,
        readonly value: number,
        readonly freeFrom?: number
    ) { }

    public static fromJson(json: any): PartnerShippingMethod {
        return new PartnerShippingMethod(
            json['id'],
            json['minTotalPrice'],
            json['value'],
            json['freeFrom']
        );
    }

    public static fromJsonArray(jsonArray: any): PartnerShippingMethod[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}