import { NotArrayError } from "src/app/errors/NotArrayError";

export class PartnerCustoms {
    constructor(
        readonly appLinkUrl?: string,
        readonly cookiesUrl?: string,
        readonly facebookUrl?: string,
        readonly instagramUrl?: string,
        readonly issuesMail?: string,
        readonly logoUrl?: string,
        readonly logoWhiteUrl?: string,
        readonly mainColor?: string,
        readonly photoUrlEmail?: string,
        readonly policyUrl?: string,
        readonly termsUrl?: string,
        readonly theme?: string,
        readonly themeColor?: string,
        readonly twitterUrl?: string
    ) { }

    public static fromJson(json: any): PartnerCustoms {
        return new PartnerCustoms(
            json['appLinkUrl'],
            json['cookiesUrl'],
            json['facebookUrl'],
            json['instagramUrl'],
            json['issuesMail'],
            json['logoUrl'],
            json['logoWhiteUrl'],
            json['mainColor'],
            json['photoUrlEmail'],
            json['policyUrl'],
            json['termsUrl'],
            json['theme'],
            json['themeColor'],
            json['twitterUrl'],
        );
    }

    public static fromJsonArray(jsonArray: any): PartnerCustoms[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}