import { Box, List, Typography } from "@mui/material";
import { PM } from "country-flag-icons/react/3x2";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { modifyCreditCardInNewOrder, modifyPaymentMethodInNewOrder } from "src/features/order/data/OrderSlice";
import { PaymentMethodItem } from "./PaymentMethodItem"
import { PaymentMethod } from "../../domain/entities/PaymentMethod";
import { PaymentMethodListContainerStyles } from "../styles";
import { selectPaymentMethod, setOpenManagePaymentMethod, setOpenSelectCreditCard } from "../../data/PaymentMethodSlice";

interface SelectPaymentMethodListProps {
    onClose: () => void;
    paymentMethods: PaymentMethod[];
    paymentMethodSelected?: PaymentMethod;
}

const SelectPaymentMethodList: FC<SelectPaymentMethodListProps> = ({ onClose, paymentMethods, paymentMethodSelected }) => {
    const dispatch = useDispatch();

    if (!paymentMethods) {
        return <Typography>Ningún método de envío está disponible para tu dirección</Typography>;
    }


    const handlePaymentMethodClicked = (pm: PaymentMethod) => {
        if (pm.creditCardRequired) {
            dispatch(setOpenSelectCreditCard(true))
            dispatch(modifyPaymentMethodInNewOrder(pm))
        } else {
            dispatch(modifyPaymentMethodInNewOrder(pm))
            dispatch(modifyCreditCardInNewOrder(undefined))
            onClose();
        }

    }

    return (
        <Box component='div' sx={PaymentMethodListContainerStyles}>
            <List>
                {
                    paymentMethods.map(paymentMethod =>
                        <PaymentMethodItem
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            paymentMethodSelected={paymentMethodSelected}
                            onClick={handlePaymentMethodClicked}
                        />
                    )
                }
            </List>
        </Box>
    );
}

export default SelectPaymentMethodList;