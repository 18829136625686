import { FC, useEffect, useState } from 'react';
import DrawerHeader from 'src/app/components/DrawerHeader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { getOpenManagePaymentMethod, getPaymentMethodSelected, selectPaymentMethod, setOpenManagePaymentMethod, setOpenSelectCreditCard } from '../../data/PaymentMethodSlice';
import useAuth from '../../../../app/hooks/useAuth';
import { useSnackbar, VariantType } from 'notistack';
import { PaymentMethod } from '../../domain/entities/PaymentMethod';
import { Box, Slide, TextField, Button, DialogContent, Drawer, InputBase, InputLabel, Paper, Snackbar, Typography, useTheme } from '@mui/material';
import stripeIcon from '../assets/poweredStripe.png'
import { useDispatch } from "react-redux";
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
    ActionBarStyles,
    ContainerStyles,
    CardElementStyles,
    CardInputLabelStyles,
    ElementOptionsStyles,
    NameInputStyles,
    InlineElementsStyles,
    LogoStyles,
    ErrorMessageStyles
} from "./styles"
import { PaymentMethodService } from '../../data/PaymentMethodService';
import { getCreditCardsByClientThunk } from '../../data/PaymentMethodThunk';
import Swal, { SweetAlertIcon } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


interface ManageCreditCardDrawerProps {
}

const ManageCreditCardDrawer: FC<ManageCreditCardDrawerProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const ReactSwal = withReactContent(Swal)
    const theme = useTheme();

    const { t } = useTranslation();
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const get: PaymentMethod = useAppSelector(getPaymentMethodSelected);
    const open: boolean = useAppSelector(getOpenManagePaymentMethod)

    const [form, setForm] = useState({
        name: ""
    });

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);

    const [setupClientSecret, setSetupClientSecret] = useState<string>(undefined);

    useEffect(() => {
        if (open) {
            PaymentMethodService.getClientStripeKeyAsync(user.id).then(result => setSetupClientSecret(result)).catch(console.error);
        }
    }, [open])

    const handleOnClose = (): void => {
        dispatch(setOpenManagePaymentMethod(false))
    }

    const launchSnackbar = (success: boolean): void => {
        enqueueSnackbar(success ? t('CreditCard.SaveSuccess') : t('Adress.SaveError'), {
            variant: success ? 'success' : 'error',
            preventDuplicate: false,
            TransitionComponent: Slide
        });
        if (success) {
            handleOnClose();
        }
    }

    const onClickSaveButton = async event => {
        event.preventDefault();
        const result = await ReactSwal.fire({
            customClass: {
                container: "swalert",
                confirmButton: "okButton"
            },
            title: 'Validar tarjeta',
            text: 'Por seguridad, y para validar el uso legítimo de tu tarjeta, es posible que el emisor realice un cargo de 0.00€ en la misma',
            showCancelButton: false,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Entendido',
            confirmButtonColor:
                theme.palette.primary.main,
        })
        if (!result.isConfirmed) {
            return
        }
        if (!stripe || !elements) {
            return;
        }

        setLoading(true)
        const { error, setupIntent } = await stripe
            .confirmCardSetup(setupClientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: form.name,
                        /* address: {
                             postal_code: (account.client && account.client.postalCode) ? account.client.postalCode : "",
                         }*/
                    }
                }
            });

        if (error) {
            setLoading(false);
            setErrorMessage(error.message);
        } else {
            //las recuperamos para tener la nueva card en el slice
            dispatch(getCreditCardsByClientThunk(user.id))
            launchSnackbar(true)
            handleOnClose()
            setLoading(false);
        }
    }


    const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setForm({
            name: event.target.value
        });
    }

    return (
        <Drawer
            ModalProps={{ disableEnforceFocus: true }}
            anchor={"right"}
            //disabled={isLoading}
            open={open}
            onClose={handleOnClose}
        >

            <DrawerHeader title={t('CreditCard.New')} onClickBackButton={handleOnClose} />
            <DialogContent sx={ContainerStyles}>

                <Paper variant="outlined" id="nombre" sx={CardElementStyles}>
                    <InputLabel htmlFor="nombre" sx={CardInputLabelStyles}>Nombre del
                        propietario</InputLabel>

                    <InputBase
                        fullWidth
                        name="name"
                        id="name"
                        onChange={onHandleChange}
                        placeholder="Nombre y apellidos"
                    />
                </Paper>
                <Paper variant="outlined" id="numero" sx={CardElementStyles}>
                    <InputLabel
                        htmlFor="numero"
                        sx={CardInputLabelStyles}>Número de tarjeta</InputLabel>
                    <CardNumberElement options={ElementOptionsStyles} />
                </Paper>

                <div style={InlineElementsStyles}>
                    <Paper variant="outlined" id="caducidad" sx={CardElementStyles} style={{ flexGrow: 2 }}>
                        <InputLabel
                            htmlFor="caducidad"
                            sx={CardInputLabelStyles}>Fecha de
                            caducidad</InputLabel>
                        <CardExpiryElement options={ElementOptionsStyles} />
                    </Paper>
                    <Paper
                        variant="outlined"
                        id="cvc"
                        sx={CardElementStyles}
                        style={{ flexGrow: 2, marginLeft: "1rem" }}>
                        <InputLabel htmlFor="cvc" sx={CardInputLabelStyles}>CVC</InputLabel>
                        <CardCvcElement options={ElementOptionsStyles} />
                    </Paper>
                </div>

                {errorMessage ? <Typography sx={ErrorMessageStyles} >{errorMessage}</Typography> : ""}
                <div>
                    <div style={ActionBarStyles}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={onClickSaveButton}
                            disabled={setupClientSecret === undefined || loading}
                        >
                            {loading ? "GUARDANDO..." : "GUARDAR"}
                        </Button>
                    </div>
                    <img src={stripeIcon} style={LogoStyles} alt={""} />
                </div>
            </DialogContent>

        </Drawer>
    );
}


export default ManageCreditCardDrawer;