import { NotArrayError } from "src/app/errors/NotArrayError";

export class DealerInfo {
    constructor(
        readonly name: string,
        readonly phone: string,
        
        logoUrl: string
    ) { }

    public static fromJson(json: any): DealerInfo {
        return new DealerInfo(
            json['name'],
            json['phone'],
            json['string'],
  
        );
    }

    public static fromJsonArray(jsonArray: any): DealerInfo[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}