import { NotArrayError } from "src/app/errors/NotArrayError";
import { Client } from "src/features/client/domain/entities/Client";
import { Fee } from "../../../fee/domain/entities/Fee";
import { Product } from "../../../product/domain/entities/Product";

export class Price {
  constructor(
    readonly id: string,
    readonly erpId: string,
    readonly product: Product,
    readonly timesOrdered: number,
    readonly value: number,

    readonly clientId?: string,
    readonly compareTo?: number,
    readonly fee?: Fee,
    readonly promo?: string
  ) {}

  getValue(withTax: boolean): number {
    if (withTax) {
      return this.value * (this.product.tax.value / 100) + this.value;
    }

    return this.value;
  }

  getCompareToValue(withTax: boolean): number {
    if (!this.compareTo) {
      return this.getValue(withTax);
    }

    if (withTax) {
      return this.compareTo * this.product.tax.value + this.compareTo;
    }

    return this.compareTo;
  }

  getTaxValue(): number {
    return this.value * this.product.tax.value;
  }

  public static fromJson(json: any): Price {
    return new Price(
      json["id"],
      json["erpId"],
      Product.fromJson(json["product"]),
      json["timesOrdered"],
      json["value"],
      json["client"] ? json.client.id : undefined,
      json["compareTo"],
      json["fee"] ? Fee.fromJson(json["fee"]) : undefined,
      json["promo"]
    );
  }

  public toJson(): any {
    return {
      id: this.id,
      erpId: this.erpId,
      product: this.product.toJson(),
      timesOrdered: this.timesOrdered,
      value: this.value,
      client: this.clientId,
      compareTo: this.compareTo,
      fee: this.fee.toJson(),
      promo: this.promo,
    };
  }

  public static fromJsonArray(jsonArray: any): Price[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
