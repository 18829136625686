import { FC } from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/AddTwoTone';
import { AddressListContainer } from "./styles";
import { useAppSelector, useAppDispatch } from '../../../../config/hooks';
import SelectAddressItem from './SelectAddessItem';
import { Address } from "../../domain/entities/Address";
import { getAddresses, selectAddress } from "../../data/AddressSlice";
import { setOpenManageAddress } from 'src/features/address/data/AddressSlice';
import { modifyShippingAddressInNewOrder } from "src/features/order/data/OrderSlice";

interface SelectAddressListProps {
    readonly editable?: boolean;
    readonly onSelectAddress?: (address: Address) => void;
}

const SelectAddressList: FC<SelectAddressListProps> = ({ editable, onSelectAddress }) => {
    const dispatch = useAppDispatch();
    const addresses = useAppSelector(getAddresses);

    const handleOnClickAddress = (address: Address): void => {
        dispatch(selectAddress(address));
        dispatch(modifyShippingAddressInNewOrder(address));
        if (onSelectAddress) {
            onSelectAddress(address)
        }
    }

    const handleOnClickEditAddress = (address?: Address): void => {
        dispatch(setOpenManageAddress({ address, open: true }));
    }

    return (
        <Box component={'span'} sx={AddressListContainer}>
            <List>
                {
                    addresses.map(address =>
                        <SelectAddressItem
                            key={address.id}
                            address={address}
                            onClickAddress={() => handleOnClickAddress(address)}
                            onClickEditAddress={editable ? (() => handleOnClickEditAddress(address)) : undefined} />)
                }
                <ListItem key={"listItem"} onClick={() => handleOnClickEditAddress()}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`AÑADIR UNA NUEVA DIRECCIÓN`}
                    />
                </ListItem>
            </List>
        </Box>
    );

}

export default SelectAddressList;