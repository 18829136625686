import { NotArrayError } from "src/app/errors/NotArrayError";
import { Coordinates } from './Coordinates';
import { Location } from "./Location";
import { AddressType } from './AddressType';
import { ErpStatus } from '../../../commons/ErpStatus';

export class Address {
    constructor(
        readonly id: string,
        readonly city: string,
        readonly country: Location,
        readonly erpId: string,
        readonly name: string,
        readonly postalCode: string,
        readonly province: Location,
        readonly street: string,
        readonly type: AddressType,

        readonly coords?: Coordinates,
        readonly erpStatus?: ErpStatus,
        readonly phone?: string,

    ) { }

    public static fromJson(json: any): Address {
        return new Address(
            json['id'],
            json['city'],
            Location.fromJson(json['country']),
            json['erpId'],
            json['name'],
            json['postalCode'],
            Location.fromJson(json['province']),
            json['street'],
            AddressType.fromString(json['type']),
            
            json['coords'] ? Coordinates.fromJson(json['coords']) : undefined,
            json['sendToErp'] ? ErpStatus.fromJson(json['sendToErp']) : undefined,
            json['phone']
        );
    }

    public static fromJsonArray(jsonArray: any): Address[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}