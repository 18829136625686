import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import 'src/index.css';
// import 'src/config/googlemaps';

import { SidebarProvider } from 'src/contexts/SidebarContext';
import ScrollTop from 'src/app/hooks/useScrollTop';
import { store } from 'src/config/store';
import App from 'src/App';


ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
      
            <App />
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
