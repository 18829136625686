const DrawerAccountCloseButton = () => {
  return {
    backgroundColor: "#ff373d",
    color: "white",
  };
};

const DrawerAccountContainer = (backgroundColor: string) => {
  return {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
    backgroundColor,
  };
};

const DrawerAccountFooter = () => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "1rem",
    boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)",
  };
};

const DrawerAccountTitle = () => {
  return {
    flexGrow: 100,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  };
};

const DrawerAccountTopBar = () => {
  return {
    boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
    display: "flex",
    height: 56,
    paddingLeft: 5,

    backgroundColor: "white",
  };
};

export {
  DrawerAccountCloseButton,
  DrawerAccountContainer,
  DrawerAccountFooter,
  DrawerAccountTitle,
  DrawerAccountTopBar,
};
