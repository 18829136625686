export enum NavigationRoutes {
    ActivateClient = '/activate_client',
    Families = '/categories/:family_id',
    FamilyProducts = '/categories/:family_id/products',
    Login = '/login',
    Main = '/main',
    ProductDetail = '/products/:price_id',//ojo que la ruta es de products pero recibe un price_id
    Profile = '/profile/:user_id',
    RecoveryPassword = '/recovery_password',
    SignUp = '/sign_up',
}