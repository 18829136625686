import { createAsyncThunk } from "@reduxjs/toolkit";
import { Order } from "../domain/entities/Order";
import {
  CreateOrderDto,
  UpdateOrderDto,
  FindClientOrdersDto,
} from "./OrderDto";
import { OrderService } from "./OrderService";
import { SearchResponse } from "../../commons/Types";

export const createOrderThunk = createAsyncThunk(
  "order/create",
  async (createOrder: CreateOrderDto): Promise<Order> =>
    OrderService.createOrderAsync(createOrder)
);

export const deleteOrderThunk = createAsyncThunk(
  "order/delete",
  async (orderId: string) => OrderService.deleteOrderAsync(orderId)
);

export const searchOrdersThunk = createAsyncThunk(
  "order/search",
  async (
    findClientOrders: FindClientOrdersDto
  ): Promise<SearchResponse<Order>> =>
    OrderService.searchOrdersByClientAsync(findClientOrders)
);

export const updateOrderThunk = createAsyncThunk(
  "order/update",
  async (updateOrder: UpdateOrderDto): Promise<Order> =>
    OrderService.updateOrderAsync(updateOrder)
);

export const findOrderByIdThunk = createAsyncThunk(
  "order/find_by_id",
  async (orderId: string): Promise<Order> =>
    OrderService.findOrderByIdAsync(orderId)
);
