import { useEffect } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, DialogContent, Drawer, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { getOpenBillingInfo, setOpenBillingInfo } from '../../data/ClientSlice';
import DrawerHeader from 'src/app/components/DrawerHeader';
import { useState } from 'react';
import { BillingInfo } from '../../domain/entities/BillingInfo';
import { ClientService } from '../../data/ClientService';
import useAuth from '../../../../app/hooks/useAuth';
import { updateClientThunk } from '../../data/ClientThunk';
import { BillingInfoAcceptButton, BillingInfoContainer } from './styles';

const clientSchema = Yup.object({
    name: Yup.string().min(3, "Mínimo 3 carácteres").required('Debes introducir un nombre'),
    street: Yup.string().min(5, 'Mínimo 5 carácteres').required('Debes de introducir la calle, número y piso'),
    city: Yup.string().required('Debes de introducir el nombre de la ciudad'),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto").length(5, "Código postal incorrecto").required("Debes proporcionar un código postal"),
    phone: Yup.string().trim().min(9, "Faltan números").matches(/^[0-9]+$/, "El teléfono solo debe de estar compuesto por números").required('Debes introducir un teléfono'),
    cif: Yup.string().trim().length(9, "El CIF/NIF/NIE tiene que tener 9 caracteres").required('Debes introducir un CIF/NIF/NIE'),
    company: Yup.string().trim().min(3, "Mínimo 3 carácteres").required('Debes introducir  un nombre válido'),
})

const BillingInfoDrawer = ({ }) => {
    const dispatch = useAppDispatch();
    const open = useAppSelector(getOpenBillingInfo);
    const { user } = useAuth();
    const [billingInfo, setBillingInfo] = useState<BillingInfo>();

    const handleOnClose = (): void => {
        dispatch(setOpenBillingInfo(false));
    }

    useEffect(() => {
        if (open) {
            ClientService.findClientByIdAsync(user.id).then(client => {
                setBillingInfo(client.billingInfo);
            })
        }
    }, [open]);

    return (
        <Drawer anchor={"right"} open={open} onClose={handleOnClose}>
            <DrawerHeader title="Datos de facturación" onClickBackButton={handleOnClose} />
            <Formik
                initialValues={{
                    street: billingInfo?.street ?? "",
                    city: billingInfo?.city ?? "",
                    postalCode: billingInfo?.postalCode ?? "",
                    cif: billingInfo?.nif ?? "",
                    company: billingInfo?.name ?? ""
                }}
                enableReinitialize
                validationSchema={clientSchema}
                onSubmit={(values) => {

                    dispatch(updateClientThunk({
                        ...values,
                        clientId: user.id
                    }));
                }}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogContent sx={BillingInfoContainer}>
                            <TextField
                                margin="dense"
                                id="street"
                                name="street"
                                label="Calle y Número"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('street')}
                                helperText={props.touched.street && props.errors.street}
                                error={props.touched.street && props.errors.street !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="Ciudad"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('city')}
                                helperText={props.touched.city && props.errors.city}
                                error={props.touched.city && props.errors.city !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="postalCode"
                                name="postalCode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode !== undefined}
                            />
                            <TextField
                                margin="dense"
                                id="cif"
                                name="cif"
                                label="CIF/NIF/NIE"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('cif')}
                                helperText={props.touched.cif && props.errors.cif}
                                error={props.touched.cif && props.errors.cif !== undefined}
                            />

                            <TextField
                                margin="dense"
                                id="company"
                                name="company"
                                label="Razón Social"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('company')}
                                helperText={props.touched.company && props.errors.company}
                                error={props.touched.company && props.errors.company !== undefined}
                            />

                            <Box component="div" sx={{ marginTop: 20 }}>
                                <Button fullWidth variant="contained" sx={BillingInfoAcceptButton} type="submit">
                                    {"GUARDAR"}
                                </Button>
                            </Box>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
}


export default BillingInfoDrawer;