import { useRef, useState } from 'react';
import useAuth from 'src/app/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Divider,
    Popover,
    Typography,
    styled,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { NavigationRoutes } from 'src/app/navigation/NavigationRoutes';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
`
);

function LoginButton() {
    const theme = useTheme();
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef<any>(null);

    const { user } = useAuth();

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    if (user) {
        return <></>;
    }

    return (
        <>
            <Box component="span">
                <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                    ACCEDER
                    <ExpandMoreTwoToneIcon
                        fontSize="small"
                    />
                </UserBoxButton>
                <Popover
                    disableScrollLock
                    anchorEl={ref.current}
                    onClose={handleClose}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <Box sx={{ minWidth: '300px' }} display={'flex'} flexDirection={'column'} >
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: '400',
                                textAlign: 'center',
                                mt: 1,
                                mb: 1,
                                pl: 5,
                                pr: 5
                            }}
                        >
                            Opciones
                        </Typography>
                        <Divider sx={{ bgcolor: 'grey' }} />
                        <Button
                            onClick={() => {
                                return navigate(NavigationRoutes.Login)
                            }}
                            variant="outlined"
                            sx={{
                                backgroundColor: `${theme.colors.primary.dark}`,
                                borderColor: `${theme.colors.primary.dark}`,
                                color: 'white',
                                mt: 2,
                                ml: 2,
                                mr: 2,
                                fontSize: 16,
                                borderWidth: '2px',
                                '&:hover': {
                                    borderWidth: '2px',
                                    backgroundColor: 'white',
                                    color: `${theme.colors.primary.dark}`,
                                }
                            }}
                        >
                            Iniciar sesión
                        </Button>
                        <Button
                            onClick={() => {
                                return navigate(NavigationRoutes.SignUp)
                            }}
                            variant="outlined"
                            sx={{
                                m: 2,
                                fontSize: 16,
                                borderWidth: '2px',
                                '&:hover': {
                                    borderWidth: '2px'
                                }
                            }}
                        >
                            Crear cuenta
                        </Button>
                    </Box>
                </Popover>
            </Box >
        </>
    );
}

export default LoginButton;
