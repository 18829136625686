import { Box, List, Typography } from "@mui/material";
import { FC } from "react";
import { ShippingMethod } from "../../domain/entities/ShippingMethod";
import SelectShippingMethodItem from "./SelectShippingMethodItem";
import { ShippingMethodListContainerStyles } from "./styles";

interface SelectShippingMethodListProps {
    onClose: () => void;
    shippingMethods: ShippingMethod[];
    shippingMethodSelected?: ShippingMethod;
}

const SelectShippingMethodList: FC<SelectShippingMethodListProps> = ({ onClose, shippingMethods, shippingMethodSelected }) => {
    if (!shippingMethods) {
        return <Typography>Ningún método de envío está disponible para tu dirección</Typography>;
    }


    return (
        <Box component='div' sx={ShippingMethodListContainerStyles}>
            <List>
                {
                    shippingMethods.map(shippingMethod =>
                        <SelectShippingMethodItem
                            key={shippingMethod.id}
                            shippingMethod={shippingMethod}
                            shippingMethodSelected={shippingMethodSelected}
                            onClose={onClose}
                        />
                    )
                }
            </List>
        </Box>
    );
}

export default SelectShippingMethodList;