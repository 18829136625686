import { FC } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCardTwoTone';
import ReceiptIcon from '@mui/icons-material/ReceiptTwoTone';
import HomeIcon from '@mui/icons-material/HomeTwoTone';
import HistoryIcon from '@mui/icons-material/HistoryTwoTone';

import { DrawerAccountCloseButton, DrawerAccountContainer, DrawerAccountFooter } from './styles';
import DrawerButtonItem from './DrawerButtonItem';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/app/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { DrawerTypes } from 'src/features/commons/Types';
import DrawerHeader from 'src/app/components/DrawerHeader';
import { Box, Button, Drawer, List, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { setOpenSelectAddress } from 'src/features/address/data/AddressSlice';
import { getOpenProfile, setOpenBillingInfo, setOpenProfile } from 'src/features/client/data/ClientSlice';
import { setOpenOrderHistory } from 'src/features/order/data/OrderSlice';
import { setOpenSelectCreditCard } from 'src/features/payment_method/data/PaymentMethodSlice';

interface AccountDrawerProps { }

const AccountDrawer: FC<AccountDrawerProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const openProfile: boolean = useAppSelector(getOpenProfile);
    const theme = useTheme();

    const handleOnClickButton = (drawerType: DrawerTypes): void => {
        switch (drawerType) {
            case 'billing_info':
                dispatch(setOpenBillingInfo(true));
                break;
            case 'order_history':
                dispatch(setOpenOrderHistory(true));
                break;
            case 'shipping':
                dispatch(setOpenSelectAddress(true));
                break;
            case 'credit_card':
                dispatch(setOpenSelectCreditCard(true));
                break;
        }
    }

    const handleOnClose = () => {
        dispatch(setOpenProfile(false));
    }

    const handleOnClickCloseSession = async () => {
        try {
            await logout();
            handleOnClose();
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <Drawer anchor={"right"} open={openProfile} onClose={handleOnClose} >
                <DrawerHeader title="Tu cuenta" onClickBackButton={handleOnClose} />
                <Box component="span" sx={DrawerAccountContainer(theme.palette.background.paper)}>
                    <List component="nav" aria-label="main mailbox folders">
                        <DrawerButtonItem title={t('Client.YourAddresses')} icon={HomeIcon} onClick={() => handleOnClickButton('shipping')} />
                        <DrawerButtonItem title={t('Client.PaymentData')} icon={CreditCardIcon} onClick={() => handleOnClickButton('credit_card')} />
                        <DrawerButtonItem title={t('Client.OrdersHistory')} icon={HistoryIcon} onClick={() => handleOnClickButton('order_history')} />
                        <DrawerButtonItem title={t('Client.BillingInfo')} icon={ReceiptIcon} onClick={() => handleOnClickButton('billing_info')} />
                    </List>

                    <Box sx={DrawerAccountFooter}>
                        <Button variant={"contained"} type="submit" sx={DrawerAccountCloseButton} fullWidth onClick={handleOnClickCloseSession}>
                            {t('Commons.Login.LogOut')}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}

export default AccountDrawer;