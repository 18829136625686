const OrderListContainer = {
  margin: 0,
  minWidth: "calc(min(100vw, 375px))",
  maxWidth: "calc(min(100vw, 375px))",
  overflowY: "scroll",
};

const OrderListOrderItem = {
  paddingLeft: "0px",
  paddingRight: "0px",
  alignItems: "center",
  justifyItems: "center",
  background: "white",
  borderBottom: "1px solid #f5f5f5",
};

export { OrderListContainer, OrderListOrderItem };
