import { createAsyncThunk } from '@reduxjs/toolkit';
import { Family } from '../domain/entities/Family';
import { FamilyFilterDto } from './FamilyDto';
import { FamilyService } from './FamilyService';


export const searchFamiliesThunk = createAsyncThunk(
    'family/public',
    async (filters: FamilyFilterDto): Promise<Family[]> => FamilyService.searchFamilysAsync(filters)
);

export const searchFamiliesByClientIdThunk = createAsyncThunk(
    'family/client',
    async (clientId: string): Promise<Family[]> => FamilyService.searchFamiliesByClientAsync(clientId)
)

export const findFamilyByIdThunk = createAsyncThunk(
    'family/find_by_id',
    async (familyId: string): Promise<Family> => FamilyService.findFamilyByIdAsync(familyId)
);