import { Box, List } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../../config/hooks';
import { getOrders, setOpenOrderDetails } from '../../data/OrderSlice';
import OrderItem from './OrderItem';
import { OrderListContainer } from './styles';
import { Order } from '../../domain/entities/Order';

const OrdersList = ({ }) => {
    const dispatch = useAppDispatch();
    const orders = useAppSelector(getOrders);

    const handleOnClickOrder = (order: Order): void => {
        dispatch(setOpenOrderDetails({ open: true, order }));
    }

    return (
        <Box component="div" sx={OrderListContainer}>
            <List >
                {
                    orders.map(order => <OrderItem key={order.id} order={order} onClickOrder={() => handleOnClickOrder(order)} />)
                }
            </List>

        </Box>

    )

}

export default OrdersList;
