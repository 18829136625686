import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FC } from "react";
import { Order } from "../../domain/entities/Order";
import { OrderListOrderItem } from "./styles";
import OrderIcon from '@mui/icons-material/LocalMallTwoTone';
import RightIcon from '@mui/icons-material/ChevronRightTwoTone';
import moment from 'moment';
import useAuth from '../../../../app/hooks/useAuth';

interface OrderItemProps {
    readonly order: Order;

    readonly onClickOrder: () => void;
}

const OrderItem: FC<OrderItemProps> = ({ order, onClickOrder }) => {
    const { user } = useAuth();

    return (
        <ListItem alignItems="flex-start" sx={OrderListOrderItem} onClick={onClickOrder}>
            <ListItemIcon>
                <OrderIcon />
            </ListItemIcon>
            <ListItemText
                primary={order.erpId}
                secondary={
                    <>
                        <Typography>
                            {
                                moment(order.createdAt).format("DD/MM/YYYY")
                            }
                        </Typography>
                        <Typography>
                            {
                                "Importe total: " + (order.getTotalPrice(user?.type !== 'company')).toFixed(2) + "€"
                            }
                        </Typography>
                    </>

                }
            />
            <ListItemIcon>
                <IconButton edge="end" aria-label="comments">
                    <RightIcon />
                </IconButton>
            </ListItemIcon>
        </ListItem>
    )
};

export default OrderItem;