import { NotArrayError } from "src/app/errors/NotArrayError";

export class PartnerContact {
    constructor(
        readonly email?: string,
        readonly facebook?: string,
        readonly instagram?: string,
        readonly phone?: string,
        readonly whatsapp?: string,
    ) { }

    public static fromJson(json: any): PartnerContact {
        return new PartnerContact(
            json['email'],
            json['facebook'],
            json['instagram'],
            json['phone'],
            json['whatsapp'],
        );
    }

    public static fromJsonArray(jsonArray: any): PartnerContact[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}