import React, { lazy } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from 'src/app/hooks/useAuth';
import MainLayout from "src/app/layouts/AccentHeaderLayout";
import { ProtectedRoute } from "./ProtectedRoute";
import { NavigationRoutes } from "./NavigationRoutes";
import { Loader } from "src/app/components/SuspenseLoader";
import { AuthProvider } from "src/contexts/FirebaseAuthContext";
import AppInit from "../components/AppInit";

const ActivateClientPage = Loader(lazy(() => import('src/app/pages/activate_client')));
const FamiliesPage = Loader(lazy(() => import('src/app/pages/families')));
const MainPage = Loader(lazy(() => import('src/app/pages/main')));
const LoginPage = Loader(lazy(() => import('src/app/pages/login')));
const FamilyPricesPage = Loader(lazy(() => import('src/app/pages/family_prices')));
const RecoveryPasswordPage = Loader(lazy(() => import('src/app/pages/recovery_password')));
const SignUpPage = Loader(lazy(() => import('src/app/pages/sign_up')));
const UserProfilePage = Loader(lazy(() => import('src/app/pages/user_profile')));
const ProductDetailPage = Loader(lazy(() => import('src/app/pages/price_detail')));

const redirectToHome = (): any => {
    return NavigationRoutes.Main;
}

const AppNavigation = () => {
    const { user, isInitialized } = useAuth();
    const auth = useAuth();
    const mainRoute = [

    ];

    const publicRoutes = [
        <Route
            key={NavigationRoutes.Login}
            path={NavigationRoutes.Login}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={redirectToHome()}
                >
                    <LoginPage />
                </ProtectedRoute>
            }
        />,
        <Route
            key={NavigationRoutes.RecoveryPassword}
            path={NavigationRoutes.RecoveryPassword}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={redirectToHome()}
                >
                    <RecoveryPasswordPage />
                </ProtectedRoute>
            }
        />,
        <Route key={'signUpRoutes'}>
            <Route
                key={NavigationRoutes.SignUp}
                path={NavigationRoutes.SignUp}
                element={
                    <ProtectedRoute
                        isAllowed={user === null}
                        redirectPath={NavigationRoutes.Main}>
                        <SignUpPage />
                    </ProtectedRoute>
                }
            />
        </Route >,
        <Route key={'activateClientRoutes'}>
            <Route
                key={NavigationRoutes.ActivateClient}
                path={NavigationRoutes.ActivateClient}
                element={
                    <ProtectedRoute
                        isAllowed={user === null}
                        redirectPath={NavigationRoutes.Main}>
                        <ActivateClientPage />
                    </ProtectedRoute>
                }
            />
        </Route >,

        <Route key={'mainRoute'} element={<MainLayout />}>
            <Route
                key={NavigationRoutes.Families}
                path={NavigationRoutes.Families}
                element={
                    <FamiliesPage />
                }
            />
            <Route
                key={NavigationRoutes.Main}
                path={NavigationRoutes.Main}
                element={
                    <MainPage />
                }
            />
            <Route
                key={NavigationRoutes.FamilyProducts}
                path={NavigationRoutes.FamilyProducts}
                element={
                    <FamilyPricesPage />
                }
            />
            <Route
                key={NavigationRoutes.ProductDetail}
                path={NavigationRoutes.ProductDetail}
                element={
                    <ProtectedRoute
                        isAllowed={true}
                        redirectPath={redirectToHome()}
                    >
                        <ProductDetailPage />
                    </ProtectedRoute>
                }
            />,
        </Route>

    ];

    const privateRoutes = [
        <Route key={'privateRoutes'} element={user ? <MainLayout /> : undefined}>

            <Route
                key={NavigationRoutes.Profile}
                path={NavigationRoutes.Profile}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <UserProfilePage />
                    </ProtectedRoute>}
            />
        </Route>
    ];

    const homePath = (
        <Route path="/" element={<Navigate to={redirectToHome()} replace />} />
    );

    const unknownPath = (
        <Route path="*" element={<Navigate to={redirectToHome()} replace />} />

    );

    return (
        <AuthProvider>

            {auth.isInitialized ?
                <Routes>
                    {homePath}
                    {publicRoutes}
                    {privateRoutes}
                    {unknownPath}
                </Routes > 
            :
            <AppInit />}
        </AuthProvider>
    );
}

export default AppNavigation;