import { Divider, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import RightIcon from '@mui/icons-material/ChevronRightTwoTone';
import { FC } from "react";

interface DrawerButtonProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    title: string,

    onClick: () => void;
}

const DrawerButtonItem: FC<DrawerButtonProps> = ({ icon: Icon, title, onClick }) => {
    return (
        <>
            <ListItem button onClick={onClick}>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={title} primaryTypographyProps={{ color: 'black' }} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label={title}>
                        <RightIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    );

}

export default DrawerButtonItem;