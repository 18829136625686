export class HomeSectionAction {
    static readonly Product = new HomeSectionAction('product');

    static readonly Family = new HomeSectionAction('family');

    static readonly Web = new HomeSectionAction('web');

    static readonly Order = new HomeSectionAction('order');

    static readonly Venues = new HomeSectionAction('venues');

    static readonly NotActionable = new HomeSectionAction('not_actionable');

    constructor(public readonly value: string) { }

    static getValues(): HomeSectionAction[] {
        return [this.Product, this.Family, this.Web, this.Order, this.Venues, this.NotActionable];
    }

    static fromString(string: string): HomeSectionAction {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}