const OrderDetailsContainer = (backgroundColor: string) => {
  return {
    margin: 0,
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
    backgroundColor,
    overflowY: "scroll",
  };
};

const OrderDetaisDivider = () => {
  return { height: "1px" };
};

const DetailsInOrderContainer = () => {
  return {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
  };
};

const DetailsInOrderListPaper = () => {
  return {
    background: "black",
  };
};

const DetailsInOrderListDetailItem = () => {
  return {
    paddingLeft: "0px",
    paddingRight: "12.5%",
    alignItems: "center",
    justifyItems: "center",
    background: "white",
    borderBottom: "1px solid #f5f5f5",
  };
};

const DetailsInOrderListDetailPrice = () => {
  return {
    display: "flex",
    webkitBoxOrient: "vertical",
    webKitBoxDirection: "normal",
    webkitFlexDirection: "column",
    flexDirection: "column",
    webkitBoxAlign: "end",
    webkitAlignItems: "flex-end",
    alignItems: "flex-end",
    overflow: "hidden",
    webkitFlexShrink: 0,
    flexShrink: 0,
    position: "relative",
  };
};

const DetailsInOrderListDetailProductName = () => {
  return {
    alignItems: "center",
    lineHeight: "1",
  };
};

const DetailsInOrderListDetailProductImage = () => {
  return {
    marginTop: 0,
  };
};

export {
  OrderDetailsContainer,
  OrderDetaisDivider,
  DetailsInOrderContainer,
  DetailsInOrderListPaper,
  DetailsInOrderListDetailItem,
  DetailsInOrderListDetailPrice,
  DetailsInOrderListDetailProductName,
  DetailsInOrderListDetailProductImage,
};
