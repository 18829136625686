import { createAsyncThunk } from '@reduxjs/toolkit';
import { HomeSection } from '../domain/entities/HomeSection';
import { HomeSectionService } from './HomeSectionService';


export const searchHomeSectionsThunk = createAsyncThunk(
    'home_section/search',
    async (): Promise<HomeSection[]> =>
        HomeSectionService.searchHomeSectionsAsync()
);



