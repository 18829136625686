import { NotArrayError } from "src/app/errors/NotArrayError";
import { NotificationAction } from "./NotificationAction";

export class Notification {
  constructor(
    readonly id: string,
    readonly action: NotificationAction,
    readonly createdAt: Date,
    readonly priority: number,
    readonly showInHome: boolean,
    readonly tags: string[],
    readonly validFrom: Date,

    readonly actionable?: string,
    readonly description?: string,
    readonly photoUrl?: string,
    readonly title?: string,
    readonly url?: string,
    readonly validUntil?: Date
  ) {}

  public static fromJson(json: any): Notification {
    return new Notification(
      json["id"],
      NotificationAction.fromString(json["action"]),
      new Date(json["createdAt"]),
      json["priority"],
      json["showInHome"],
      json["tags"],
      new Date(json["validFrom"]),
      json["actionable"],
      json["description"],
      json["photoUrl"],
      json["title"],
      json["url"],
      json["validUntil"] ? new Date(json["validUntil"]) : undefined
    );
  }

  public static fromJsonArray(jsonArray: any): Notification[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
