import { alpha, Tooltip, IconButton, styled, Badge } from "@mui/material";
import { t } from "i18next";
import CartIcon from '@mui/icons-material/ShoppingCartTwoTone';
import { FC } from "react";
import { useAppSelector } from '../../../../../../config/hooks';
import { getNewOrder } from 'src/features/order/data/OrderSlice';

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
          margin-left: ${theme.spacing(1)};
          background: ${theme.colors.alpha.trueWhite[10]};
          color: ${theme.colors.alpha.trueWhite[70]};
          padding: 0;
          width: 42px;
          height: 42px;
          border-radius: 10%;
          transition: ${theme.transitions.create(['background', 'color'])};
      
          &.active,
          &:active,
          &:hover {
            background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
            color: ${theme.colors.alpha.trueWhite[100]};
          }
      `
);

interface CartButtonProps {
    readonly onClickCartButton: () => void;
}

const CartButton: FC<CartButtonProps> = ({ onClickCartButton }) => {
    const detailsInCart: number = useAppSelector(getNewOrder).details?.length ?? 0;

    if (detailsInCart > 0) {
        return (
            <Tooltip arrow title={t('Cart.Title')} onClick={onClickCartButton} >
                <Badge color="error" badgeContent={detailsInCart}>
                    <IconButtonPrimary color="primary" >
                        <CartIcon />
                    </IconButtonPrimary>
                </Badge>
            </Tooltip>
        )
    }

    return (
        <Tooltip arrow title={t('Cart.Title')} onClick={onClickCartButton} >
            <IconButtonPrimary color="primary" >
                <CartIcon />
            </IconButtonPrimary>
        </Tooltip>
    );
}

export default CartButton;