const BillingInfoAcceptButton = {
  marginBottom: "1rem",
  backgroundColor: "#4fd053",
  color: "white",
  "&:hover": {
    backgroundColor: "#47ba4a",
  },
  width: "100%",
};

const BillingInfoContainer = {
  minWidth: "calc(min(100vw, 375px))",
  maxWidth: "calc(min(100vw, 375px))",
};

export { BillingInfoAcceptButton, BillingInfoContainer };
