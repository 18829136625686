import { PaymentMethod } from '../domain/entities/PaymentMethod';
import { getRequest, postRequest } from 'src/config/http';
import { CreditCard } from 'src/features/client/domain/entities/CreditCard';

export abstract class PaymentMethodService {

    public static async searchPaymentMethodsByTypeAsync(type?: string): Promise<PaymentMethod[]> {
        return PaymentMethod.fromJsonArray(await getRequest('/payment_methods', type ? { type } : {}));
    }

    public static async getClientStripeKeyAsync(clientId: string): Promise<string> {
        return postRequest(`/clients/${clientId}/stripe_keys`, {}).then(result => result.clientSecret);
    }

    public static async getCreditCardsByClient(clientId: string): Promise<CreditCard[]> {
        return CreditCard.fromJsonArray(await getRequest(`/clients/${clientId}/credit_cards`, {}));
    }

    public static async getStripeOrderKeyAsync(orderId: string) {
        return postRequest(`/orders/${orderId}/stripe_keys`, {})
            .then(result => result.clientSecret)
            .catch(error => new Error(error));
    }

}