import firebase from "firebase/compat/app";
import "firebase/compat/auth";

interface StripeConfig {
  publicKey: string;
}

export const stripeConfigurations: Record<string, StripeConfig> = {
  orome: {
    publicKey: "AIzaSyBci07sGoz-BUuSUO6yUKSXKJoy0Do3eWw",
  },

  representacionesbecares: {
    publicKey:
      "pk_test_51LMrCmFWEQ8QASKx39ugmWEB2OBe9zRsJd9gMVAdGSxsCPRsDJaRHaZtpMr7AmRABPxyg5V5oltYdnkg6D3e4fKr00H5HlaTgM",
  },

  // "LA TIENDA DE LINO"
  latiendadelino: {
    publicKey:
      "pk_live_51LUXOVJOSfirwDy1K10hXZhKOkWswO9q5UaiQSuaVkaZWHcqPYI8tA17tlO2mM3lK1fwZMlavXH5vWaz2ZT1QOXq00T0yv6tGL",
  },
};
