export class ProductSalesFormat {
    static readonly Weight = new ProductSalesFormat('weight');

    static readonly Units = new ProductSalesFormat('units');

    constructor(public readonly value: string) { }

    static getValues(): ProductSalesFormat[] {
        return [this.Weight, this.Units];
    }
    
    static fromString(string: string): ProductSalesFormat {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}