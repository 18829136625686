export class ClientType {
    static readonly Company = new ClientType('company');

    static readonly Retail = new ClientType('retail');

    static readonly Distributor = new ClientType('distributor');

    constructor(public readonly value: string) { }

    static getValues(): ClientType[] {
        return [this.Company, this.Retail, this.Distributor];
    }
    
    static fromString(string: string): ClientType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}