import { CircularProgress, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerHeader from "src/app/components/DrawerHeader";
import useAuth from "src/app/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import { getNewOrder } from "src/features/order/data/OrderSlice";
import { NewOrder } from "src/features/order/domain/entities/NewOrder";
import { ShippingAreaService } from "src/features/shipping_area/data/ShippingAreaService";
import { ShippingArea } from "src/features/shipping_area/domain/entities/ShippingArea";
import { getOpenShippingMethod, getShippingMethods, setOpenShippingMethod, setShippingMethods } from "../../data/ShippingMethodSlice";
import { ShippingMethod } from "../../domain/entities/ShippingMethod";
import { ShippingMethodScope } from "../../domain/entities/ShippingMethodScope";
import SelectShippingMethodList from "./SelectShippingMethodList";

const ShippingMethodDrawer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const openShippingMethod = useAppSelector(getOpenShippingMethod);
    const shippingMethods = useAppSelector(getShippingMethods);
    const newOrder: NewOrder = useAppSelector(getNewOrder);
    const { user } = useAuth();


    useEffect(() => {
        if (openShippingMethod && newOrder.shippingAddress) {
            setLoading(true);

            (async () => {
                const shippingArea: ShippingArea = await ShippingAreaService.findShippingAreaAsync(
                    {
                        province_code: newOrder.shippingAddress.province.code.toString(),
                        country_code: '34'
                    }
                );

                // Tenemos que seleccionar los shippingMethods de acuerdo al tipo de cliente
                const shippingMethods: ShippingMethod[] = [];
                // 1. Metemos los shipping methods de tipo 'both'
                shippingMethods.push(
                    ...shippingArea.shippingMethods.filter(sp => sp.scope === ShippingMethodScope.Both)
                );
                // 2. Dependiendo del tipo de cliente, metemos los sM de tipo
                //    retail o company
                const clientShippingMethodScope: ShippingMethodScope = user.type === 'company'
                    ? ShippingMethodScope.Company
                    : ShippingMethodScope.Retail;

                shippingMethods.push(
                    ...shippingArea.shippingMethods.filter(sp => sp.scope === clientShippingMethodScope)
                );

                dispatch(setShippingMethods(shippingMethods));
            })();

            setLoading(false);
        } else {
            dispatch(setShippingMethods([]));
        }
    }, [openShippingMethod])


    const handleOnClose = () => {
        dispatch(setOpenShippingMethod(false));
        dispatch(setShippingMethods([]));
    }

    return (
        <>
            <Drawer anchor={"right"} open={openShippingMethod} onClose={handleOnClose} >
                <DrawerHeader title="Elige tipo de entrega" onClickBackButton={handleOnClose} />
                {
                    loading
                        ? <CircularProgress />
                        : <SelectShippingMethodList
                            shippingMethods={shippingMethods}
                            shippingMethodSelected={newOrder.shippingMethod}
                            onClose={handleOnClose}
                        />
                }
            </Drawer>
        </>
    );
}

export default ShippingMethodDrawer;