const esJSON = {
  /* :: ADDRESS :: */
  Address: {
    Create: "Nueva dirección",
    Update: "Editar dirección",
    SaveError: "No se ha podido guardar la dirección",
    SaveSuccess: "Dirección guardada correctamente",
  },

  /* :: AUTH :: */
  Auth: {
    LoginRequiredText:
      "Para poder añadir productos a tu carrito debes de estar autenticado en la aplicación. Identifícate antes de realizar el pedido",
    LoginRequiredTitle: "Esta acción necesita autenticación",
  },

  /* :: CART :: */
  Cart: {
    Empty: "Carrito vacio",
    Name: "Carrito",
    Title: "Pedido en curso",
    GoToPayment: "Ir a pago y envío",
  },

  /* :: COMMONS :: */

  Commons: {
    Actions: "Acciones",

    Back: "Volver",

    Cancel: "Cancelar",
    ClickHere: "clic aquí",
    Footer: {
      Conditions: "Términos y condiciones de uso",
      Contact: "Contacto",
      Cookies: {
        Legend:
          "Esta página tan sólo utiliza las cookies necesarias para completar el proceso de compra. NO utilizamos cookies con fines de seguimiento ni análisis. Para más información, ver la ",
        Link: "Política de cookies",
      },
      LegalTitle: "Legal",
      Legal: "Aviso Legal",
      Privacy: "Política de privacidad",
      SocialMediaPolicy: "Política de redes sociales",
    },
    ConfirmAction: "Confirmar Acción",
    Confirm: "Confirmar",
    CreatedAt: "Creado",
    CreatedBy: "Autor",

    Delete: "Eliminar",
    Deleting: "Eliminando",
    Download: "Descargar",

    Edit: "Editar",

    Files: "Ficheros",

    Gender: {
      Title: "Género",

      female: "Mujer",
      male: "Hombre",
      none: "Sin indicar",
    },

    Login: {
      Email: "Email",
      EmailInstructions:
        "Se ha enviado un email a tu correo eléctronico con las instrucciones para recuperar tu contraseña",
      EmailRecovery:
        "Escribe tu email y te enviaremos un correo eléctronico para recuperar la contraseña",
      Errors: {
        Email: "Debes introducir un email válido",
        Password: "Debes introducir una contraseña válida",
        Terms: "Debes aceptar los téminos y condiciones",
      },
      IAccept: "Acepto",
      GoToLogin: "Volver al login",
      LoginError: "El email o la contraseña es incorrecta",
      LogOut: "Cerrar sesión",
      LostPassword: "Recuperar contraseña",
      Password: "Contraseña",
      RecoverPassword: "Recuperar contraseña",
      RecoverPassEmailSent:
        "Comprueba la bandeja de entrada de tu correo eléctronico.",
      SignIn: "Autenticarse",
      Subtitle: "Accede con tu usuario y tu contraseña",
      TermsAndConditions: "los téminos y condiciones del servicio",
      Title: "Autenticación",
      TrySignIn: "Para acceder a la plataforma",
    },

    Open: "Abrir",

    PaginationPrep: "de",
    Permissions: "Permisos",
    PerPage: "{{resource}} por página",
    Profile: "Mi datos",
    Role: {
      admin: "Admin",
      all: "Todos",
      user: "Usuario",
      Title: "Rol",
    },

    Save: "Guardar",
    Saving: "Guardando",
    Search: "Buscar",
    SeeDetails: "Ver detalles",
    SeeMore: "Ver Mas",
    Status: {
      active: "Activo",
      all: "Todos",
      inactive: "Inactivo",
      pending: "Pendiente",

      Title: "Estado",
    },

    Upload: "Subir Fichero",
    UploadBox: {
      BadType: "No puedes subir ficheros del tipo seleccionado",
      DragAndDrop: "Arrastra ficheros hasta aquí",
      DragNow: "Arrastra ficheros para empezar a subir",
      FilesToUpload: "Has subido",
      Title: "Subir Ficheros",
      Subtitle:
        "Puedes pulsar en el recuadro de abajo o arrastrar hasta aquí los ficheros que quieras subir a la plataforma",
    },
    Uploading: "Subiendo Fichero...",
  },

  /* :: CLIENT :: */
  Client: {
    BillingInfo: "Datos de facturación",
    OrdersHistory: "Historial de pedidos",
    PaymentData: "Información de pago",
    YourAddresses: "Tus direcciones",
  },

  /* :: CREDIT_CARD :: */
  CreditCard: {
    New: "Nueva tarjeta",
    Save: {
      Success: "Tarjeta creada",
      Error: "Error creando tarjeta",
    },
  },

  /* :: FAMILIES :: */

  Families: {
    Featured: "Categorías Destacadas",
    SeeAll: "Ver catálogo completo",
    SeeAllProducts: "Ver todos los productos",
    Title: "Categorías",
  },

  /* :: MAIN :: */
  Main: {
    HomeNotifications: "Anuncios",
    Title: "Distributio",
  },

  /* :: ORDER :: */
  Order: {
    Buy: "Compra ",
    CompleteAllFields: "Completa todos los campos",
    Date: "Fecha de pedido",
    Edit: "Editar Pedido",
    EditText:
      "Tienes actualmente un pedido sin procesar en tu carrito. Si editas el pedido actual perderás el actual. ¿Quieres continuar editando este pedido?",
    Estimated: "Fecha estimada de entrega",
    Finish: "Finalizar el pedido",
    PaymentMethod: "Método de pago",
    PayAndSend: "Pago y envío",
    ShippingAddress: "Dirección de envio",
    ShippingCost: "Gastos de envío",
    ShippingDate: "Fecha de entrega",
    ShippingMethod: "Método de envío",
    Status: {
      canceled: "Cancelado",
      in_review: "En revisión",
      confirmed: "Confirmado",
      in_progress: "En progreso",
      prepared: "Preparado",
      sent: "Enviado",
      delivered: "Entregado",
    },
    Title: "Pedido",
    Total: "Total",
    YourOrders: "Tus pedidos",
  },

  /* :: PRICE :: */
  Price: {
    PackPrice: "{{amount}} unidades",
    Price: "Precio",
    SearchNotResult: "Ningún producto encontrado para la búsqueda introducida.",
    StartTypingToSearch: "Empieza a escribir para buscar productos...",
  },

  /* :: PRICES :: */

  Prices: {
    AddToCart: "Añadir al carrito",
    Favourite: "Favorito",
    InStock: "En stock",
    NoStock: "Sin stock",
    LastUnits: "Últimas unidades",
    PriceBlock: "Precio bloq.",
    SeeAllProducts: "Ver todos los productos",
    Title: "Productos",
  },

  Detail: {
    AmountModeUnits: "Unidades",
    AmountModeInBulk: "Peso",
    AmountModeUnitsHint: "Unidades",
    AmountsInCart: "kgs en el carrito",
    AmountInCart: "kg en el carrito",
    UnitsInCart: "unidades en el carrito",
    UnitInCart: "unidad en el carrito",
    AmountModeInBulkHint: "Cantidad (kg)",
    AddToCartSuccess: "Producto añadido al carrito",
    RemoveFromCartSuccess: "Producto eliminado del carrito",
  },

  /* SHOP */
  Shop: {
    ShopClosed: "Pedido Bloqueados",
  },

  /* USERS */

  Users: {
    AddNew: "Nuevo usuario",
    Attributes: {
      Id: "Identificador",
      CreatedAt: "Fecha de alta",
      Email: "Email",
      Name: "Nombre",
      Password: "Contraseña",
      Role: "Rol",
      Status: "Estado",
      Phone: "Teléfono",
    },
    Edit: "Editar usuario",
    Errors: {
      Email: "Debe introducir un email válido",
      Manage: "No se ha podido guardar el usuario",
      Name: "Debe introducir un nombre válido",
      Password: "Escribe una contraseña",
      PasswordLength: "La contraseña debe tener al menos 6 caracteres",
      Phone: "Debe introducir un teléfono válido",
      Surname: "Debe introducir unos apellidos válidos",
      Status: "Debe introducir un estado válido",
      Role: "Debe introducir un rol válido",
      EmailAlreadyUse: "El email ya está en uso",
    },
    ManageSuccess: "Usuario guardado correctamente",
    NoResults: "No hay resultados",
    Title: "Usuarios",
    Search: "Buscar por nombre, email...",
    Summary:
      "En esta sección podrás consultar, dar de alta o dar de baja usuarios",
  },
};

export default esJSON;
