import { HomeSection } from '../domain/entities/HomeSection';
import { getRequest } from 'src/config/http';

export abstract class HomeSectionService {
    public static async findHomeSectionByIdAsync(homeSectionId: string): Promise<HomeSection> {
        return HomeSection.fromJson(await getRequest(`/home_sections/${homeSectionId}`));
    }

    public static async searchHomeSectionsAsync(): Promise<HomeSection[]> {
        return HomeSection.fromJsonArray(await getRequest('/home_sections'));
    }
}