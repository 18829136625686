export class ProductAmountMode {
    static readonly InBulk = new ProductAmountMode('in_bulk');

    static readonly Units = new ProductAmountMode('units');

    static readonly Both = new ProductAmountMode('both');

    constructor(public readonly value: string) { }

    static getValues(): ProductAmountMode[] {
        return [this.InBulk, this.Units, this.Both];
    }
    
    static fromString(string: string): ProductAmountMode {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}