import { NotArrayError } from "src/app/errors/NotArrayError";
import { PaymentMethod } from "../../../payment_method/domain/entities/PaymentMethod";
import { PartnerShippingMethod } from "./PartnerShippingMethod";

export class PartnerDefaults {
  constructor(
    readonly allergens: boolean,
    readonly shippingMethod: PartnerShippingMethod,
    readonly paymentMethod: PaymentMethod,
    readonly taxId: string,

    readonly unregisteredFeeId?: string
  ) {}

  public static fromJson(json: any): PartnerDefaults {
    return new PartnerDefaults(
      json["allergens"],
      PartnerShippingMethod.fromJson(json["shippingMethod"]),
      PaymentMethod.fromJson(json["paymentMethod"]),
      json["taxId"],

      json["unregisteredFeeId"]
    );
  }

  public static fromJsonArray(jsonArray: any): PartnerDefaults[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
