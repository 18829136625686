export class FamiliyContentType {
    static readonly Families = new FamiliyContentType('families');

    static readonly Products = new FamiliyContentType('products');


    constructor(public readonly value: string) { }

    static getValues(): FamiliyContentType[] {
        return [this.Families, this.Products];
    }

    static fromString(string: string): FamiliyContentType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}