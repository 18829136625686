const ButtonsWrapper = {
  padding: "3px",
  minWidth: "32px",
  display: "inline-flex",
  webkitBoxOrient: "vertical",
  webKitBoxDirection: "normal",
  webkitFlexDirection: "column",
  flexDirection: "column",
  webkitBoxAlign: "center",
  webkitAlignItems: "center",
  alignItems: "center",
  textAlign: "center",
};

const ListItemWrapper = {
  paddingLeft: "0px",
  paddingRight: "0px",
  alignItems: "center",
  justifyItems: "center",
  background: "white",
  borderBottom: "1px solid #f5f5f5",
};

const PriceLabelWrapper = {
  display: "flex",
  webkitBoxOrient: "vertical",
  webKitBoxDirection: "normal",
  webkitFlexDirection: "column",
  flexDirection: "column",
  webkitBoxAlign: "end",
  webkitAlignItems: "flex-end",
  alignItems: "flex-end",
  overflow: "hidden",
  webkitFlexShrink: 0,
  flexShrink: 0,
  position: "relative",
};

export { ButtonsWrapper, ListItemWrapper, PriceLabelWrapper };
