import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Address } from "../domain/entities/Address";
import { OpenManageAddressDto } from "./AddressDto";
import { searchAddresssByClient } from "./AddressThunk";
import {
  createAddressThunk,
  deleteAddressThunk,
  findAddressByIdThunk,
  updateAddressThunk,
} from "./AddressThunk";

interface AddressState {
  addressAction: Action;
  addresses: Address[];
  openManageAddress: boolean;
  openSelectAddress: boolean;
  status: StateStatus;

  addressSelected?: Address;
}

export const initialState: AddressState = {
  addressAction: "none",
  addresses: [],
  addressSelected: undefined,
  openManageAddress: false,
  openSelectAddress: false,
  status: "ready",
};

const addressSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    changeAddressAction: (
      state: AddressState,
      action: PayloadAction<Action>
    ) => {
      state.addressAction = action.payload;
    },

    selectAddress: (
      state: AddressState,
      action: PayloadAction<Address | undefined>
    ) => {
      state.addressSelected = action.payload;
    },

    setOpenManageAddress: (
      state: AddressState,
      action: PayloadAction<OpenManageAddressDto>
    ) => {
      state.addressSelected = action.payload.address;
      state.openManageAddress = action.payload.open;
    },

    setOpenSelectAddress: (
      state: AddressState,
      action: PayloadAction<boolean>
    ) => {
      state.openSelectAddress = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AddressState>) => {
    const updateAddress = (
      state: AddressState,
      action: PayloadAction<Address>
    ) => {
      state.addresses = state.addresses.map((address) =>
        address.id === action.payload.id ? action.payload : address
      );
      if (state.addressSelected?.id == action.payload.id) {
        state.addressSelected = action.payload;
      }
      state.status = "ready";
    };

    /*
            Create Address
        */
    builder
      .addCase(createAddressThunk.pending, (state: AddressState) => {
        state.status = "loading";
      })
      .addCase(
        createAddressThunk.fulfilled,
        (state: AddressState, action: PayloadAction<Address>) => {
          state.addresses.unshift(action.payload);
          state.addressSelected = undefined;
          state.status = "ready";
        }
      )
      .addCase(createAddressThunk.rejected, (state: AddressState) => {
        state.status = "error";
      });

    /*
            Delete Address
        */
    builder
      .addCase(deleteAddressThunk.pending, (state: AddressState) => {
        state.status = "loading";
      })
      .addCase(
        deleteAddressThunk.fulfilled,
        (state: AddressState, action: PayloadAction<Address>) => {
          state.status = "ready";
          state.addresses = state.addresses.filter(
            (address) => address.id !== action.payload.id
          );
          if (state.addressSelected?.id === action.payload.id) {
            state.addressSelected = undefined;
          }
          state.addressAction = "none";
        }
      )
      .addCase(deleteAddressThunk.rejected, (state: AddressState) => {
        state.status = "error";
      });

    /*
            Find Address By Id
        */
    builder
      .addCase(findAddressByIdThunk.pending, (state: AddressState) => {
        state.status = "loading";
      })
      .addCase(
        findAddressByIdThunk.fulfilled,
        (state: AddressState, action: PayloadAction<Address>) => {
          state.addressSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findAddressByIdThunk.rejected, (state: AddressState) => {
        state.status = "error";
      });

    /*
            Find Client Addresses
       */
    builder
      .addCase(searchAddresssByClient.pending, (state: AddressState) => {
        state.status = "loading";
      })
      .addCase(
        searchAddresssByClient.fulfilled,
        (state: AddressState, action: PayloadAction<Address[]>) => {
          state.addresses = action.payload;
          state.status = "ready";
        }
      )
      .addCase(searchAddresssByClient.rejected, (state: AddressState) => {
        state.status = "error";
      });

    /*
            Update Address
        */
    builder
      .addCase(updateAddressThunk.pending, (state: AddressState) => {
        state.status = "loading";
      })
      .addCase(
        updateAddressThunk.fulfilled,
        (state: AddressState, action: PayloadAction<Address>) => {
          updateAddress(state, action);
        }
      )
      .addCase(updateAddressThunk.rejected, (state: AddressState) => {
        state.status = "error";
      });
  },
});

const {
  changeAddressAction,
  selectAddress,
  setOpenManageAddress,
  setOpenSelectAddress,
} = addressSlice.actions;

const getAddressAction = (state: RootState) => state.address.addressAction;
const getAddresses = (state: RootState) => state.address.addresses;
const getAddressSelected = (state: RootState) => state.address.addressSelected;
const getOpenManageAddress = (state: RootState) =>
  state.address.openManageAddress;
const getOpenSelectAddress = (state: RootState) =>
  state.address.openSelectAddress;
const getStatus = (state: RootState) => state.address.status;

export {
  changeAddressAction,
  selectAddress,
  setOpenManageAddress,
  setOpenSelectAddress,
  getAddressAction,
  getAddresses,
  getAddressSelected,
  getOpenManageAddress,
  getOpenSelectAddress,
  getStatus,
};

export default addressSlice.reducer;
