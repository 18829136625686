import { Divider, ListItem, ListItemIcon, ListItemText, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { OrderDetaisDivider } from "./styles";

interface OrderDetailInfoItemProps {
    readonly icon: OverridableComponent<SvgIconTypeMap>,
    readonly label: string,
    readonly value: string,
}

const OrderDetailInfoItem: FC<OrderDetailInfoItemProps> = ({ icon: Icon, label, value }) => {

    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={label} secondary={value} />
            </ListItem>
            <Divider />
        </>
    );

}

export default OrderDetailInfoItem;