import { Box, Button, Drawer, useTheme } from '@mui/material';
import CartDetails from '../cart_details/index';
import { FC } from "react";
import { CartContinueButton, CartFooter } from './styles';
import { useAppSelector } from "src/config/hooks";
import { getNewOrder, getOpenCart, setOpenCart, setOpenFinishOrder } from "../../data/OrderSlice";
import DrawerHeader from '../../../../app/components/DrawerHeader/index';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/config/hooks';
import { ClientType } from 'src/features/client/domain/entities/ClientType';
import useAuth from 'src/app/hooks/useAuth';

const CartDrawer: FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isCompany = useAuth().user?.type === ClientType.Company.value;
    const { t } = useTranslation();
    const { details } = useAppSelector(getNewOrder);
    const openCart = useAppSelector(getOpenCart);

    const handleOnCloseCart = () => {
        dispatch(setOpenCart(false));
    }

    const handleOnPaymentButtonClicked = () => {
        dispatch(setOpenFinishOrder(true));
    }

    const ContinueButton = () => {
        if (details.length === 0) {
            return <></>;
        }

        return (
            <Box
                sx={CartFooter}
                component="span">
                <Button
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    disableElevation
                    onClick={handleOnPaymentButtonClicked}

                >
                    <Box component={'span'}
                        sx={CartContinueButton(theme.colors.primary.light)}>
                        {details.length}
                    </Box>
                    <Box
                        component={'span'}
                        sx={{ flex: 1 }
                        }>
                        {t('Cart.GoToPayment')}
                    </Box>
                    <Box
                        component={'span'}
                        sx={{ flex: 0.1 }}>
                        {details.reduce((a, b) => a + (b.getPrice(isCompany !== true) || 0), 0).toFixed(2)}€
                        </Box>
                </Button>
            </Box>
        );
    }

    return (
        <Drawer anchor={"right"} open={openCart} onClose={handleOnCloseCart}>
            <DrawerHeader title={t('Cart.Name')} onClickBackButton={handleOnCloseCart} />
            <CartDetails details={details} />
            <ContinueButton />
        </Drawer>
    );
}

export default CartDrawer;