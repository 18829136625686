export class ProductOption {
  constructor(
    public description: string,
    public name: string,
    public value: number,
    public quantity?: number 
  ) {}

  static fromJson(json: Record<string, any>): ProductOption {
    return new ProductOption(
      json.description,
      json.name,
      json.value,
      json.quantity
    );
  }

  toJson(): Record<string, any> {
    return {
      description: this.description,
      name: this.name,
      value: this.value,
      quantity: this.quantity
    };
  }
}