import { NotArrayError } from "src/app/errors/NotArrayError";

export class PaymentMethodAttribute {
    constructor(
        readonly key: string,
        readonly value: string
    ) { }

    public static fromJson(json: any): PaymentMethodAttribute {
        return new PaymentMethodAttribute(
            json['key'],
            json['value'],
        );
    }

    public static fromJsonArray(jsonArray: any): PaymentMethodAttribute[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}