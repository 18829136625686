import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Family } from "../domain/entities/Family";
import { findFamilyByIdThunk, searchFamiliesByClientIdThunk, searchFamiliesThunk } from './FamilyThunk';

interface FamilyState {
    families: Family[];
    familySelected?: Family;
    status: StateStatus;
}

export const initialState: FamilyState = {
    families: [],
    familySelected: undefined,
    status: 'ready',
}

const familySlice = createSlice({
    name: 'families',
    initialState,
    reducers: {
        selectFamily: (state: FamilyState, action: PayloadAction<Family | undefined>) => {
            state.familySelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<FamilyState>) => {
        /*
            Find Family By Id
        */
        builder.addCase(findFamilyByIdThunk.pending, (state: FamilyState) => {
            state.status = 'loading';
        }).addCase(findFamilyByIdThunk.fulfilled, (state: FamilyState, action: PayloadAction<Family>) => {
            state.familySelected = action.payload;
            state.status = 'ready';
        }).addCase(findFamilyByIdThunk.rejected, (state: FamilyState) => {
            state.status = 'error';
        });

        /*
             Search Families
        */
        builder.addCase(searchFamiliesThunk.pending, (state: FamilyState) => {
            state.status = 'loading';
        }).addCase(searchFamiliesThunk.fulfilled, (state: FamilyState, action: PayloadAction<Family[]>) => {
            state.families = action.payload;
            state.status = 'ready';
        }).addCase(searchFamiliesThunk.rejected, (state: FamilyState) => {
            state.status = 'error';
        });

        /*
             Search Client Families
        */
        builder.addCase(searchFamiliesByClientIdThunk.pending, (state: FamilyState) => {
            state.status = 'loading';
        }).addCase(searchFamiliesByClientIdThunk.fulfilled, (state: FamilyState, action: PayloadAction<Family[]>) => {
            state.families = action.payload;
            state.status = 'ready';
        }).addCase(searchFamiliesByClientIdThunk.rejected, (state: FamilyState) => {
            state.status = 'error';
        });
    }
});

const { selectFamily } = familySlice.actions;

const getFamilies = (state: RootState) => state.family.families;
const getFamilySelected = (state: RootState) => state.family.familySelected;
const getStatus = (state: RootState) => state.family.status;

export {
    selectFamily,

    getFamilies,
    getFamilySelected,
    getStatus
}

export default familySlice.reducer;