import { Box, List, Typography } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import { OrderDetail } from "../../domain/entities/OrderDetail";
import DetailInOrderCell from "./DetailInOrderCell";
import { DetailsInOrderContainer } from "./styles";

interface DetailsInOrderListProps {
    readonly details: OrderDetail[]
}

const DetailsInOrderList: FC<DetailsInOrderListProps> = ({ details }) => {
    if (!details) {
        return <Typography>{t('Cart.Empty')}</Typography>;
    }


    return (
        <Box >
            <List>
                {
                    details.map(detail => <DetailInOrderCell key={detail.price.id} detail={detail} />)
                }
            </List>

        </Box>
    );
}

export default DetailsInOrderList;