import { NotArrayError } from "src/app/errors/NotArrayError";

export class ProductSpecialLabel {
  constructor(readonly color: string, readonly value: string) {}

  public static fromJson(json: any): ProductSpecialLabel {
    return new ProductSpecialLabel(json["color"], json["value"]);
  }

  public toJson(): any {
    return {
      color: this.color,
      value: this.value,
    };
  }

  public static fromJsonArray(jsonArray: any): ProductSpecialLabel[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
