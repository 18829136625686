

export const ActionBarStyles = {
    marginTop: "20px",
}

export const CardElementStyles = {
    marginTop: "10px",
    borderRadius: "4px",
    borderColor: "#223354",
    borderWidth: "0.1px",

    padding: "10px"
}
export const CardInputLabelStyles = {
    paddingBottom: "4px"
}

export const ContainerStyles = {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
}

export const NameInputStyles = {
    padding: "0px",
    input: {
        padding: "0px"
    }
}
export const InlineElementsStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row"
}
export const LogoStyles: React.CSSProperties = {
    height: "3em",
    float: "right",
    marginTop: "1rem"
}
export const ErrorMessageStyles = {
    paddingTop: "1rem",
    color: "red",
    fontWeight: "bold",
    fontSize: "18px"
}

export const ElementOptionsStyles = {
    style: {
        base: {
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            }
        },
        invalid: {
            color: '#9e2146',
        },
    },
}