import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CreditCard } from "src/features/client/domain/entities/CreditCard";
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { PaymentMethod } from "../domain/entities/PaymentMethod";
import { getCreditCardsByClientThunk, searchPaymentMethodsThunk } from './PaymentMethodThunk';

interface PaymentMethodState {
    creditCards: CreditCard[],
    paymentMethods: PaymentMethod[],
    paymentMethodSelected?: PaymentMethod,
    openManagePaymentMethod: boolean,
    openSelectPaymentMethod: boolean,
    openSelectCreditCard: boolean,
    status: StateStatus;
}

export const initialState: PaymentMethodState = {
    creditCards: [],
    paymentMethods: [],
    status: 'ready',
    paymentMethodSelected: undefined,
    openManagePaymentMethod: false,
    openSelectPaymentMethod: false,
    openSelectCreditCard: false
}

const paymentMethodSlice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {
        selectPaymentMethod: (
            state: PaymentMethodState,
            action: PayloadAction<PaymentMethod | undefined>
        ) => {
            state.paymentMethodSelected = action.payload;
        },
        setOpenSelectPaymentMethod: (
            state: PaymentMethodState,
            action: PayloadAction<boolean>
        ) => {
            state.openSelectPaymentMethod = action.payload;
        },
        setOpenSelectCreditCard: (
            state: PaymentMethodState,
            action: PayloadAction<boolean>
        ) => {
            state.openSelectCreditCard = action.payload;
        },
        setOpenManagePaymentMethod: (
            state: PaymentMethodState,
            action: PayloadAction<boolean>
        ) => {
            state.openManagePaymentMethod = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<PaymentMethodState>) => {
        /*
             Search PaymentMethods
        */
        builder.addCase(searchPaymentMethodsThunk.pending, (state: PaymentMethodState) => {
            state.status = 'loading';
        }).addCase(searchPaymentMethodsThunk.fulfilled, (state: PaymentMethodState, action: PayloadAction<PaymentMethod[]>) => {
            state.paymentMethods = action.payload;
            state.status = 'ready';
        }).addCase(searchPaymentMethodsThunk.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
        });

        builder.addCase(getCreditCardsByClientThunk.pending, (state: PaymentMethodState) => {
            state.status = 'loading';
        }).addCase(getCreditCardsByClientThunk.fulfilled, (state: PaymentMethodState, action: PayloadAction<CreditCard[]>) => {
            state.creditCards = action.payload;
            state.status = 'ready';
        }).addCase(getCreditCardsByClientThunk.rejected, (state: PaymentMethodState) => {
            state.status = 'error';
        });
    }
});

const {
    selectPaymentMethod,
    setOpenSelectPaymentMethod,
    setOpenSelectCreditCard,
    setOpenManagePaymentMethod,
} = paymentMethodSlice.actions;


const getCreditCards = (state: RootState) => state.paymentMethod.creditCards;
const getPaymentMethods = (state: RootState) => state.paymentMethod.paymentMethods;
const getStatus = (state: RootState) => state.paymentMethod.status;
const getPaymentMethodSelected = (state: RootState) => state.paymentMethod.paymentMethodSelected;
const getOpenSelectPaymentMethod = (state: RootState) => state.paymentMethod.openSelectPaymentMethod;
const getOpenSelectCreditCard = (state: RootState) => state.paymentMethod.openSelectCreditCard;
const getOpenManagePaymentMethod = (state: RootState) => state.paymentMethod.openManagePaymentMethod;

export {
    getCreditCards,
    getPaymentMethods,
    getStatus,
    getPaymentMethodSelected,
    getOpenSelectPaymentMethod,
    getOpenSelectCreditCard,
    getOpenManagePaymentMethod,

    selectPaymentMethod,
    setOpenSelectPaymentMethod,
    setOpenSelectCreditCard,
    setOpenManagePaymentMethod
}

export default paymentMethodSlice.reducer;