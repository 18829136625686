export class OrderFrom {
    static readonly AppAndroid = new OrderFrom('app_android');

    static readonly AppIos = new OrderFrom('app_ios');

    static readonly Api = new OrderFrom('api');

    static readonly SalesAndroid = new OrderFrom('sales_android');

    static readonly SalesIos = new OrderFrom('sales_ios');

    static readonly Panel = new OrderFrom('panel');

    static readonly Csv = new OrderFrom('csv');

    static readonly Web = new OrderFrom('web');

    static readonly Woocommerce = new OrderFrom('woo_commerce');

    constructor(public readonly value: string) { }

    static getValues(): OrderFrom[] {
        return [this.AppAndroid, this.AppIos, this.Api, this.SalesAndroid, this.SalesIos, this.Panel, this.Csv, this.Web, this.Woocommerce];
    }

    static fromString(string: string): OrderFrom {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}