import { NotArrayError } from "src/app/errors/NotArrayError";
import { Day } from "src/features/commons/Day";
import { ShippingMethodScope } from './ShippingMethodScope';
import { ShippingMethodType } from './ShippingMethodType';

export class ShippingMethod {
    constructor(
        readonly id: string,
        readonly deliveryDays: Day[],
        readonly erpId: string,
        readonly limitHour: number,
        readonly minTotalPrice: number,
        readonly name: string,
        readonly preparationDays: number,
        readonly scope: ShippingMethodScope,
        readonly type: ShippingMethodType,
        readonly value: number,

        readonly freeFrom?: number,
        readonly fromAgency?: boolean,

    ) { }

    public static fromJson(json: any): ShippingMethod {
        return new ShippingMethod(
            json['id'],
            json['deliveryDays'] instanceof Array ? json['deliveryDays'].map(dayValue => Day.fromString(dayValue)) : [],
            json['erpId'],
            json['limitHour'],
            json['minTotalPrice'],
            json['name'],
            json['preparationDays'],
            ShippingMethodScope.fromString(json['scope']),
            ShippingMethodType.fromString(json['type']),
            json['value'],
            json['freeFrom'],
            json['fromAgency']
        );
    }

    public static fromJsonArray(jsonArray: any): ShippingMethod[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

    getPriceDescription(): string {
        let description = "";
        if (this.value > 0) {
            description = `${this.value}€`
        }
        if (this.freeFrom) {
            return description += ` (Gratis desde ${this.freeFrom}€)`
        }
    }
}