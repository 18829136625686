import { FC } from "react";
import { useAppDispatch } from '../../../../config/hooks';
import { modifyDetailInNewOrder } from "../../data/OrderSlice";
import CartDetailItem from './CartDetailItem';
import { OrderDetail } from '../../domain/entities/OrderDetail';
import { Box, List, Stack, Typography, useTheme } from '@mui/material';
import { setOpenEditDetail } from 'src/features/price/data/PriceSlice';
import { useTranslation } from 'react-i18next';
import EmptyCartIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone';

interface CartDetailsProps {
    readonly details: OrderDetail[];
}

const CartDetails: FC<CartDetailsProps> = ({ details }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const handleOnClickDetail = (detail: OrderDetail): void => {
        dispatch(setOpenEditDetail({ open: true, price: detail.price }));
    }

    const handleChangeDetailUnits = (action: 'add' | 'sustract', detail: OrderDetail): void => {
        detail.quantity = action === 'add' ? detail.quantity + 1 : detail.quantity - 1;
        dispatch(modifyDetailInNewOrder(detail));
    }

    if (details.length === 0) {
        return <Stack
            sx={{
                padding: 4,
                minWidth: "calc(min(100vw, 375px))",
                maxWidth: "calc(min(100vw, 375px))",
            }}
            direction={'column'}
            alignItems={'center'}
        >
            <Typography variant={'h3'} color={theme.colors.primary.main}>{t('Cart.Empty')}</Typography>
            <EmptyCartIcon sx={{ width: '100px', height: '100px', margin: '10px', color: theme.colors.primary.main }} />
        </Stack>
    }

    return (
        <Box sx={{
            minWidth: "calc(min(100vw, 375px))",
            maxWidth: "calc(min(100vw, 375px))",
        }}>
            <List>
                {
                    details.map(detail =>
                        <CartDetailItem
                            key={detail.price.id}
                            detail={detail}
                            changeUnits={(action: 'add' | 'sustract') => handleChangeDetailUnits(action, detail)}
                            onClickDetail={() => handleOnClickDetail(detail)}
                        />)
                }
            </List>
        </Box>
    )
}


export default CartDetails;
