import { NotArrayError } from "src/app/errors/NotArrayError";
import { ShippingMethod } from "src/features/shipping_method/domain/entities/ShippingMethod";
import { DealerInfo } from "./DealerInfo";
import { ShippingAreaDefaults } from "./ShippingAreaDefaults";
import { Location } from "src/features/address/domain/entities/Location";

export class ShippingArea {
    constructor(
        readonly id: string,
        readonly companyCanEditAddresses: boolean,
        readonly country: Location,
        readonly partnerId: string,
        readonly provinces: Location[],
        readonly shippingMethods: ShippingMethod[],
        dealerInfo: DealerInfo,
        defaultsCompany: ShippingAreaDefaults,
        defaultsRetail: ShippingAreaDefaults,
    
    ) { }

    public static fromJson(json: any): ShippingArea {
        return new ShippingArea(
            json['id'],
            json['companyCanEditAddresses'],
            Location.fromJson(json['country']),
            json['partnerId'],
            json['provinces'] ? Location.fromJsonArray(json['provinces']) : [],
            json['shippingMethods'] ? ShippingMethod.fromJsonArray(json['shippingMethods']) : [],
            
            json['dealerInfo'] ? DealerInfo.fromJson(json['dealerInfo']) : undefined,
            json['defaultsCompany'] ? ShippingAreaDefaults.fromJson(json['defaultsCompany']) : undefined,
            json['defaultsRetail'] ? ShippingAreaDefaults.fromJson(json['defaultsRetail']) : undefined,

        );
    }

    public static fromJsonArray(jsonArray: any): ShippingArea[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}