import { Dialog, Card, Grid, Box, Button, useMediaQuery, useTheme, ToggleButtonGroup, ToggleButton, Divider, Slide } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import DialogTitleWithIcon from "src/app/components/DialogTitleWithIcon";
import { useAppSelector } from "src/config/hooks";

import CloseIcon from '@mui/icons-material/Close';
import useAuth from "src/app/hooks/useAuth";
import { ProductSalesFormat } from "src/features/product/domain/entities/ProductSalesFormat";
import { getNewOrder, modifyDetailInNewOrder } from "../../data/OrderSlice";
import { OrderDetail } from "../../domain/entities/OrderDetail";
import { ProductAmountMode } from "src/features/product/domain/entities/ProductAmountMode";
import { NoteService } from "src/features/note/data/NoteService";
import { useSnackbar } from "notistack";
import { QuantitySelector } from "./QuantitySelector";
import { DetailNotes } from "./DetailNotes";
import { t } from "i18next";
import { useAppDispatch } from '../../../../config/hooks';
import { getOpenEditOrderDetail, setOpenEditDetail } from 'src/features/price/data/PriceSlice';
import FeatureList from "src/features/feature/ui/feature-item";
import { Feature } from "src/features/feature/domain/Feature";
import { Note } from "src/features/note/domain/entities/Note";

interface DetailFormDialogProps { }

type FeaturesSelectedContextType = {
    featuresSelected: Feature[],
    selectFeatures: (features: Feature | Feature[]) => void;
  }

export const FeaturesSelectedContext = createContext({} as FeaturesSelectedContextType);

export default function DetailFormDialog({ }: DetailFormDialogProps) {
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const newOrder = useAppSelector(getNewOrder);
    const price = useAppSelector(getOpenEditOrderDetail)?.price;
    const open = useAppSelector(getOpenEditOrderDetail)?.open;
    const [clientNote, setClientNote] = useState<string>();
    const [featuresSelected, setFeaturesSelected] = useState<Feature[]>([]);
    const [detail, setDetail] = useState<OrderDetail>();

    useEffect(() => {
        if (open) {
            //buscamos un detail para el price en el newOrder, si no está creamos un detail vacío
            const detail: OrderDetail | undefined = newOrder['details']?.find((detail) => detail.price.product.id === price.product.id);
            if (detail) {
                setDetail(detail);
                setClientNote(detail.clientNote);
                setFeaturesSelected(detail.featuresSelected ?? []);
            } else {
                setDetail(new OrderDetail(
                    'new_detail',
                    price.product.amountMode !== ProductAmountMode.InBulk ? ProductAmountMode.Units : ProductAmountMode.InBulk,
                    1,
                    price,
                    1,
                    undefined, // ClientNote
                    undefined, // CommercialNote
                    featuresSelected,
                ));
                NoteService.findNoteAsync({ clientId: user.id, productId: price.product.id }).then(result => result instanceof Note ? setClientNote(result.clientText) : '').catch(console.error);
                setFeaturesSelected([]);
            }
        };
    }, [open]);

    const productInCart = () => {
        return newOrder.details?.find((detail) => detail.price.product.id === price.product.id)
    };

    const onClose = () => {
        setDetail(undefined);
        setClientNote(undefined)
        dispatch(setOpenEditDetail({ open: false }));
    };

    const selectFeatures = (features: Feature | Feature[]): void => {
        if (features instanceof Array) {
          if (features.length > 0) {
            const updatedFeaturesSelected: Feature[] = featuresSelected.filter(f => features[0].productId !== f.productId);
            updatedFeaturesSelected.concat(features);
            setFeaturesSelected(updatedFeaturesSelected);
          }
        } else {
          const updatedFeaturesSelected: Feature[] = featuresSelected.filter(f => f.productId !== features.productId).filter(f => f.group !== features.group);
          updatedFeaturesSelected.push(features);
          setFeaturesSelected(updatedFeaturesSelected);
        }
      }

    const handleDeleteClicked = () => {
        const updatedDetail: OrderDetail = detail.getClone();
        updatedDetail.quantity = 0;
        setFeaturesSelected(featuresSelected.filter(feature => updatedDetail.price.product.id !== feature.productId));
        dispatch(modifyDetailInNewOrder(updatedDetail));
        enqueueSnackbar(t('Detail.RemoveFromCartSuccess'), {
            variant: 'error',
            preventDuplicate: false,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            TransitionComponent: Slide
        });
        onClose()
    }

    const handleAddToCartClicked = () => {
        const updatedDetail: OrderDetail = detail.getClone();
        updatedDetail.clientNote = clientNote;
        if (featuresSelected) {
            updatedDetail.featuresSelected = featuresSelected.filter(feature => feature.productId === updatedDetail.price.product.id);
        }        
        dispatch(modifyDetailInNewOrder(updatedDetail));
        enqueueSnackbar(t('Detail.AddToCartSuccess'), {
            variant: 'success',
            preventDuplicate: false,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            TransitionComponent: Slide
        });
        onClose();
    }

    const handleQuantityChanged =
        (value: string) => {
            setDetail((detail: OrderDetail) => {
                const updatedDetail: OrderDetail = detail.getClone();

                if (isNaN(+value)) {
                    return detail;
                }

                if (+value < 0) {
                    updatedDetail.quantity = 0;
                } else if (updatedDetail.amountModeSelected === ProductAmountMode.Units) {
                    updatedDetail.quantity = Math.round(+value);
                } else {
                    updatedDetail.quantity = +value;
                }

                return updatedDetail;
            });
        };

    const handleNotesChanged =
        (value: string) => {
            setClientNote(value)
        };
    const handleSelectionTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: ProductAmountMode
    ) => {

        setDetail((detail: OrderDetail) => {
            const updatedDetail: OrderDetail = detail.getClone();
            if (newValue == ProductAmountMode.Units) {
                updatedDetail.quantity = Math.round(updatedDetail.quantity);
            }
            updatedDetail.amountModeSelected = newValue;
            return updatedDetail;
        });
    };

    if (!detail) {
        return <></>;
    }

    const ActionButtons = () => (
        <Box display="flex" justifyContent="center">
            <Box
                sx={{
                    display: { xs: 'block', sm: 'flex' },
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: 2,
                    py: 2,
                    minWidth: "400px"
                }}
            >

                {
                    productInCart() ?
                        <Box px={2}>
                            <Button
                                color="error"
                                fullWidth={mobile}
                                variant="outlined"
                                size="large"
                                onClick={handleDeleteClicked}
                            >
                                Borrar
                            </Button>
                        </Box> : ""
                }
                <Button
                    fullWidth={mobile}
                    variant="contained"
                    size="large"
                    onClick={handleAddToCartClicked}
                >
                    {t('Prices.AddToCart')}
                </Button>
            </Box>
        </Box >
    )

    const QuantityModeToggle = () => (
        <Box px={3} pt={0} sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <ToggleButtonGroup
                color="primary"
                value={detail.amountModeSelected}
                exclusive
                onChange={handleSelectionTypeChange}
                aria-label="Eligiendo"
            >
                <ToggleButton sx={{ width: "125px" }} value={ProductAmountMode.Units}>{t('Detail.AmountModeUnits')}</ToggleButton>
                <ToggleButton sx={{ width: "125px" }} value={ProductAmountMode.InBulk}>{t('Detail.AmountModeInBulk')}</ToggleButton>

            </ToggleButtonGroup>
        </Box>
    )

    return (<Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth='xs'
    >
        <DialogTitleWithIcon
            title={price.product.name}
            icon={CloseIcon}
            onClick={onClose}
        />
        <Card sx={{ overflow: "scroll" }}>
            <Grid container spacing={1} padding={2}>
                <Grid item xs={12}>
                    {price.product.salesFormat == ProductSalesFormat.Weight ?
                        <QuantityModeToggle /> : ""
                    }
                    <QuantitySelector quantity={detail.quantity} amountModeSelected={detail.amountModeSelected} handleQuantityChanged={handleQuantityChanged} />
                    <FeaturesSelectedContext.Provider value={{featuresSelected, selectFeatures}}>
                        <FeatureList productId={price.product.id} />
                    </FeaturesSelectedContext.Provider>
                    <DetailNotes clientNote={clientNote} handleNotesChanged={handleNotesChanged} />
                </Grid>
            </Grid>

            <Divider />
            <ActionButtons />
        </Card>
    </Dialog >
    )
}


