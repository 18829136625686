import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Order } from "../domain/entities/Order";
import {
  createOrderThunk,
  findOrderByIdThunk,
  searchOrdersThunk,
  updateOrderThunk,
} from "./OrderThunk";
import { OpenOrderDetailsDto, PaymentInfoDto } from "./OrderDto";
import { Address } from "src/features/address/domain/entities/Address";
import { OrderDetail } from "../domain/entities/OrderDetail";
import { OrderDiscount } from "../domain/entities/OrderDiscount";
import { PaymentMethod } from "../../payment_method/domain/entities/PaymentMethod";
import { ShippingMethod } from "../../shipping_method/domain/entities/ShippingMethod";
import { SearchResponse } from "src/features/commons/Types";
import { NewOrder } from "../domain/entities/NewOrder";
import { CreditCard } from "src/features/client/domain/entities/CreditCard";

interface OrderState {
  orders: Order[];
  newOrder: NewOrder;
  openCart: boolean;
  openFinishOrder: boolean;
  openDeliveryDate: boolean;
  openOrderDetails: boolean;
  openOrderHistory: boolean;
  status: StateStatus;

  orderSelected?: Order;
}

export const initialState: OrderState = {
  orders: [],
  newOrder: new NewOrder(
    undefined,
    undefined,
    undefined,
    localStorage["pendingDetails"]
      ? OrderDetail.fromJsonArray(
          JSON.parse(localStorage["pendingDetails"] ?? "")
        )
      : [],
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ),
  openCart: false,
  openDeliveryDate: false,
  openFinishOrder: false,
  openOrderDetails: false,
  openOrderHistory: false,
  orderSelected: undefined,
  status: "ready",
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    selectOrder: (
      state: OrderState,
      action: PayloadAction<Order | undefined>
    ) => {
      state.orderSelected = action.payload;
    },

    setOpenCart: (state: OrderState, action: PayloadAction<boolean>) => {
      state.openCart = action.payload;
    },

    setOpenDeliveryDate: (
      state: OrderState,
      action: PayloadAction<boolean>
    ) => {
      state.openDeliveryDate = action.payload;
    },

    setNewOrderDetails: (
      state: OrderState,
      action: PayloadAction<OrderDetail[]>
    ) => {
      state.newOrder.details = action.payload;
    },

    resetNewOrder: (state: OrderState) => {
      localStorage.setItem("pendingDetails", JSON.stringify([]));
      state.newOrder = new NewOrder(
        undefined,
        undefined,
        undefined,
        [],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      state.openCart = false;
      state.openFinishOrder = false;
    },

    setOpenOrderDetails: (
      state: OrderState,
      action: PayloadAction<OpenOrderDetailsDto>
    ) => {
      state.openOrderDetails = action.payload.open;
      state.orderSelected = action.payload.order;
    },

    setOpenOrderHistory: (
      state: OrderState,
      action: PayloadAction<boolean>
    ) => {
      state.openOrderHistory = action.payload;
    },

    setOpenFinishOrder: (state: OrderState, action: PayloadAction<boolean>) => {
      state.openFinishOrder = action.payload;
    },

    initNewOrderFromOrder: (
      state: OrderState,
      action: PayloadAction<Order>
    ) => {
      state.newOrder.clientNote = action.payload.clientNote;
      state.newOrder.shippingAddress = action.payload.shippingAddress;
      state.newOrder.billingAddress = action.payload.billingAddress;
      state.newOrder.details = action.payload.details;
      state.newOrder.discount = action.payload.discount;
      state.newOrder.estimatedDeliveryDate =
        action.payload.estimatedDeliveryDate;
      state.newOrder.paymentMethod = action.payload.paymentMethod;
      state.newOrder.shippingMethod = action.payload.shippingMethod;
    },

    modifyClientNoteInNewOrder: (
      state: OrderState,
      action: PayloadAction<string>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.clientNote = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyShippingAddressInNewOrder: (
      state: OrderState,
      action: PayloadAction<Address>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.shippingAddress = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyBillingAddressInNewOrder: (
      state: OrderState,
      action: PayloadAction<Address>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.billingAddress = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyCreditCardInNewOrder: (
      state: OrderState,
      action: PayloadAction<CreditCard>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.creditCard = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyDetailInNewOrder: (
      state: OrderState,
      action: PayloadAction<OrderDetail>
    ) => {
      let exists = false;

      const details = state.newOrder.details?.map((detail) => {
        if (detail.price.product.id === action.payload.price.product.id) {
          exists = true;
          return action.payload;
        }
        return detail;
      });

      if (!exists) {
        details.push(action.payload);
      }

      let modifiedNewOrder: NewOrder = Object.create(state.newOrder);
      modifiedNewOrder.details = details.filter(
        (detail) => detail.quantity > 0
      );
      state.newOrder = modifiedNewOrder;

      // Se persisten los details para cuando se refresque el storage sigamos teniendo los details en el carrito
      localStorage["pendingDetails"] = JSON.stringify(
        modifiedNewOrder.details?.map((detail) => detail.toJson())
      );
    },

    modifyDiscountInNewOrder: (
      state: OrderState,
      action: PayloadAction<OrderDiscount>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.discount = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyEstimatedDeliverDateInNewOrder: (
      state: OrderState,
      action: PayloadAction<Date>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.estimatedDeliveryDate = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyInvoiceRequiredInNewOrder: (
      state: OrderState,
      action: PayloadAction<boolean>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.invoiceRequired = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyPaymentInfoInNewOrder: (
      state: OrderState,
      action: PayloadAction<PaymentInfoDto>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.paymentInfo = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyPaymentMethodInNewOrder: (
      state: OrderState,
      action: PayloadAction<PaymentMethod>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.paymentMethod = action.payload;
      state.newOrder = modifiedNewOrder;
    },

    modifyShippingMethodInNewOrder: (
      state: OrderState,
      action: PayloadAction<ShippingMethod>
    ) => {
      let modifiedNewOrder = Object.create(state.newOrder);
      modifiedNewOrder.shippingMethod = action.payload;
      modifiedNewOrder.estimatedDeliveryDate = undefined;
      state.newOrder = modifiedNewOrder;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrderState>) => {
    const updateOrder = (state: OrderState, action: PayloadAction<Order>) => {
      state.orders = state.orders.map((order) =>
        order.id === action.payload.id ? action.payload : order
      );
      if (state.orderSelected?.id == action.payload.id) {
        state.orderSelected = action.payload;
      }
      state.status = "ready";
    };

    /*
            Create Order
        */
    builder
      .addCase(createOrderThunk.pending, (state: OrderState) => {
        state.status = "loading";
      })
      .addCase(
        createOrderThunk.fulfilled,
        (state: OrderState, action: PayloadAction<Order>) => {
          state.orders.unshift(action.payload);
          state.orderSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(createOrderThunk.rejected, (state: OrderState) => {
        state.status = "error";
      });

    /*
            Find Order By Id
        */
    builder
      .addCase(findOrderByIdThunk.pending, (state: OrderState) => {
        state.status = "loading";
      })
      .addCase(
        findOrderByIdThunk.fulfilled,
        (state: OrderState, action: PayloadAction<Order>) => {
          state.orderSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findOrderByIdThunk.rejected, (state: OrderState) => {
        state.status = "error";
      });

    /*
            Search Orders
       */
    builder
      .addCase(searchOrdersThunk.pending, (state: OrderState) => {
        state.status = "loading";
      })
      .addCase(
        searchOrdersThunk.fulfilled,
        (state: OrderState, action: PayloadAction<SearchResponse<Order>>) => {
          state.orders = action.payload.data;
          state.status = "ready";
        }
      )
      .addCase(searchOrdersThunk.rejected, (state: OrderState) => {
        state.status = "error";
      });

    /*
            Update Order
        */
    builder
      .addCase(updateOrderThunk.pending, (state: OrderState) => {
        state.status = "loading";
      })
      .addCase(
        updateOrderThunk.fulfilled,
        (state: OrderState, action: PayloadAction<Order>) => {
          updateOrder(state, action);
        }
      )
      .addCase(updateOrderThunk.rejected, (state: OrderState) => {
        state.status = "error";
      });
  },
});

const {
  resetNewOrder,
  setNewOrderDetails,
  setOpenCart,
  setOpenDeliveryDate,
  setOpenOrderDetails,
  setOpenOrderHistory,
  setOpenFinishOrder,
  initNewOrderFromOrder,
  modifyBillingAddressInNewOrder,
  modifyClientNoteInNewOrder,
  modifyCreditCardInNewOrder,
  modifyDetailInNewOrder,
  modifyDiscountInNewOrder,
  modifyEstimatedDeliverDateInNewOrder,
  modifyInvoiceRequiredInNewOrder,
  modifyPaymentInfoInNewOrder,
  modifyPaymentMethodInNewOrder,
  modifyShippingAddressInNewOrder,
  modifyShippingMethodInNewOrder,
  selectOrder,
} = orderSlice.actions;

const getOrders = (state: RootState) => state.order.orders;
const getNewOrder = (state: RootState) => state.order.newOrder;
const getOpenCart = (state: RootState) => state.order.openCart;
const getOpenDeliveryDate = (state: RootState) => state.order.openDeliveryDate;
const getOpenOrderDetails = (state: RootState) => state.order.openOrderDetails;
const getOpenOrderHistory = (state: RootState) => state.order.openOrderHistory;
const getOpenFinishOrder = (state: RootState) => state.order.openFinishOrder;
const getOrderSelected = (state: RootState) => state.order.orderSelected;
const getStatus = (state: RootState) => state.order.status;

export {
  resetNewOrder,
  selectOrder,
  setNewOrderDetails,
  setOpenCart,
  setOpenDeliveryDate,
  setOpenOrderDetails,
  setOpenOrderHistory,
  setOpenFinishOrder,
  initNewOrderFromOrder,
  modifyBillingAddressInNewOrder,
  modifyCreditCardInNewOrder,
  modifyClientNoteInNewOrder,
  modifyDetailInNewOrder,
  modifyDiscountInNewOrder,
  modifyEstimatedDeliverDateInNewOrder,
  modifyInvoiceRequiredInNewOrder,
  modifyPaymentInfoInNewOrder,
  modifyPaymentMethodInNewOrder,
  modifyShippingAddressInNewOrder,
  modifyShippingMethodInNewOrder,
  getNewOrder,
  getOpenCart,
  getOpenDeliveryDate,
  getOpenFinishOrder,
  getOpenOrderDetails,
  getOpenOrderHistory,
  getOrders,
  getOrderSelected,
  getStatus,
};

export default orderSlice.reducer;
