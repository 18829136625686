import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { ShippingMethod } from "../domain/entities/ShippingMethod";
import { findShippingMethodsThunk } from "./ShippingMethodThunk";

interface ShippingMethodState {
    openShippingMethod: boolean,
    shippingMethods: ShippingMethod[];
    status: StateStatus;
}

export const initialState: ShippingMethodState = {
    openShippingMethod: false,
    shippingMethods: [],
    status: 'ready',
}

const shippingMethodSlice = createSlice({
    name: 'shippingMethods',
    initialState,
    reducers: {
        setOpenShippingMethod: (
            state: ShippingMethodState,
            action: PayloadAction<boolean>
        ) => {
            state.openShippingMethod = action.payload;
        },
        setShippingMethods: (
            state: ShippingMethodState,
            action: PayloadAction<ShippingMethod[]>) => {
            state.shippingMethods = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<ShippingMethodState>) => {
        /*
            Find ShippingMethods
        */
        builder.addCase(findShippingMethodsThunk.pending, (state: ShippingMethodState) => {
            state.status = 'loading';
        }).addCase(findShippingMethodsThunk.fulfilled, (state: ShippingMethodState, action: PayloadAction<ShippingMethod[]>) => {
            state.shippingMethods = action.payload;
        }).addCase(findShippingMethodsThunk.rejected, (state: ShippingMethodState) => {
            state.status = 'error';
        });
    }
});

const { setOpenShippingMethod, setShippingMethods } = shippingMethodSlice.actions;

const getOpenShippingMethod = (state: RootState) => state.shippingMethod.openShippingMethod;
const getShippingMethods = (state: RootState) => state.shippingMethod.shippingMethods;
const getStatus = (state: RootState) => state.shippingMethod.status;

export {
    getOpenShippingMethod,
    getShippingMethods,
    getStatus,

    setOpenShippingMethod,
    setShippingMethods
}

export default shippingMethodSlice.reducer;