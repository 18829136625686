import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC } from "react";
import { CreditCard } from "src/features/client/domain/entities/CreditCard"
import { SelectCreditCardStyles as styles } from "./styles";
import {
    CreditCard as CreditCardIcon,
    DeleteOutlined as DeleteIcon,
    Check as SelectedIcon
} from '@mui/icons-material/';


interface CreditCardItemProps {
    onClick: (creditCard: CreditCard) => void;
    onDeleteClick?: (creditCard: CreditCard) => void;
    creditCard: CreditCard;
    creditCardSelected: CreditCard;
}

export const CreditCardItem: FC<CreditCardItemProps> = ({ onClick, onDeleteClick, creditCard, creditCardSelected }) => {

    if (!CreditCard) {
        return null;
    }

    return (

        <ListItem alignItems="flex-start" sx={styles.creditCardItem}
            onClick={() => onClick(creditCard)}>
            <ListItemIcon>
                <CreditCardIcon />
            </ListItemIcon>
            <ListItemText
                sx={{ textAlign: "left" }}
                primary={creditCard.brand.toUpperCase() + " ****" + creditCard.lastCardNumbers}
                secondaryTypographyProps={{ component: 'span' }}
                secondary={
                    <div >
                        <div>{"Válida hasta: " + creditCard.expMonth + "/" + creditCard.expYear}</div>
                        <div>{creditCard.ownerName.toUpperCase()}</div>
                    </div>
                }

            />

            {
                onDeleteClick ?
                    <ListItemIcon>
                        <IconButton edge="end" aria-label="comments">
                            <DeleteIcon />
                        </IconButton>
                    </ListItemIcon>
                    :
                    ((creditCardSelected && creditCardSelected.stripeId === creditCard.stripeId) ?
                        <ListItemIcon>
                            <IconButton edge="end" aria-label="comments">
                                <SelectedIcon />
                            </IconButton>
                        </ListItemIcon> : "")
            }
        </ListItem >

    );
}