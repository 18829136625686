export class OrderStatus {

    static readonly Canceled = new OrderStatus('canceled');

    static readonly InReview = new OrderStatus('in_review');

    static readonly Confirmed = new OrderStatus('confirmed');

    static readonly InProgress = new OrderStatus('in_progress');

    static readonly Prepared = new OrderStatus('prepared');

    static readonly Sent = new OrderStatus('sent');

    static readonly Delivered = new OrderStatus('delivered');

    constructor(public readonly value: string) { }

    static getValues(): OrderStatus[] {
        return [this.Canceled, this.InReview, this.Confirmed, this.InProgress, this.Prepared, this.Sent, this.Delivered];
    }

    static fromString(string: string): OrderStatus {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}