export class CouponsScope {
    static readonly None = new CouponsScope('none');

    static readonly Retail = new CouponsScope('retail');

    static readonly Company = new CouponsScope('company');

    static readonly Both = new CouponsScope('both');

    constructor(public readonly value: string) { }

    static getValues(): CouponsScope[] {
        return [this.None, this.Retail, this.Company, this.Both];
    }

    static fromString(string: string): CouponsScope {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}