import { NotArrayError } from "src/app/errors/NotArrayError";

export class Tax {
  constructor(
    readonly id: string,
    readonly erpId: string,
    readonly name: string,
    readonly value: number
  ) {}

  public static fromJson(json: any): Tax {
    return new Tax(json["id"], json["erpId"], json["name"], json["value"]);
  }

  public toJson(): any {
    return {
      id: this.id,
      erpId: this.erpId,
      name: this.name,
      value: this.value,
    };
  }

  public static fromJsonArray(jsonArray: any): Tax[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
