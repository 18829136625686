import { NotArrayError } from "src/app/errors/NotArrayError";
import { OrderDiscountSourceType } from "./OrderDiscountSourceType";

export class OrderDiscount {
    constructor(
        readonly sourceId: string,
        readonly sourceType: OrderDiscountSourceType,
        readonly value: number,
    ) { }

    public static fromJson(json: any): OrderDiscount {
        return new OrderDiscount(
            json['sourceId'],
            json['sourceType'],
            json['value'],
        );
    }

    public static fromJsonArray(jsonArray: any): OrderDiscount[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}