import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from 'src/app/components/Footer';
import { useAppDispatch } from 'src/config/hooks';
import { getOpenCart, setOpenCart } from 'src/features/order/data/OrderSlice';
import { useAppSelector } from '../../../config/hooks';
import DetailFormDialog from 'src/features/order/ui/detail_form';
import ManageAddressDrawer from 'src/features/address/ui/manage_address';
import SelectAddressDrawer from 'src/features/address/ui/select_address';
import AccountDrawer from 'src/features/client/ui/account_drawer';
import BillingInfoDrawer from 'src/features/client/ui/billing_info_drawer';
import DeliveryDateDrawer from 'src/features/order/ui/delivery_date_drawer';
import FinishOrderDrawer from 'src/features/order/ui/finish_order_drawer';
import CreditCardDrawer from 'src/features/payment_method/ui/select_credit_card_drawer';
import ManageCreditCardDrawer from 'src/features/payment_method/ui/manage_credit_card_drawer';
import PaymentMethodDrawer from 'src/features/payment_method/ui/payment_method_drawer';
import OrderDetailsDrawer from 'src/features/order/ui/order_details';
import OrdersHistoryDrawer from 'src/features/order/ui/order_history';
import ShippingMethodDrawer from 'src/features/shipping_method/ui/shipping_method_drawer';
import useAuth from '../../hooks/useAuth';
import CartDrawer from 'src/features/order/ui/cart/';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { stripeConfigurations } from 'src/config/stripe';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const stripe = loadStripe(stripeConfigurations[localStorage.slug].publicKey);


  const handleOnClickCartButton = () => {
    dispatch(setOpenCart(true));
  }

  const DialogsContainer = () => {
    if (!user) {
      return <></>;
    }
    return (
      <>

        <AccountDrawer />
        <BillingInfoDrawer />
        <ManageAddressDrawer />
        <OrdersHistoryDrawer />
        <OrderDetailsDrawer />
        <SelectAddressDrawer />
        <Elements stripe={stripe}>
          <FinishOrderDrawer />
          <ManageCreditCardDrawer />
          <PaymentMethodDrawer />
          <CreditCardDrawer />
        </Elements>
        <ShippingMethodDrawer />
        <DeliveryDateDrawer />
        <CartDrawer />
        <DetailFormDialog />
      </>
    );
  }

  return (
    <>
      <Header onClickCartButton={handleOnClickCartButton} />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Footer />
      <DialogsContainer />
    </>
  );
};

export default AccentHeaderLayout;
