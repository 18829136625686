import { FindNotificationsByClientIdDto, NotificationFilter } from './NotificationDto';
import { Notification } from '../domain/entities/Notification';
import { getRequest } from 'src/config/http';

export abstract class NotificationService {
    public static async findNotificationByIdAsync(notificationId: string): Promise<Notification> {
        return Notification.fromJson(await getRequest(`/notifications/${notificationId}`));
    }

    public static async searchNotificationsAsync(filters: NotificationFilter): Promise<Notification[]> {
        const response: Notification[] = await getRequest('/notifications', JSON.parse(JSON.stringify(filters)));
        return Notification.fromJsonArray(response);
    }

    public static async findNotificationsByClientIdAsync(filters: FindNotificationsByClientIdDto): Promise<Notification[]> {
        const response: Notification[] = await getRequest(`/clients/${filters.clientId}/notifications`, JSON.parse(JSON.stringify(filters)));
        return Notification.fromJsonArray(response);
    }
}