import { CreateClientDto, UpdateClientDto, SendEmailDto, ActivateClientDto } from './ClientDto';
import { Client } from '../domain/entities/Client';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'src/config/http';
import { Status } from '../../commons/Status';
import { ClientConsumes } from '../domain/entities/ClientConsumes';
import { CreditCard } from '../domain/entities/CreditCard';

export abstract class ClientService {

    public static async createClientAsync(createClient: CreateClientDto): Promise<Client> {
        return Client.fromJson(await postRequest('/clients', createClient));
    }

    public static async deleteClientAsync(clientId: string): Promise<Client> {
        return Client.fromJson(await deleteRequest(`/clients/${clientId}`));
    }

    public static async findClientByIdAsync(clientId: string): Promise<Client> {
        return Client.fromJson(await getRequest(`/clients/${clientId}`));
    }

    public static async getClientByActivationCode(activationCode: string): Promise<Client> {
        return Client.fromJson(await getRequest('/clients', { activation_code: activationCode }))
    }

    public static async getClientByEmail(email: string): Promise<Client> {
        return Client.fromJson(await getRequest('/clients', { email }))
    }

    public static async getConsumesByClient(clientId: string): Promise<ClientConsumes[]> {
        return ClientConsumes.fromJsonArray(await getRequest(`/clients/${clientId}/consumes`));
    }

    public static async updateClientAsync(updateClient: UpdateClientDto): Promise<Client> {
        return Client.fromJson(await putRequest(`/clients/${updateClient.clientId}`, updateClient));
    }

    public static async getClientStatusAsync(email: string): Promise<any> {
        const response: any = await getRequest(`/client_status`, { email });

        return {
            id: response['clientId'],
            status: Status.fromString(response['status']),
            salesBlockedMessage: response['salesBlockedMessage']
        };
    }

    public static async sendEmailToClientAsync(sendEmail: SendEmailDto): Promise<any> {
        return postRequest(`/clients/${sendEmail.clientId}/emails?type=${sendEmail.type}`, {});
    }

    public static async activateClientAsync(activateClient: ActivateClientDto): Promise<void> {
        return postRequest(`/clients/${activateClient.clientId}/activate`, { activationToken: activateClient.activationToken });
    }


}