import CreditCardIcon from "@mui/icons-material/CreditCardTwoTone";
import HomeIcon from "@mui/icons-material/HomeTwoTone";
import StatusIcon from "@mui/icons-material/InfoTwoTone";
import LocalShippingIcon from "@mui/icons-material/LocalShippingTwoTone";
import DateSelectedIcon from "@mui/icons-material/TodayTwoTone";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "src/app/components/ConfirmDialog";
import DrawerHeader from "src/app/components/DrawerHeader";
import useAuth from "src/app/hooks/useAuth";
import { setOpenProfile } from "src/features/client/data/ClientSlice";
import {
  getNewOrder,
  setOpenCart,
  setOpenOrderHistory,
} from "src/features/order/data/OrderSlice";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import {
  getOpenOrderDetails,
  getOrderSelected,
  initNewOrderFromOrder,
  setOpenOrderDetails,
} from "../../data/OrderSlice";
import DetailsInOrderList from "./DetailsInOrderList";
import EditOrderButton from "./EditOrderButton";
import OrderDetailInfoItem from "./OrderDetailItem";
import { OrderDetailsContainer } from "./styles";

const OrderDetailsDrawer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const open = useAppSelector(getOpenOrderDetails);
  const orderSelected = useAppSelector(getOrderSelected);
  const newOrder = useAppSelector(getNewOrder);
  const [openConfirmEditOrder, setOpenConfirmEditOrder] = useState(false);

  const handleOnClose = (): void => {
    dispatch(setOpenOrderDetails({ open: false, order: undefined }));
  };

  const handleOnEditOrderButtonClicked = (): void => {
    if (newOrder) {
      setOpenConfirmEditOrder(true);
    } else {
      handleOnCloseEditOrderDialog("init_order");
    }
  };

  const handleOnCloseEditOrderDialog = (initOrder?: string): void => {
    if (initOrder) {
      dispatch(setOpenOrderDetails({ open: false }));
      dispatch(setOpenOrderHistory(false));
      dispatch(setOpenProfile(false));
      dispatch(initNewOrderFromOrder(orderSelected));
      dispatch(setOpenCart(true));
    }

    setOpenConfirmEditOrder(false);
  };

  const shippingMethodValueDescription = () => {
    const shippingValue = orderSelected.getShippingValue();
    return shippingValue === 0 ? "Gratis" : `${shippingValue.toFixed(2)} €`;
  };

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={handleOnClose}>
        <DrawerHeader
          title={t("Order.Title")}
          onClickBackButton={handleOnClose}
        />
        {orderSelected ? (
          <Box sx={OrderDetailsContainer(theme.palette.background.paper)}>
            <List>
              <OrderDetailInfoItem
                icon={StatusIcon}
                label={t("Commons.Status.Title")}
                value={t(
                  `Order.Status.${orderSelected.status.value}`
                ).toUpperCase()}
              />
              <OrderDetailInfoItem
                icon={HomeIcon}
                label={t("Order.ShippingAddress")}
                value={orderSelected.shippingAddress.street}
              />
              <OrderDetailInfoItem
                icon={LocalShippingIcon}
                label={t("Order.ShippingMethod")}
                value={orderSelected.shippingMethod.name}
              />
              <OrderDetailInfoItem
                icon={DateSelectedIcon}
                label={t("Order.Date")}
                value={moment(orderSelected.createdAt).format("DD-MM-YY HH:mm")}
              />
              {orderSelected.estimatedDeliveryDate ? (
                <OrderDetailInfoItem
                  icon={DateSelectedIcon}
                  label={t("Order.Estimated")}
                  value={moment(orderSelected.estimatedDeliveryDate).format(
                    "DD-MM-YYYY"
                  )}
                />
              ) : (
                <></>
              )}
              <OrderDetailInfoItem
                icon={CreditCardIcon}
                label={t("Order.PaymentMethod")}
                value={orderSelected.paymentMethod.name}
              />
              <Divider sx={{ height: "1px" }} />
              <DetailsInOrderList details={orderSelected.details} />

              <ListItem style={{ paddingBottom: 0 }}>
                <ListItemText
                  primary={t("Order.Buy")}
                  secondary={
                    orderSelected.details.length +
                    (orderSelected.details.length > 1
                      ? " productos"
                      : " producto")
                  }
                />
                <ListItemSecondaryAction>
                  {orderSelected
                    .getTotalPrice(user?.type !== "company")
                    .toFixed(2)}{" "}
                  €
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem style={{ paddingTop: 0 }}>
                <ListItemText
                  primary={t("Order.ShippingCost")}
                  secondary={orderSelected.shippingMethod.name}
                />
                <ListItemSecondaryAction>
                  {shippingMethodValueDescription()}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider sx={{ height: "1px" }} />
              <ListItem>
                <ListItemText
                  primary={t("Order.Total")}
                  primaryTypographyProps={{ variant: "h6" }}
                />
                <ListItemSecondaryAction>
                  <Typography variant="h6">
                    {(
                      orderSelected.getTotalPrice(user?.type !== "company") +
                      orderSelected.getShippingValue(user?.type !== "company")
                    ).toFixed(2)}{" "}
                    €
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <EditOrderButton
              visible={orderSelected.isEditable()}
              onClickEditOrderButton={handleOnEditOrderButtonClicked}
            />
          </Box>
        ) : (
          ""
        )}
      </Drawer>
      <ConfirmDialog
        itemId={"no_id_required"}
        open={openConfirmEditOrder}
        title={t("Order.Edit")}
        text={t("Order.EditText")}
        onCloseDialog={handleOnCloseEditOrderDialog}
      />
    </>
  );
};

export default OrderDetailsDrawer;
