import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../domain/entities/Client";
import { ClientConsumes } from "../domain/entities/ClientConsumes";
import { CreateClientDto, SendEmailDto, UpdateClientDto } from "./ClientDto";
import { ClientService } from "./ClientService";
import { FindClientAddressesDto } from "../../address/data/AddressDto";
import { Address } from "src/features/address/domain/entities/Address";
import { AddressService } from "../../address/data/AddressService";
import { CreditCard } from "../domain/entities/CreditCard";

export const createClientThunk = createAsyncThunk(
  "client/create",
  async (dto: CreateClientDto): Promise<Client> =>
    ClientService.createClientAsync(dto)
);

export const updateClientThunk = createAsyncThunk(
  "client/update",
  async (dto: UpdateClientDto): Promise<Client> =>
    ClientService.updateClientAsync(dto)
);

export const deleteClientThunk = createAsyncThunk(
  "client/delete",
  async (clientId: string): Promise<Client> =>
    ClientService.deleteClientAsync(clientId)
);

export const findClientByIdThunk = createAsyncThunk(
  "client/findById",
  async (clientId: string): Promise<Client> =>
    ClientService.findClientByIdAsync(clientId)
);

export const getConsumesByClientThunk = createAsyncThunk(
  "client/consumes",
  async (clientId: string): Promise<ClientConsumes[]> =>
    ClientService.getConsumesByClient(clientId)
);

export const getClientStatusThunk = createAsyncThunk(
  "client/status",
  async (email: string): Promise<any> =>
    ClientService.getClientStatusAsync(email)
);

export const sendEmailToClientThunk = createAsyncThunk(
  "client/sendEmail",
  async (dto: SendEmailDto): Promise<any> =>
    ClientService.sendEmailToClientAsync(dto)
);


