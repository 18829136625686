import { CircularProgress, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerHeader from "src/app/components/DrawerHeader";
import useAuth from "src/app/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import { getNewOrder } from "src/features/order/data/OrderSlice";
import { NewOrder } from "src/features/order/domain/entities/NewOrder";
import { ShippingAreaService } from "src/features/shipping_area/data/ShippingAreaService";
import { ShippingArea } from "src/features/shipping_area/domain/entities/ShippingArea";
import { getPaymentMethods, getStatus, getOpenSelectPaymentMethod, setOpenSelectPaymentMethod } from "../../data/PaymentMethodSlice";
import { searchPaymentMethodsThunk } from "../../data/PaymentMethodThunk";
import { PaymentMethod } from "../../domain/entities/PaymentMethod";
import SelectPaymentMethodList from "./SelectPaymentMethodList";

const PaymentMethodDrawer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const paymentMethods = useAppSelector(getPaymentMethods);
    const newOrder: NewOrder = useAppSelector(getNewOrder);
    const paymentMethodsStatus = useAppSelector(getStatus);
    const openSelectPaymentMethod = useAppSelector(getOpenSelectPaymentMethod)
    const { user } = useAuth();


    useEffect(() => {
        if (openSelectPaymentMethod) {
            dispatch(searchPaymentMethodsThunk(null));
        }
    }, [openSelectPaymentMethod])




    const handleOnClose = () => {
        dispatch(setOpenSelectPaymentMethod(false));
    }



    return (
        <>
            <Drawer anchor={"right"} open={openSelectPaymentMethod} onClose={handleOnClose} >
                <DrawerHeader title="Opciones de pago" onClickBackButton={handleOnClose} />
                {
                    paymentMethodsStatus === 'loading'
                        ? <CircularProgress />
                        : <SelectPaymentMethodList
                            paymentMethods={paymentMethods}
                            paymentMethodSelected={newOrder.paymentMethod}
                            onClose={handleOnClose}
                          
                        />
                }
            </Drawer>
        </>
    );
}

export default PaymentMethodDrawer;