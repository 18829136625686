import { NotArrayError } from "src/app/errors/NotArrayError";

export class ErpStatus {
    
    constructor(
        readonly status: ErpStatusType,

        readonly message?: string,
    ) { }

    public static fromJson(json: any): ErpStatus {
        return new ErpStatus(
            ErpStatusType.fromString(json['status']),
            json['message'],
        );
    }

    public static fromJsonArray(jsonArray: any): ErpStatus[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}

export class ErpStatusType {
    static readonly NotApply = new ErpStatusType('not_apply');

    static readonly Confirmed = new ErpStatusType('confirmed');

    static readonly Pending = new ErpStatusType('pending');

    static readonly Error = new ErpStatusType('error');

    static readonly NotUpdated = new ErpStatusType('not_updated');

    constructor(public readonly value: string) { }

    static getValues(): ErpStatusType[] {
        return [this.NotApply, this.Confirmed, this.Pending, this.Error, this.NotUpdated];
    }

    static fromString(string: string): ErpStatusType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}

export interface ErpStatusDto {
    readonly status: string;

    readonly message?: string;
}