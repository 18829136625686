import { NotArrayError } from "src/app/errors/NotArrayError";

export class Fee {
  constructor(
    readonly id: string,
    readonly erpId: string,
    readonly name: string
  ) {}

  public static fromJson(json: any): Fee {
    return new Fee(json["id"], json["erpId"], json["name"]);
  }

  public toJson(): any {
    return {
      id: this.id,
      erpId: this.erpId,
      name: this.name,
    };
  }

  public static fromJsonArray(jsonArray: any): Fee[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
