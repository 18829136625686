const ShippingMethodListContainerStyles = {
    minWidth: "calc(min(100vw, 375px))",
    maxWidth: "calc(min(100vw, 375px))",
}

const ShippingMethodListItemStyles = {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "16px",
    paddingBottom: "16px",
    background: "white",
    borderBottom: "1px solid #f5f5f5"
}

export {
    ShippingMethodListContainerStyles,
    ShippingMethodListItemStyles
}