import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/config/store";
import { StateStatus } from "src/features/commons/Entities";
import { Client } from "../domain/entities/Client";
import { ClientConsumes } from "../domain/entities/ClientConsumes";
import {
  createClientThunk,
  deleteClientThunk,
  findClientByIdThunk,
  getConsumesByClientThunk,
  updateClientThunk,
} from "./ClientThunk";

interface ClientState {
  clientSelected?: Client;
  clientConsumes: ClientConsumes[];
  openBillingInfo: boolean;
  openProfile: boolean;
  status: StateStatus;
}

export const initialState: ClientState = {
  clientSelected: undefined,
  clientConsumes: [],
  openBillingInfo: false,
  openProfile: false,
  status: "ready",
};

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    selectClient: (
      state: ClientState,
      action: PayloadAction<Client | undefined>
    ) => {
      state.clientSelected = action.payload;
    },
    setOpenBillingInfo: (
      state: ClientState,
      action: PayloadAction<boolean>
    ) => {
      state.openBillingInfo = action.payload;
    },

    setOpenProfile: (state: ClientState, action: PayloadAction<boolean>) => {
      state.openProfile = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ClientState>) => {
    /*
            Create Client
        */
    builder
      .addCase(createClientThunk.pending, (state: ClientState) => {
        state.status = "loading";
      })
      .addCase(
        createClientThunk.fulfilled,
        (state: ClientState, action: PayloadAction<Client>) => {
          state.clientSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(createClientThunk.rejected, (state: ClientState) => {
        state.status = "error";
      });

    /*
            Update Client
        */
    builder
      .addCase(updateClientThunk.pending, (state: ClientState) => {
        state.status = "loading";
      })
      .addCase(
        updateClientThunk.fulfilled,
        (state: ClientState, action: PayloadAction<Client>) => {
          state.clientSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(updateClientThunk.rejected, (state: ClientState) => {
        state.status = "error";
      });

    /*
            Delete Client
        */
    builder
      .addCase(deleteClientThunk.pending, (state: ClientState) => {
        state.status = "loading";
      })
      .addCase(
        deleteClientThunk.fulfilled,
        (state: ClientState, action: PayloadAction<Client>) => {
          state.clientSelected = undefined;
          state.status = "ready";
        }
      )
      .addCase(deleteClientThunk.rejected, (state: ClientState) => {
        state.status = "error";
      });

    /*
            Find Client by Id
        */
    builder
      .addCase(findClientByIdThunk.pending, (state: ClientState) => {
        state.status = "loading";
      })
      .addCase(
        findClientByIdThunk.fulfilled,
        (state: ClientState, action: PayloadAction<Client>) => {
          state.clientSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findClientByIdThunk.rejected, (state: ClientState) => {
        state.status = "error";
      });

    /*
           Get Client Consumes
        */
    builder
      .addCase(getConsumesByClientThunk.pending, (state: ClientState) => {
        state.status = "loading";
      })
      .addCase(
        getConsumesByClientThunk.fulfilled,
        (state: ClientState, action: PayloadAction<ClientConsumes[]>) => {
          state.clientConsumes = action.payload;
          state.status = "ready";
        }
      )
      .addCase(getConsumesByClientThunk.rejected, (state: ClientState) => {
        state.status = "error";
      });
  },
});

const { selectClient, setOpenBillingInfo, setOpenProfile } =
  clientSlice.actions;

const getClientSelected = (state: RootState) => state.client.clientSelected;
const getClientConsumes = (state: RootState) => state.client.clientConsumes;
const getOpenBillingInfo = (state: RootState) => state.client.openBillingInfo;
const getOpenProfile = (state: RootState) => state.client.openProfile;
const getStatus = (state: RootState) => state.client.status;

export {
  selectClient,
  setOpenBillingInfo,
  setOpenProfile,
  getClientSelected,
  getClientConsumes,
  getOpenBillingInfo,
  getOpenProfile,
  getStatus,
};

export default clientSlice.reducer;
