import { PropsWithChildren, createContext, useContext, useState } from "react";

export interface PartnerState {
  readonly partnerId: string;
  salesBlockedMessage: string | null;
}

export const PartnerContext = createContext({} as PartnerState);

export const PartnerContextProvider = ({
  partnerId,
  salesBlockedMessage: _salesBlockedMessage,
  children,
}: PropsWithChildren<{
  partnerId: string;
  salesBlockedMessage: string | null;
}>) => {
  const [salesBlockedMessage] = useState<string | null>(_salesBlockedMessage);

  return (
    <PartnerContext.Provider value={{ partnerId, salesBlockedMessage }}>
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartnerContextProvider = () => useContext(PartnerContext);
