import { NotArrayError } from "src/app/errors/NotArrayError";
import { ClientType } from "./ClientType";

export class ClientLite {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly erpId: string,
    readonly type: ClientType,

    readonly salesmanName?: string
  ) {}

  public static fromJson(json: any): ClientLite {
    return new ClientLite(
      json["id"],
      json["billingInfo"] ? json["billingInfo"]["name"] : json["name"],
      json["erpId"],
      ClientType.fromString(json["type"]),
      json["commercial"] ? json["commercial"]["name"] : undefined
    );
  }

  public static fromJsonArray(jsonArray: any): ClientLite[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
