import { Box, alpha, Typography, styled, Avatar, Stack } from '@mui/material';
import { FC, Fragment } from "react";
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { Price } from "../../domain/entities/Price";

interface SearchResultItemProps {
  readonly price: Price;
  readonly onClickPrice: (price: Price) => void;
}

const ListButton = styled(Box)(
  ({ theme }) => `
        background-color: transparent;
        color:  ${theme.colors.alpha.black[100]};
        transition: ${theme.transitions.create(['all'])};
        border: ${theme.colors.alpha.black[10]} solid 1px;
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(1)};
        margin: ${theme.spacing(1, 0)};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.alpha.black[50]};
          transition: ${theme.transitions.create(['all'])};
        }
  
        &:hover {
          background-color: ${alpha(theme.colors.primary.main, 0.08)};
          color:  ${theme.colors.primary.main};
          border-color: ${alpha(theme.colors.primary.main, 0.3)};
  
          & > div > .MuiSvgIcon-root {
            color:  ${theme.colors.primary.main};
          }
        }
  `
);

const SearchResultItem: FC<SearchResultItemProps> = ({ price, onClickPrice }) => {

  return (
    <Fragment>
      <ListButton onClick={() => onClickPrice(price)}>
        <Box display="flex">
          <Avatar variant="square" src={price.product.getFirstPhoto()} sx={{ mr: 1 }} />
          <Stack direction={'column'} alignItems={'start'}>
            <Typography align="center">{price.product.name}</Typography>
            <Typography align="center" variant='subtitle2'>{price.product.erpId}</Typography>
          </Stack>
        </Box>
        <ArrowRightIcon fontSize="small" />
      </ListButton>
    </Fragment>
  )
}

export default SearchResultItem;