import { NotArrayError } from "src/app/errors/NotArrayError";
import { Allergen } from "./Allergen";
import { ProductAmountMode } from "./ProductAmountMode";
import { ProductSalesFormat } from "./ProductSalesFormat";
import { ProductSpecialLabel } from "./ProductSpecialLabel";
import { Tax } from "../../../tax/domain/entities/Tax";
import { Family } from "src/features/family/domain/entities/Family";
import { ProductOption } from "./ProductOption";

export class Product {
  constructor(
    readonly id: string,
    readonly amount: number,
    readonly amountMode: ProductAmountMode,
    readonly erpId: string,
    readonly families: Family[],
    readonly features: string[],
    readonly name: string,
    readonly options: ProductOption[],
    readonly photoUrls: string[],
    readonly salesFormat: ProductSalesFormat,
    readonly stock: number,
    readonly tax: Tax,

    readonly allergens?: Allergen[],
    readonly description?: string,
    readonly featured?: boolean,
    readonly isParent?: boolean,
    readonly parentId?: string,
    readonly specialLabel?: ProductSpecialLabel
  ) {}

  public getFirstPhoto(): string | undefined {
    return this.photoUrls.length > 0 ? this.photoUrls[0] : undefined;
  }

  public static fromJson(json: any): Product {
    return new Product(
      json["id"],
      json["amount"],
      ProductAmountMode.fromString(json["amountMode"]),
      json["erpId"],
      Family.fromJsonArray(json["families"]),
      json["features"],
      json["name"],
      json["options"] instanceof Array ? json["options"].map(option => ProductOption.fromJson(option)) : [],
      json["photoUrls"],
      ProductSalesFormat.fromString(json["salesFormat"]),
      json["stock"],
      Tax.fromJson(json["tax"]),
      json["allergens"] instanceof Array
        ? json["allergens"].map((allergen) => Allergen.fromString(allergen))
        : undefined,
      json["description"],
      json["featured"],
      json["isParent"],
      json["parentId"],
      json["specialLabel"]
        ? ProductSpecialLabel.fromJson(json["specialLabel"])
        : undefined
    );
  }

  public toJson(): any {
    return {
      id: this.id,
      amount: this.amount,
      amountMode: this.amountMode.value,
      erpId: this.erpId,
      families: this.families.map((family) => family.toJson()),
      features: this.features,
      name: this.name,
      options: this.options.map((option: ProductOption) => option.toJson()),
      photoUrls: this.photoUrls,
      salesFormat: this.salesFormat.value,
      stock: this.stock,
      tax: this.tax.toJson(),
      allergens: this.allergens?.map((allergen) => allergen.value),
      description: this.description,
      featured: this.featured,
      isParent: this.isParent,
      parentId: this.parentId,
      specialLabel: this.specialLabel?.toJson(),
    };
  }

  public static fromJsonArray(jsonArray: any): Product[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }

  public getFamiliesAsString(): string {
    return this.families.map((family) => family.name).join(", ");
  }
}
