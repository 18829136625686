import { Box, Button } from "@mui/material";
import { FC } from "react";
import { CartFooter } from "../cart/styles";
import { useTranslation } from 'react-i18next';

interface EditOrderButtonProps {
    readonly onClickEditOrderButton: () => void;

    readonly visible?: boolean;
}

const EditOrderButton: FC<EditOrderButtonProps> = ({ visible, onClickEditOrderButton }) => {
    const { t } = useTranslation();

    if (!visible) {
        return <></>;
    }

    return (
        <Box sx={CartFooter} component="span">
            <Button variant={"contained"} color="warning" fullWidth disableElevation onClick={onClickEditOrderButton} >
                {t('Order.Edit')}
            </Button>
        </Box>
    );
}

export default EditOrderButton;