import { Avatar, Box, Button, Dialog, Slide, styled, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref } from "react";
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';

const AvatarWarning = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.warning.lighter};
        color: ${theme.colors.warning.main};
        width: ${theme.spacing(12)};
        height: ${theme.spacing(12)};
  
        .MuiSvgIcon-root {
          font-size: ${theme.typography.pxToRem(45)};
        }
  `
);

const ButtonError = styled(Button)(
    ({ theme }) => `
       background: ${theme.colors.warning.main};
       color: ${theme.palette.warning.contrastText};
  
       &:hover {
          background: ${theme.colors.warning.dark};
       }
      `
);

const DialogWrapper = styled(Dialog)(
    () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `
);

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ConfirmDialogProps {
    itemId: string;
    open: boolean;
    text: string;

    onCloseDialog: (removeId?: string) => void;

    title?: string;
}

const ConfirmDialog = ({ itemId, open, title, text, onCloseDialog }: ConfirmDialogProps) => {
    const { t } = useTranslation();

    return <>
        <DialogWrapper
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseDialog}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={5}
            >
                <AvatarWarning>
                    <WarningIcon />
                </AvatarWarning>

                <Typography
                    align="center"
                    sx={{
                        pt: 4,
                        px: 6
                    }}
                    variant="h3"
                >
                    {title ? title : t('Commons.ConfirmAction')}
                </Typography>

                <Typography
                    align="center"
                    sx={{
                        pt: 2,
                        pb: 4,
                        px: 6
                    }}
                    fontWeight="normal"
                    color="text.secondary"
                    variant="h4"
                >
                    {text}
                </Typography>

                <Box>
                    <Button
                        variant="text"
                        size="large"
                        sx={{
                            mx: 1
                        }}
                        onClick={() => onCloseDialog()}
                    >
                        {t('Commons.Cancel')}
                    </Button>
                    <ButtonError
                        onClick={() => onCloseDialog(itemId)}
                        size="large"
                        sx={{
                            mx: 1,
                            px: 3
                        }}
                        variant="contained"
                    >
                        {t('Commons.Confirm')}
                    </ButtonError>
                </Box>
            </Box>
        </DialogWrapper>
    </>;
}

export default ConfirmDialog;