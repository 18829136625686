const FooterRoot = (marginLeft: number) => {
    return {
        '& > * + *': {
            marginLeft: marginLeft
        }
    };
}

const FooterMainStyles = (backgroundColor: string) => {
    return {
        backgroundColor,
        width: "100%",
        position: "relative",
    };
}

const FooterAdornment = (breakPointMD: number, breakPointXS: number) => {
    return {
        width: "25em",
        verticalAlign: "bottom",
        [breakPointMD]: {
            width: "21em"
        },
        [breakPointXS]: {
            width: "15em"
        }
    };
}

const FooterMainContainer = (backgroundColor: string) => {
    return {
        position: "absolute",
        backgroundColor
    };
}

const FooterLink = () => {
    return {
        color: "white",
        fontSize: "0.75rem",
        textDecoration: "none"
    };
}

const FooterGritItem = () => {
    return {
        margin: "3em"
    };
}

const FooterIcon = (breakPointXS: number) => {
    return {
        height: "4em",
        width: "4em",
        [breakPointXS]: {
            height: "2.5em",
            width: "2.5em"
        }
    };
}

const FooterSocialContainer = (breakPointXS: number) => {
    return {
        position: "absolute",
        marginTop: "-6em",
        right: "1.5em",
        [breakPointXS]: {
            right: "0.6em"
        }
    }
}

const FooterTitle = () => {
    return {
        fontSize: "23px",
        color: "white",
    };
}

const FooterLogoStyle = () => {
    return {
        height: "8em",
        padding: "0.5em"
    };
}

const FooterNewsLetter = () => {
    return {
        color: "white",
        fontSize: "0.75rem",
        fontWeight: "bold"
    }
}


export {
    FooterRoot,
    FooterMainStyles,
    FooterAdornment,
    FooterMainContainer,
    FooterLink,
    FooterGritItem,
    FooterIcon,
    FooterSocialContainer,
    FooterTitle,
    FooterLogoStyle,
    FooterNewsLetter
}