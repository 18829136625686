import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Notification } from "../domain/entities/Notification";
import {
  searchClientNotificationsThunk,
  searchHomeNotificationsThunk,
} from "./NotificationThunk";

interface NotificationState {
  homeNotifications: Notification[];
  clientNotifications: Notification[];
  status: StateStatus;
}

export const initialState: NotificationState = {
  homeNotifications: [],
  clientNotifications: [],
  status: "uninitialized",
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<NotificationState>) => {
    /*
             Search homeNotifications
        */
    builder
      .addCase(
        searchHomeNotificationsThunk.pending,
        (state: NotificationState) => {
          state.status = "loading";
        }
      )
      .addCase(
        searchHomeNotificationsThunk.fulfilled,
        (state: NotificationState, action: PayloadAction<Notification[]>) => {
          state.homeNotifications = action.payload;
          state.status = "ready";
        }
      )
      .addCase(
        searchHomeNotificationsThunk.rejected,
        (state: NotificationState) => {
          state.status = "error";
        }
      );

    /*
        Search clientNotifications
        */
    builder
      .addCase(
        searchClientNotificationsThunk.pending,
        (state: NotificationState) => {
          state.status = "loading";
        }
      )
      .addCase(
        searchClientNotificationsThunk.fulfilled,
        (state: NotificationState, action: PayloadAction<Notification[]>) => {
          state.clientNotifications = action.payload;
          state.status = "ready";
        }
      )
      .addCase(
        searchClientNotificationsThunk.rejected,
        (state: NotificationState) => {
          state.status = "error";
        }
      );
  },
});

const getHomeNotifications = (state: RootState) =>
  state.notification.homeNotifications;
const geClientNotifications = (state: RootState) =>
  state.notification.clientNotifications;
const getStatus = (state: RootState) => state.notification.status;
const loadingNotifications = (state: RootState) =>
  state.notification.status === "loading";

export { getHomeNotifications, geClientNotifications, getStatus };

export default notificationSlice.reducer;
