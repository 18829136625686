import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC } from "react";
import { PaymentMethod } from "../../domain/entities/PaymentMethod";
import { PaymentMethodItemStyles } from "../styles";
import LocalShippingIcon from '@mui/icons-material/LocalShippingTwoTone';
import { modifyPaymentMethodInNewOrder } from "src/features/order/data/OrderSlice";
import { useAppDispatch } from "src/config/hooks";
import { PaymentTwoTone as CardIcon, PaymentsTwoTone as PaymentIcon, CheckTwoTone as SelectedIcon } from '@mui/icons-material/';

interface PaymentMethodItemProps {
    onClick: (PaymentMethod) => void;
    paymentMethod: PaymentMethod;
    paymentMethodSelected: PaymentMethod
}

export const PaymentMethodItem: FC<PaymentMethodItemProps> = ({ onClick, paymentMethod, paymentMethodSelected }) => {

    if (!paymentMethod) {
        return null;
    }

    return (

        <ListItemButton sx={PaymentMethodItemStyles} onClick={() => onClick(paymentMethod)}>
            <ListItemIcon>
                {paymentMethod.creditCardRequired ? <CardIcon /> : <PaymentIcon />}
            </ListItemIcon>
            <ListItemText
                primary={paymentMethod.name}
                secondary={paymentMethod.description}
            />

            {(paymentMethodSelected?.id === paymentMethod.id) ?
                <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <SelectedIcon />
                    </IconButton>
                </ListItemIcon> : ""

            }
        </ListItemButton>

    );
}