export class AddressType {
    static readonly Shipping = new AddressType('shipping');

    static readonly Billing = new AddressType('billing');

    static readonly Both = new AddressType('both');

    static readonly Venue = new AddressType('venue');

    constructor(public readonly value: string) { }

    static getValues(): AddressType[] {
        return [this.Shipping, this.Billing, this.Both, this.Venue];
    }

    static fromString(string: string): AddressType {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
            (this as any).prototype.constructor.name
            }`
        );
    }
}