import { NotArrayError } from "src/app/errors/NotArrayError";
import { PartnerConfigLoyalty } from "./PartnerConfigLoyalty";

export class PartnerConfig {
  constructor(
    readonly acceptsCompanyClients: boolean,
    readonly acceptsRetailClients: boolean,
    readonly autoActivateClients: boolean,
    readonly loyalty: PartnerConfigLoyalty,

    readonly showBillingAddressInOrder?: boolean,
    readonly salesBlockedMessage?: string | null
  ) {}

  public static fromJson(json: any): PartnerConfig {
    return new PartnerConfig(
      json["acceptsCompanyClients"],
      json["acceptsRetailClients"],
      json["autoActivateClients"],
      PartnerConfigLoyalty.fromJson(json["loyalty"]),
      json["showBillingAddressInOrder"],
      json["salesBlockedMessage"]
    );
  }

  public static fromJsonArray(jsonArray: any): PartnerConfig[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
