import CreditCardIcon from "@mui/icons-material/CreditCardTwoTone";
import HomeIcon from "@mui/icons-material/HomeTwoTone";
import LocalShippingIcon from "@mui/icons-material/LocalShippingTwoTone";
import PaymentsIcon from "@mui/icons-material/PaymentsTwoTone";
import DateSelectedIcon from "@mui/icons-material/TodayTwoTone";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import ConfirmDialog from "src/app/components/ConfirmDialog";
import DrawerHeader from "src/app/components/DrawerHeader";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import { setOpenSelectAddress } from "src/features/address/data/AddressSlice";
import { ClientType } from "src/features/client/domain/entities/ClientType";
import { PartnerService } from "src/features/partner/data/PartnerService";
import { usePartnerContextProvider } from "src/features/partner/ui/context/PartnerContext";
import { PaymentMethodService } from "src/features/payment_method/data/PaymentMethodService";
import { setOpenSelectPaymentMethod } from "src/features/payment_method/data/PaymentMethodSlice";
import { setOpenShippingMethod } from "src/features/shipping_method/data/ShippingMethodSlice";
import Swal, { SweetAlertIcon } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useAuth from "../../../../app/hooks/useAuth";
import { CreateOrderDto } from "../../data/OrderDto";
import {
  getNewOrder,
  getOpenFinishOrder,
  getOrderSelected,
  resetNewOrder,
  selectOrder,
  setOpenDeliveryDate,
  setOpenFinishOrder,
} from "../../data/OrderSlice";
import { createOrderThunk } from "../../data/OrderThunk";
import { NewOrder } from "../../domain/entities/NewOrder";
import { Order } from "../../domain/entities/Order";
import FinishOrderListItem from "./FinishOrderListItem";
import FinishOrderPriceItem from "./FinishOrderPriceItem";
import {
  FinishOrderDrawerContainer,
  FinishOrderDrawerFooter,
  FinishOrderDrawerSectionDivider,
} from "./Style";

interface AlertParams {
  title: string;
  text?: string;
  icon?: SweetAlertIcon;
  loading?: boolean;
  confirm?: boolean;
  timer?: number;
  then?: () => void;
}

const FinishOrderDrawer = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const stripe = useStripe();
  const openFinishOrder = useAppSelector(getOpenFinishOrder);
  const newOrder: NewOrder = useAppSelector(getNewOrder);
  const orderSelected: Order = useAppSelector(getOrderSelected);
  const [waitingForOrderToPay, setWaitingForOrderToPay] = useState(false);
  const [orderClientSecret, setOrderClientSecret] = useState(undefined);
  const { user } = useAuth();
  const { partnerId } = usePartnerContextProvider();
  const ReactSwal = withReactContent(Swal);
  const isRetail: boolean = user?.type === ClientType.Retail.value;
  const [salesBlockedMessage, setSalesBlockedMessage] = useState<string | null>(
    null
  );
  const [openBlockedMessage, setOpenBlockedMessage] = useState(false);

  useEffect(() => {
    //Esto se llama cuando el thunk de createOrder mete el order en el slice
    if (orderSelected?.needsPayment() && waitingForOrderToPay) {
      getOrderClientSecret();
    } else if (openFinishOrder && orderSelected) {
      showOrderCompletedAndExit();
    }
    PartnerService.findPartnerByIdAsync(partnerId).then((partner) =>
      setSalesBlockedMessage(partner.config.salesBlockedMessage ?? null)
    );
  }, [orderSelected]);

  useEffect(() => {
    //hay que hacerlo en dos pasos:
    //1: obtener el orderClientSecret
    //2: invocar el pago
    // si no es así en plan reactivo no funciona.
    if (orderClientSecret) {
      payWithOrderClientSecret();
    }
  }, [orderClientSecret]);

  const createOrder = async () => {
    if (salesBlockedMessage) {
      alert(salesBlockedMessage);
      return;
    }
    showAlert({ title: "Guardando el pedido...", loading: true });
    let orderToCreate: CreateOrderDto = {
      clientId: user.id,
      billingAddressId: newOrder.shippingAddress.id,
      paymentMethodId: newOrder.paymentMethod.id,
      details: newOrder.details.map((detail) => detail.toDto()),
      from: "web",
      venue: undefined,
      clientNote: newOrder.clientNote,
      estimatedDeliveryDate: newOrder.estimatedDeliveryDate.toISOString(),
      shippingAddressId: newOrder.shippingAddress.id,
      shippingMethodId: newOrder.shippingMethod.id,
    };

    if (newOrder.creditCard) {
      orderToCreate = {
        ...orderToCreate,
        paymentInfo: {
          captureStatus: "pending",
          creditCard: newOrder.creditCard,
          paymentIntentId: "",
          totalToPay: 0,
        },
      };
    }

    dispatch(createOrderThunk(orderToCreate));
    if (newOrder.creditCard) {
      setWaitingForOrderToPay(true);
    }
  };

  const showAlert = ({
    title,
    text,
    icon,
    loading,
    confirm = false,
    timer = 3000,
    then,
  }: AlertParams) => {
    let params = {
      customClass: {
        container: "swalert",
      },
      title,
      text,
      icon,
      timer,
      showConfirmButton: confirm,
      didOpen: () => {
        if (loading) Swal.showLoading();
      },
    };
    //el update no tiene THEN
    if (ReactSwal.isVisible() && then === undefined) {
      ReactSwal.update(params);
    } else {
      ReactSwal.fire(params).then(then);
    }
    if (loading) Swal.showLoading();
  };

  const dismissAlert = () => {
    ReactSwal.close();
  };

  const showOrderCompletedAndExit = () => {
    showAlert({
      title: "Pedido completado",
      text: "Su pedido ha sido completado con éxito",
      icon: "success",
      then: handleOrderComplete,
      confirm: true,
    });
    dispatch(selectOrder(undefined));
    handleOnClose();
  };

  const getOrderClientSecret = async () => {
    showAlert({ title: "Comprobando datos de pago", loading: true });
    let orderClientSecret = await PaymentMethodService.getStripeOrderKeyAsync(
      orderSelected.id
    );
    setOrderClientSecret(orderClientSecret);
  };

  const payWithOrderClientSecret = async () => {
    showAlert({ title: "Completando el pago...", loading: true });
    const payload = await stripe.confirmCardPayment(orderClientSecret, {
      payment_method: newOrder.creditCard.stripeId,
    });
    if (payload.error) {
      showAlert({ title: "Error en el pago", icon: "error" });
    } else {
      showAlert({
        title: "Pago completado",
        text: "Su pedido ha sido completado con éxito",
        icon: "success",
        then: handleOrderComplete,
        confirm: true,
      });
    }
  };

  const onClickShowEditClientData = () => {
    //setEditingClientData(true)
  };

  const onClickPayButton = () => {
    if (orderSelected === undefined) {
      createOrder();
    } else if (orderSelected.needsPayment()) {
      getOrderClientSecret();
    }
  };

  const handleOnClose = () => {
    setWaitingForOrderToPay(false);
    setOrderClientSecret(false);
    dispatch(setOpenFinishOrder(false));
  };
  const handleOrderComplete = () => {
    setWaitingForOrderToPay(false);
    setOrderClientSecret(false);
    dispatch(resetNewOrder());
  };

  const handleOpenSelectAddress = () => {
    dispatch(setOpenSelectAddress(true));
  };

  const onClickSelectPaymentMethod = () => {
    dispatch(setOpenSelectPaymentMethod(true));
  };

  const handleOpenShippingMethod = () => {
    dispatch(setOpenShippingMethod(true));
  };

  const onClickOpenDeliveryDate = () => {
    dispatch(setOpenDeliveryDate(true));
  };

  const checkOrderParams = (): boolean => {
    if (!newOrder.shippingAddress) {
      return false;
    }
    if (!newOrder.shippingMethod) {
      return false;
    }
    if (!newOrder.paymentMethod) {
      return false;
    }
    if (newOrder.paymentMethod?.creditCardRequired && !newOrder.creditCard) {
      return false;
    }
    return true;
  };

  const shippingMethodValueDescription = () => {
    const shippingValue = newOrder.getShippingValue();
    return shippingValue === 0 ? "Gratis" : `${shippingValue.toFixed(2)} €`;
  };

  const confirmOrderTitle = () => {
    if (checkOrderParams() == false) {
      return t("Order.CompleteAllFields");
    }
    if (newOrder.paymentMethod.creditCardRequired) {
      return `Pagar ${newOrder
        .getTotalPriceWithShipping(isRetail)
        .toFixed(2)}€ y finalizar`;
    }
    return t("Order.Finish");
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <Drawer anchor={"right"} open={openFinishOrder} onClose={handleOnClose}>
          <DrawerHeader
            title={t("Order.PayAndSend")}
            onClickBackButton={handleOnClose}
          />
          <Box
            component={"div"}
            sx={FinishOrderDrawerContainer(theme.palette.background.paper)}
          >
            <List component="nav">
              <FinishOrderListItem
                icon={HomeIcon}
                title={t("Order.ShippingAddress")}
                value={`${
                  newOrder.shippingAddress
                    ? newOrder.shippingAddress.street
                    : ""
                } ${
                  newOrder.shippingAddress
                    ? newOrder.shippingAddress.province.name
                    : ""
                }`}
                onClick={handleOpenSelectAddress}
              />

              <FinishOrderListItem
                disabled={!newOrder.shippingAddress}
                icon={LocalShippingIcon}
                title={t("Order.ShippingMethod")}
                value={
                  newOrder.shippingMethod ? newOrder.shippingMethod.name : ""
                }
                onClick={handleOpenShippingMethod}
              />

              <FinishOrderListItem
                disabled={!newOrder.shippingMethod}
                icon={DateSelectedIcon}
                title={t("Order.ShippingDate")}
                value={
                  newOrder.estimatedDeliveryDate
                    ? moment(newOrder.estimatedDeliveryDate).format(
                        "DD/MM/YYYY"
                      )
                    : ""
                }
                onClick={onClickOpenDeliveryDate}
              />

              <FinishOrderListItem
                icon={newOrder.creditCard ? CreditCardIcon : PaymentsIcon}
                title={t("Order.PaymentMethod")}
                value={
                  newOrder.creditCard
                    ? newOrder.creditCard.brand.toUpperCase() +
                      " ****" +
                      newOrder.creditCard.lastCardNumbers
                    : newOrder.paymentMethod?.name
                }
                onClick={onClickSelectPaymentMethod}
              />

              <Divider sx={FinishOrderDrawerSectionDivider()} />
              <Box sx={{ height: 15 }} />

              <FinishOrderPriceItem
                title={t("Order.Buy")}
                subtitle={
                  newOrder.details?.length +
                  (newOrder.details.length > 1 ? " productos" : " producto")
                }
                value={`${newOrder
                  ?.getTotalPrice(user?.type !== "company")
                  .toFixed(2)} €`}
              />

              <FinishOrderPriceItem
                title={t("Order.ShippingCost")}
                subtitle={newOrder.shippingMethod?.name ?? "No seleccionado"}
                value={
                  newOrder.shippingMethod
                    ? shippingMethodValueDescription()
                    : "--"
                }
              />

              <Divider />

              <ListItem>
                <ListItemText
                  primary="Total"
                  primaryTypographyProps={{ variant: "h6" }}
                />
                <ListItemSecondaryAction>
                  <Typography component={"span"} variant="h6">
                    {newOrder.getTotalPriceWithShipping(isRetail).toFixed(2)} €
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>

            <Box sx={FinishOrderDrawerFooter()}>
              <Button
                variant={"contained"}
                type="submit"
                color="secondary"
                fullWidth
                disabled={checkOrderParams() == false}
                onClick={onClickPayButton}
              >
                <span>{confirmOrderTitle()}</span>
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Elements>
      <ConfirmDialog
        itemId={"shop_closed"}
        open={openBlockedMessage}
        text={salesBlockedMessage}
        title={t("Shop.ShopClosed")}
        onCloseDialog={() => setOpenBlockedMessage(false)}
      />
    </>
  );
};

export default FinishOrderDrawer;
