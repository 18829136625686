import { NotArrayError } from "src/app/errors/NotArrayError";
import { FamiliyContentType } from "./FamilyContentType";

export class Family {
  constructor(
    readonly id: string,
    readonly erpId: string,
    readonly hidden: boolean,
    readonly name: string,
    readonly priority: number,
    readonly taxId: string,

    readonly contentType?: FamiliyContentType,
    readonly description?: string,
    readonly onlyImage?: boolean,
    readonly parentId?: string,
    readonly photoUrl?: string
  ) {}

  public static fromJson(json: any): Family {
    return new Family(
      json["id"],
      json["erpId"],
      json["hidden"],
      json["name"],
      json["priority"],
      json["taxId"],
      json["contentType"]
        ? FamiliyContentType.fromString(json["contentType"])
        : undefined,
      json["description"],
      json["onlyImage"],
      json["parentId"],
      json["photoUrl"]
    );
  }

  public toJson(): any {
    return {
      id: this.id,
      erpId: this.erpId,
      hidden: this.hidden,
      name: this.name,
      priority: this.priority,
      taxId: this.taxId,
      contentType: this.contentType?.value,
      description: this.description,
      onlyImage: this.onlyImage,
      parentId: this.parentId,
      photoUrl: this.photoUrl,
    };
  }

  public static fromJsonArray(jsonArray: any): Family[] {
    if (jsonArray instanceof Array) {
      return jsonArray.map((json) => this.fromJson(json));
    }

    throw new NotArrayError();
  }
}
