import { Box, CircularProgress, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { PM } from "country-flag-icons/react/3x2";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import { modifyPaymentInfoInNewOrder } from "src/features/order/data/OrderSlice";
import { CreditCardItem } from "./CreditCardItem"
import { CreditCard } from "src/features/client/domain/entities/CreditCard"
import { SelectCreditCardStyles as styles } from "./styles";
import { setOpenManagePaymentMethod, setOpenSelectPaymentMethod } from "../../data/PaymentMethodSlice";
import { modifyCreditCardInNewOrder } from "src/features/order/data/OrderSlice"
import { AddTwoTone as AddIcon } from "@mui/icons-material"
import { getStatus } from "src/features/payment_method/data/PaymentMethodSlice";

interface SelectCreditCardListProps {
    onClose: () => void;
    creditCards: CreditCard[];
    creditCardSelected?: CreditCard;
}

const SelectCreditCardList: FC<SelectCreditCardListProps> = ({ onClose, creditCards, creditCardSelected }) => {
    const dispatch = useAppDispatch();
    const status = useAppSelector(getStatus);

    const handleCreditCardClicked = (card: CreditCard) => {
        dispatch(modifyCreditCardInNewOrder(card));
        dispatch(setOpenSelectPaymentMethod(false));
        onClose();
    }

    const handleNewCreditCardClicked = () => {
        dispatch(setOpenManagePaymentMethod(true))
    }

    const NewCardButtonItem = () => {
        return (
            < ListItemButton key={"listItem"} onClick={handleNewCreditCardClicked}>
                <ListItemIcon>
                    <AddIcon />
                </ListItemIcon>
                <ListItemText
                    primary={`AÑADIR UNA NUEVA TARJETA`}
                />
            </ ListItemButton>)
    }

    if (status == "loading") {
        return (
            <Box component='div' sx={styles.loadingContainer}>
                <CircularProgress />
            </Box>
        )
    }

    if (!creditCards) {
        return (
            <Box component='div' sx={styles.listContainer}>
                <Typography>Aun no tienes tarjetas</Typography>;
            </Box>
        )
    }
    return (
        <Box component='div' sx={styles.listContainer}>
            <List>
                {
                    creditCards.map(creditCard =>
                        <CreditCardItem
                            key={creditCard.stripeId}
                            creditCard={creditCard}
                            creditCardSelected={creditCardSelected}
                            onClick={handleCreditCardClicked}
                        />
                    )

                }
                < NewCardButtonItem />
            </List>
        </Box>
    );
}

export default SelectCreditCardList;


