import { CircularProgress, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerHeader from "src/app/components/DrawerHeader";
import useAuth from "src/app/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "src/config/hooks";
import { getNewOrder } from "src/features/order/data/OrderSlice";
import { getCreditCards, getOpenSelectCreditCard, getStatus, setOpenSelectCreditCard } from "src/features/payment_method/data/PaymentMethodSlice";
import { NewOrder } from "src/features/order/domain/entities/NewOrder";
import SelectCreditCardList from "./SelectCreditCardList";
import { getCreditCardsByClientThunk } from "../../data/PaymentMethodThunk";

const CreditCardDrawer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const creditCards = useAppSelector(getCreditCards);
    const newOrder: NewOrder = useAppSelector(getNewOrder);
   
    const openSelectCreditCard = useAppSelector(getOpenSelectCreditCard)
    const { user } = useAuth();


    useEffect(() => {
        if (openSelectCreditCard) {
            dispatch(getCreditCardsByClientThunk(user.id));
        }
    }, [openSelectCreditCard])




    const handleOnClose = () => {
        dispatch(setOpenSelectCreditCard(false));
    }

    return (
        <>
            <Drawer anchor={"right"} open={openSelectCreditCard} onClose={handleOnClose} >
                <DrawerHeader title="Tarjetas guardadas" onClickBackButton={handleOnClose} />
                {
                    <SelectCreditCardList
                        creditCards={creditCards}
                        creditCardSelected={newOrder.creditCard}
                        onClose={handleOnClose}
                    />
                }
            </Drawer>
        </>
    );
}

export default CreditCardDrawer;