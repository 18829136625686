import { NotArrayError } from "src/app/errors/NotArrayError";
import { CouponsScope } from './CouponsScope';

export class PartnerConfigLoyalty {
    constructor(
        readonly companyPercentMoneyWon: number,
        readonly couponsActivated: boolean,
        readonly couponsScope: CouponsScope,
        readonly pointsName: string,

        readonly retailPercentMoneyWon?: number,
    ) { }

    public static fromJson(json: any): PartnerConfigLoyalty {
        return new PartnerConfigLoyalty(
            json['companyPercentMoneyWon'],
            json['couponsActivated'],
            CouponsScope.fromString(json['couponsScope']),
            json['pointsName'],
            json['retailPercentMoneyWon'],
        );
    }

    public static fromJsonArray(jsonArray: any): PartnerConfigLoyalty[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}