import { NotArrayError } from "src/app/errors/NotArrayError";
import { Address } from "src/features/address/domain/entities/Address";
import { Fee } from "src/features/fee/domain/entities/Fee";
import { PaymentMethod } from "src/features/payment_method/domain/entities/PaymentMethod";
import { Salesman } from "src/features/salesman/domain/entities/Salesman";
import { BillingInfo } from "./BillingInfo";
import { CreditCard } from "./CreditCard";
import { ShippingMethod } from '../../../shipping_method/domain/entities/ShippingMethod';
import { Status } from '../../../commons/Status';
import { ClientType } from "./ClientType";
import { User } from "src/features/commons/Entities";

export class Client {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        readonly email: string,
        readonly erpId: string,
        readonly name: string,
        readonly postalCode: string,
        readonly status: Status,
        readonly type: ClientType,

        readonly activationCode?: string,
        readonly billingInfo?: BillingInfo,
        readonly commercial?: Salesman,
        readonly commercialMustConfirmOrders?: boolean,
        readonly debt?: number,
        readonly defaultBillingAddress?: Address,
        readonly defaultCreditCard?: CreditCard,
        readonly defaultShippingAddress?: Address,
        readonly fee?: Fee,
        readonly lastAccessDate?: Date,
        readonly paymentMethod?: PaymentMethod,
        readonly phone?: string,
        readonly photoUrl?: string,
        readonly salesBlockedMessage?: string,
        readonly shippingMethod?: ShippingMethod,
        readonly stripeId?: string,
    ) { }

    public static fromJson(json: any): Client {
        return new Client(
            json['id'],
            json['createdAt'],
            json['email'],
            json['erpId'],
            json['name'],
            json['postalCode'],
            Status.fromString(json['status']),
            ClientType.fromString(json['type']),

            json['activationCode'],
            json['billingInfo'] ? BillingInfo.fromJson(json['billingInfo']) : undefined,
            json['commercial'] ? Salesman.fromJson(json['commercial']) : undefined,
            json['commercialMustConfirmOrders'],
            json['debt'],
            json['defaultBillingAddress'] ? Address.fromJson(json['defaultBillingAddress']) : undefined,
            json['defaultCreditCard'] ? CreditCard.fromJson(json['defaultCreditCard']) : undefined,
            json['defaultShippingAddress'] ? Address.fromJson(json['defaultShippingAddress']) : undefined,
            json['fee'] ? Fee.fromJson(json['fee']) : undefined,
            json['lastAccessDate'],
            json['paymentMethod'] ? PaymentMethod.fromJson(json['paymentMethod']) : undefined,
            json['phone'],
            json['photoUrl'],
            json['salesBlockedMessage'],
            json['shippingMethod'] ? ShippingMethod.fromJson(json['shippingMethod']) : undefined,
            json['stripeId']
        );
    }

    public static fromJsonArray(jsonArray: any): Client[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

    toLoginUser(): User {
        return {
            id: this.id,
            avatar: this.photoUrl,
            name: this.name,
            email: this.email,
            status: this.status.value,
            type: this.type.value,
            stripeId: this.stripeId
        };
    }

}