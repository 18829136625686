import { Box, Card, alpha, styled } from '@mui/material';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

import useAuth from 'src/app/hooks/useAuth';
import PriceSearcher from 'src/features/price/ui/price_searcher';
import { FC } from 'react';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

interface HeaderProps {
  readonly onClickCartButton: () => void;
}

const Header: FC<HeaderProps> = ({ onClickCartButton }) => {
  const { user } = useAuth();

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
      </Box>

      <Box
        component="span"
        sx={{
          display: { sm: 'inline-block' },
          width: '45%'
        }}
      >
        <PriceSearcher />
      </Box>

      <Box display="flex" alignItems="center">
        <HeaderUserbox />
        <HeaderButtons onClickCartButton={onClickCartButton} />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
