import { createAsyncThunk } from "@reduxjs/toolkit";
import { Address } from "../domain/entities/Address";
import {
  CreateAddressDto,
  UpdateAddressDto,
  FindClientAddressesDto,
} from "./AddressDto";
import { AddressService } from "./AddressService";

export const createAddressThunk = createAsyncThunk(
  "address/create",
  async (createAddress: CreateAddressDto): Promise<Address> =>
    AddressService.createAddressAsync(createAddress)
);

export const deleteAddressThunk = createAsyncThunk(
  "address/delete",
  async (addressId: string) => AddressService.deleteAddressAsync(addressId)
);

export const searchAddresssByClient = createAsyncThunk(
  "address/search",
  async (filters: FindClientAddressesDto): Promise<Address[]> =>
    AddressService.findClientAddressesAsync(filters)
);

export const updateAddressThunk = createAsyncThunk(
  "address/update",
  async (updateAddress: UpdateAddressDto): Promise<Address> =>
    AddressService.updateAddressAsync(updateAddress)
);

export const findAddressByIdThunk = createAsyncThunk(
  "address/find_by_id",
  async (addressId: string): Promise<Address> =>
    AddressService.findAddressByIdAsync(addressId)
);
