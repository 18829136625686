const DrawerHeaderBackButton = () => {
  return {
    padding: "1rem",
    position: "absolute",
    top: 0,
    left: 0,
    margin: 0,
  };
};

const DrawerHeaderContainer = (backgroundColor: string) => {
  return {
    zIndex: 100,
    boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
    display: "flex",
    height: 56,
    minHeight: 56,
    paddingLeft: 5,
    backgroundColor,
  };
};

const DrawerHeaderTitle = () => {
  return {
    flexGrow: 100,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  };
};

export { DrawerHeaderBackButton, DrawerHeaderContainer, DrawerHeaderTitle };
