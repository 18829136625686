export class BillingInfo {
    constructor(
        readonly city: string,
        readonly name: string,
        readonly country: string,
        readonly iban: string,
        readonly nif: string,
        readonly postalCode: string,
        readonly province: string,
        readonly street: string
    ) { }

    public static fromJson(json: any): BillingInfo {
        return new BillingInfo(
            json['city'],
            json['name'],
            json['country'].name,
            json['iban'] ?? "",
            json['nif'],
            json['postalCode'],
            json['province'].name,
            json['street'],
        );
    }

    public static fromJsonArray(jsonArray: any): BillingInfo[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new Error('jsonArray is not an Array in BillingInfo');
    }
}