import { CreateNoteDto, FindNoteDto, UpdateNoteDto } from './NoteDto';
import { Note } from '../domain/entities/Note';
import { deleteRequest, getRequest, postRequest, putRequest } from 'src/config/http';

export abstract class NoteService {

    public static async createNoteAsync(createNote: CreateNoteDto): Promise<Note> {
        return Note.fromJson(await postRequest('/notes', createNote));
    }

    public static async deleteNoteAsync(noteId: string): Promise<Note> {
        return Note.fromJson(await deleteRequest(`/notes/${noteId}`));
    }

    public static async findNoteAsync(findNote: FindNoteDto): Promise<Note | null> {
        const response = await getRequest(`clients/${findNote.clientId}/products/${findNote.productId}/notes`);
        return response ? Note.fromJson(response) : null;
    }

    public static async updateNoteAsync(updateNote: UpdateNoteDto): Promise<Note> {
        return Note.fromJson(await putRequest(`/notes/${updateNote.noteId}`, updateNote));
    }
}