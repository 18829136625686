import { Divider, IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import RightIcon from '@mui/icons-material/ChevronRightTwoTone';

interface FinishOrderListItemProps {
    readonly icon: OverridableComponent<SvgIconTypeMap>;
    readonly title: string;
    readonly value: string;
    readonly onClick?: () => void;
    readonly disabled?: boolean;
}

const FinishOrderListItem: FC<FinishOrderListItemProps> = ({ icon: Icon, title, value, disabled, onClick }) => {
    return (
        <>
            <ListItemButton disabled={disabled} onClick={onClick}>
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={title} secondary={value} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                        <RightIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
        </>
    );
}

export default FinishOrderListItem;