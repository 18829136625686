import { useState, useEffect, useContext } from "react";
import { FeaturesSelectedContext } from "src/features/order/ui/detail_form";
import { findFeaturesByProductIdAsync } from "../../data/featuresService";
import { Feature } from "../../domain/Feature";

export const useFeatures = (productId: string) => {
  const { featuresSelected } = useContext(FeaturesSelectedContext);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    findFeaturesByProductIdAsync(productId).then(features => {
      const groups: Set<string> = new Set();
      features.forEach(feature => groups.add(feature.group));
      setFeatures(features);
      setGroups(Array.from(groups));
    });
  }, [productId]);

  const getFeaturesByGroup = (group: string) => features.filter(feature => feature.group === group);
  const getFeatureSelectedByGroup = (group: string): Feature | undefined => featuresSelected.find(feature => feature.group === group);

  return {
    groups,
    getFeaturesByGroup,
    getFeatureSelectedByGroup,
  }

}