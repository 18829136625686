import { NotArrayError } from "src/app/errors/NotArrayError";
import { Status } from '../../../commons/Status';

export class Salesman {
    constructor(
        readonly id: string,
        readonly email: string, 
        readonly erpId: string, 
        readonly name: string,
        readonly phone: string, 
        readonly photoUrl: string, 
        readonly status: Status
    ) { }

    public static fromJson(json: any): Salesman {
        return new Salesman(
            json['id'],
            json['email'],
            json['erpId'],
            json['name'],
            json['phone'],
            json['photoUrl'],
            Status.fromString(json['status']),
        );
    }

    public static fromJsonArray(jsonArray: any): Salesman[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}