const DeliveryDateDrawerCalendarWrapper = () => {
    return {
        justifyContent: "center",
        alignContent: "center",
        display: "flex"
    };
}

const DeliveryDateDrawerActionBar = () => {
    return {
        marginTop: 20,
        marginRight: 20,
        float: "right",
    };
}

export {
    DeliveryDateDrawerCalendarWrapper,
    DeliveryDateDrawerActionBar
}