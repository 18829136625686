import { Button, Grid, useTheme } from '@mui/material';
import { Box, Link } from '@mui/material';
import { FooterGritItem, FooterLink, FooterMainContainer, FooterMainStyles, FooterTitle } from './styles';
import { t } from 'i18next';
import { useAppSelector } from 'src/config/hooks';
import { getPartnerSelected } from 'src/features/partner/data/PartnerSlice';
import LegalLink from './LegalLink';
import Cookies from './Cookies';
import FooterLogo from './FooterLogo';


function Footer() {
  const theme = useTheme()

  const partner = useAppSelector(getPartnerSelected);


  const phoneLink = () => {
    if (!partner.contact?.phone) {
      return <></>;
    }

    return (
      <Grid item>
        <Link sx={FooterLink()} underline='none' href={`tel:${partner.contact?.phone}`}>
          {
            partner.contact?.phone
          }
        </Link>
      </Grid>
    );
  }

  const whatsappLink = () => {
    if (!partner.contact?.whatsapp) {
      return <></>;
    }

    return (
      <Grid item>
        <Link sx={FooterLink()} underline='none' href={`https://wa.me/${partner.contact?.whatsapp?.replace(/[^0-9]/g, "")}`}>
            Whatsapp
        </Link>
      </Grid>
    );
  }

  const emailLink = () => {
    if (!partner.contact?.email) {
      return <></>;
    }
    return (
      <Grid item>
        <Link sx={FooterLink()} underline='none' href={`mailto:${partner.contact?.email}`}>
          {partner.contact?.email}
        </Link>
      </Grid>
    );
  }

  const rssLinks = () => {
    if (!partner.customs || (!partner.customs.facebookUrl && !partner.customs.instagramUrl && !partner.customs.twitterUrl)) {
      return <></>;
    }

    return (
      <Grid item>
        {
          !partner.customs.facebookUrl
            ? <></>
            : (<Button
              sx={{ ...FooterLink(), padding: 0, marginRight: 1, marginLeft: 0, minWidth: '0px' }}
              component={'a'}
              href={partner.customs.facebookUrl}
              target={'_blank'}
            >Facebook</Button>)
        }

        {
          !partner.customs.instagramUrl
            ? <></>
            : (<Button
              sx={{ ...FooterLink(), padding: 0, marginRight: 1, marginLeft: 0, minWidth: '0px' }}
              component={'a'}
              href={partner.customs.instagramUrl}
              target={'_blank'}
            >Instagram</Button>)
        }

        {
          !partner.customs.twitterUrl
            ? <></>
            : (<Button
              sx={{ ...FooterLink(), padding: 0, marginRight: 1, marginLeft: 0, minWidth: '0px' }}
              component={'a'}
              href={partner.customs.twitterUrl}
              target={'_blank'}
            >Twitter</Button>)
        }
      </Grid>
    );
  }


  return (
    <Box sx={FooterMainStyles(theme.palette.secondary.main)}>
      <Cookies url={partner.customs?.cookiesUrl} />

      <Grid
        container
        justifyContent="space-evenly"
        direction={"row"}
        alignItems={"flex-start"}
        sx={FooterMainContainer(theme.palette.secondary.main)}
      >

        <FooterLogo url={partner?.customs?.logoWhiteUrl} />
        <Grid item sx={FooterGritItem()}>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              sx={FooterTitle()}
            >
              {t('Commons.Footer.Contact')}
            </Grid>
            {
              whatsappLink()
            }
            {
              phoneLink()
            }
            {
              emailLink()
            }
            {
              rssLinks()
            }
          </Grid>
        </Grid>
        <Grid item sx={FooterGritItem()}>
          <Grid item container direction="column" spacing={2} >
            {
              partner.customs?.policyUrl || partner.customs?.termsUrl || partner.customs?.cookiesUrl
                ? <Grid
                  item
                  sx={FooterTitle()}
                >
                  {t('Commons.Footer.LegalTitle')}
                </Grid>
                : <></>
            }
            <LegalLink text={t('Commons.Footer.Privacy')} url={partner.customs?.policyUrl} />
            <LegalLink text={t('Commons.Footer.Conditions')} url={partner.customs?.termsUrl} />
            <LegalLink text={t('Commons.Footer.Cookies.Link')} url={partner.customs?.cookiesUrl} />
          </Grid>
          <Grid />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
