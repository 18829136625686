import { NotArrayError } from "src/app/errors/NotArrayError";

export class Coordinates {
    constructor(
        readonly lat: number,
        readonly lng: number,
    ) { }

    public static fromJson(json: any): Coordinates {
        return new Coordinates(
            json['lat'],
            json['lng'],
        );
    }

    public static fromJsonArray(jsonArray: any): Coordinates[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}